import { platform } from "@/utils/platform";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useCouponAction from "../utils/coupon";
import styles from "./PrizeCard.module.less";

const CouponCard: React.FC<{ info: any; }> = ({ info }) => {
  const { navigate2H5 } = useNavigation();
  const { couponDetailAction } = useCouponAction();
  const toJointBillPage = (couponAward) => {
    const { isOldCoupon, awdId, voucherNo } = couponAward;
    const params = {
      newVersionCoupon: !isOldCoupon,
      couponId: awdId,
      voucherNo: voucherNo,
    };
    couponDetailAction(params);
  };

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <span className={styles.price}>{info.quotaAmount}</span>
      </div>
      <div className={styles.center}>
        <div className={styles.title}>
          满{info.baseAmount}减{info.quotaAmount}
        </div>
        <div className={styles.desc}>
          <div className={styles.descRow}>
            {moment(parseInt(info?.receiveTime)).format("Y.MM.DD")}-
            {moment(parseInt(info?.expireTime)).format("Y.MM.DD")}
          </div>
          <div className={styles.descRow}>{info.couponLimitDescList?.join(";")}</div>
        </div>
      </div>
      <div className={styles.right}>
        {platform.isApp ? (
          <div className={styles.textBtn}>仅限饷店使用</div>
        ) : (
          <div className={styles.btn} onClick={() => toJointBillPage(info)}>
            去使用
          </div>
        )}
      </div>
    </div>
  );
};
const PointCard: React.FC<{ pointsAmount: number; }> = ({ pointsAmount }) => {
  const { navigate2H5 } = useNavigation();
  const toMemberCenter = () => {
    if (platform.isH5) {
      const options: IOptions = {
        path: "/#/membercenter",
        is_micro_app: true,
      };
      navigate2H5(options);
    } else if (platform.isSaas) {
      // saas小程序
    } else if (platform.isMini) {
      // 饷店小程序
      const link = `${location.origin}/v2/membercenter`;
      let url = `/pages/web-view/web-view?url=${encodeURIComponent(link)}`;
      window.wx.miniProgram.navigateTo({
        url,
      });
    }
  };
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <span className={styles.point}>{pointsAmount}</span>
      </div>
      <div className={styles.center}>
        <div className={styles.title}>积分奖励</div>
        <div className={styles.desc}>
          <div>可在积分商城进行商品兑换</div>
        </div>
      </div>
      <div className={styles.right}>
        <div
          className={styles.btn}
          onClick={() => {
            toMemberCenter();
          }}
        >
          去查看
        </div>
      </div>
    </div>
  );
};

const RedPacket: React.FC<{ info: any; shopInfo: any; }> = ({ info, shopInfo }) => {
  const { amount, hotBrandInfo } = info ?? {};
  const { navigate2H5, navigate2Store } = useNavigation();
  const toCouponProductList = () => {
    // 去红包落地页
    if (platform.isH5) {
      const options: IOptions = {
        path: "/mx-shop-micro/joint-bill",
        is_micro_app: true,
      };
      navigate2H5(options);
    } else if (platform.isMini) {
      // 小程序
      window.wx.miniProgram.navigateTo({
        url: "/acts/pages/offer-order/offer-order",
      });
    }
  };
  const toShop = () => {
    navigate2Store({
      merchantShopCode: shopInfo.shopCode,
      shopCode: shopInfo.shopCode,
      page_name: encodeURIComponent("日日奖"),
      productType: 1,
    });
  };
  return (
    <div className={styles.card}>
      <div className={styles.redPacketLeft}>
        <div className={styles.price}>{amount}</div>
        <div className={styles.priceDesc}>全场商品可用</div>
      </div>
      <div className={styles.center}>
        <div className={styles.title}>红包奖励</div>
        <div className={styles.desc}>
          <div className={styles.flex}>
            <div className={styles.descRow2}>{hotBrandInfo.brandName}</div> 品牌赞助
          </div>
          <div>红包直接购物抵扣，不可提现，海淘、虚拟商品除外</div>
        </div>
      </div>
      <div className={styles.redPacketRight}>
        <div className={styles.btn} onClick={toShop}>
          去逛逛
        </div>
      </div>
    </div>
  );
};
const PrizeCard: React.FC<{ info: any; }> = ({ info }) => {
  const { awardType } = info ?? {};
  if (awardType === "RED_PACKET") {
    return (
      info.redPacketAward && (
        <RedPacket info={info.redPacketAward} shopInfo={info.merchantShopList?.[0]} />
      )
    );
  } else if (awardType === "COUPON") {
    return info.couponAwd && <CouponCard info={info.couponAwd} />;
  } else if (awardType === "POINTS") {
    return info.pointsAmount && <PointCard pointsAmount={info.pointsAmount} />;
  }
};

export default PrizeCard;
