import { Dialog, Popup } from "antd-mobile";
import React from "react";
import { YouXueSignUp } from "../../../model";
import StudyActivityCardComp from "../../components/card";
import useSelectList, { PopupProps } from "../../hook/useSelectList";
import ActivityDetailComp from "../detail";
import styles from "./index.module.less";

const SelectedActivityListComp: React.FC<PopupProps> = ({
  visible,
  studyTourInfo,
  tab,
  onVisiblePress,
  onDeletePress,
}) => {
  const {
    lists,
    detailVisible,
    selectStudyTourDetail,
    onDetailDialogVisible,
    onSureAction,
    showEmptyList,
    onClose,
    onActivityDetailAction,
  } = useSelectList({ visible, studyTourInfo, onVisiblePress, onDeletePress, tab });

  return (
    <>
      <Popup
        style={{ "--z-index": "99" }}
        maskStyle={{ zIndex: "100" }}
        visible={visible}
        onMaskClick={onClose}
      >
        <div className={styles.popup__box}>
          <div className={styles.box__header}>
            <div className={styles.header__title}>已选游学活动</div>
            <img
              className={styles.header__icon}
              onClick={() => onClose()}
              src="https://akim-oss.aikucun.com/mshop/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1710849026952_49.png"
            />
          </div>
          <div className={styles.list}>
            <div className={styles.page__body}>
              <>
                {lists?.length > 0 && !showEmptyList() ? (
                  <>
                    {lists.map((item) => {
                      const showHeader =
                        (item?.studyTourSignUpList?.findIndex((item) => item?.isSelect) || 0) > -1;
                      return (
                        <>
                          {showHeader && (
                            <div className={styles.list__header}>
                              <div className={(styles.header__title, styles.header__title__0)}>
                                {item?.day}
                              </div>
                            </div>
                          )}
                          {item?.studyTourSignUpList?.map((t, index) => {
                            return (
                              <>
                                {t?.isSelect && (
                                  <StudyActivityCardComp
                                    signUpDetail={t}
                                    key={`${index}+${t.studyTourId}`}
                                    supportDelete={true}
                                    onDetailPress={onActivityDetailAction}
                                    onDeletePress={(signUpDetail?: YouXueSignUp) => {
                                      Dialog.confirm({
                                        title: (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              fontSize: 16,
                                              color: "#222222",
                                            }}
                                          >
                                            请确认是否删除
                                          </div>
                                        ),
                                        closeOnMaskClick: true,
                                        cancelText: (
                                          <div style={{ color: "#666666", fontSize: 16 }}>取消</div>
                                        ),
                                        confirmText: (
                                          <div style={{ color: "#FF4747", fontSize: 16 }}>确定</div>
                                        ),
                                        onConfirm() {
                                          onSureAction(signUpDetail);
                                        },
                                        style: {
                                          zIndex: 20000, // 由于Popup全局优先级设置的较高，因此这里需要设置zindex
                                        },
                                      });
                                    }}
                                  />
                                )}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                    <div className={styles.footer} />
                  </>
                ) : (
                  <div className={styles.empty}>
                    <img
                      className={styles.emptyIcon}
                      src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                    />
                    <div>暂未选择相关游学～</div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </Popup>
      {detailVisible && (
        <ActivityDetailComp
          signUpDetail={selectStudyTourDetail.current}
          visible={detailVisible}
          showBottom={false}
          setVisible={onDetailDialogVisible}
        />
      )}
    </>
  );
};

export default SelectedActivityListComp;
