import { drawLotteryAward } from "@/api/dailyReward";
import { useCommonContext } from "@/utils/context/common";
import { getCurPageClientWidth } from "@/utils/getStyle";
import { isSameDay } from "@/utils/time";
import { Toast } from "antd-mobile";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DrawResultPop from "./DrawResultPop";
import styles from "./Lottery.module.less";
const acturePx = (px: number) => {
  const pixel = window.devicePixelRatio || 1;
  const ratio = getCurPageClientWidth() / 375;
  return px * ratio * pixel;
};

const textMap = {
  ADVANCE: "活动暂未开始，敬请期待",
  BEGIN: "暂无抽奖机会，连续签到获得抽奖机会",
  END: "本次活动已结束",
  CANCEL: "本次活动已结束",
};

const canvasWidth = acturePx(279);
const canvasHeight = acturePx(150);

const Lottery: React.FC<{
  activityInfo: any;
  handleShare: any;
  setRefreshTimes: any;
  pageId: string;
}> = ({ activityInfo, handleShare, setRefreshTimes, pageId }) => {
  const { track } = useCommonContext();
  const [search] = useSearchParams();
  const { lotteryInfo, status } = activityInfo ?? {};
  const [drawResultVisible, setDrawResultVisible] = useState(false);
  const [drawResultInfo, setDrawResultInfo] = useState<any>(null);
  const touchHandles = useRef<{
    removeEvent?: any;
    touchstart?: any;
    touchend?: any;
    touchmove?: any;
  }>({});
  if (activityInfo?.signInTaskInfo?.totalDays) {
    if (
      isSameDay(activityInfo.endTime, +new Date()) &&
      activityInfo?.lotteryInfo.remainCount === 0
    ) {
      // 如果是在最后一天
      textMap.BEGIN = "暂无抽奖机会";
    }
    textMap.BEGIN = `当前剩余0次抽奖机会，连续签到${activityInfo?.signInTaskInfo?.totalDays}天可获得`;
  }

  const queryPrize = debounce(async (ctx) => {
    Toast.show({
      icon: "loading",
      content: "抽奖中...",
      duration: 3000,
    });
    const params = {
      promoActivityId: activityInfo.promoActivityId,
      pageId,
    };
    try {
      const result = await drawLotteryAward(params);
      setDrawResultInfo(result);
      setDrawResultVisible(true);
      console.log("drawResult", result);
      ctx.globalCompositeOperation = "source-over";
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      // eslint-disable-next-line no-use-before-define
      init(true);
      if (track?.track) {
        track?.track("resource_click", {
          page_name: "日日奖",
          previous_page_name: search.get("previous_page_name"),
          btn_name: "抽奖",
          page_code: activityInfo.promoActivityId,
          task_id: activityInfo.signInTaskInfo?.taskId,
        });
      }
    } catch (error: any) {
      console.log("error", error);
      Toast.show({
        content: error?.message || "抽奖失败",
      });
      setRefreshTimes((n) => n + 1);
    }

    Toast.clear();
  }, 1000);

  const writeText = (ctx: any, e: Touch, gj: HTMLElement) => {
    ctx?.beginPath();
    const pixel = window.devicePixelRatio || 1;
    let x = e.clientX - (gj?.getBoundingClientRect()?.left ?? 0);
    let y = e.clientY - (gj?.getBoundingClientRect()?.top ?? 0);
    ctx.globalCompositeOperation = "destination-out";
    ctx?.closePath();
    ctx?.arc(x * pixel, y * pixel, 10 * pixel, 0, Math.PI * 2);
    ctx?.fill();
  };

  const renderTextNoChance = (ctx, text = "") => {
    ctx.font = `${acturePx(14)}px 微软雅黑`;
    ctx.fillStyle = "#ffffff";
    ctx.textAlign = "center";
    ctx.fillText(text, canvasWidth / 2, acturePx(56));
  };
  const renderTextHasChance = (ctx, num) => {
    ctx.font = `${acturePx(14)}px 微软雅黑`;
    ctx.fillStyle = "#ffffff";
    let text1 = "当前剩余";
    let text2 = ` ${num} `;
    let text3 = "次刮奖机会";
    const width1 = ctx.measureText(text1).width;
    const width2 = ctx.measureText(text2).width;
    const width3 = ctx.measureText(text3).width;
    const x = canvasWidth / 2 - (width1 + width2 + width3) / 2;
    ctx.fillText(text1, x, acturePx(56));
    ctx.fillStyle = "#FF4747";
    ctx.fillText(text2, x + width1, acturePx(56));
    ctx.fillStyle = "#ffffff";
    ctx.fillText(text3, x + width1 + width2, acturePx(56));
  };
  const init = (hasChance = true) => {
    let gj = document.querySelector("#gj") as HTMLElement;
    let canvas = document.querySelector("#mask") as HTMLCanvasElement;
    const pixel = window.devicePixelRatio || 1;
    canvas.style.width = `${canvasWidth / pixel}px`;
    canvas.style.height = `${canvasHeight / pixel}px`;
    let ctx = canvas?.getContext("2d") as any;

    ctx.globalCompositeOperation = "source-over";
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    canvas.width = canvasWidth;
    // ctx.scale(pixel, pixel);
    const img = new Image();
    img.onload = function () {
      if (!activityInfo.promoActivityId) {
        return;
      }
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight); // 在画布上绘制图片
      if (status === "BEGIN" && lotteryInfo?.remainCount && hasChance) {
        renderTextHasChance(ctx, lotteryInfo?.remainCount);
      } else {
        renderTextNoChance(ctx, textMap[status]);
      }
    };
    img.src =
      "https://akim-oss.aikucun.com/310b86e0b62b828562fc91c7be5380a992b2786a_1724850832466_42.png";

    // 绘图部分
    let isDraw = false;
    let moveActionCount = 0;
    touchHandles.current = {
      touchstart: () => {
        console.log(654321, status, lotteryInfo?.remainCount);
        if (status === "BEGIN" && lotteryInfo?.remainCount) {
          isDraw = true;
        }
      },
      touchend: () => {
        if (status === "BEGIN" && lotteryInfo?.remainCount && moveActionCount > 10) {
          // move事件触发10次以上才会抽奖
          queryPrize(ctx);
          moveActionCount = 0;
          touchHandles.current.removeEvent();
        } else if (status !== "BEGIN" || !lotteryInfo?.remainCount) {
          Toast.show({
            content: "暂无抽奖机会",
          });
        }
        isDraw = false;
      },
      touchmove: (e) => {
        if (isDraw) {
          e.preventDefault();
          writeText(ctx, e.touches[0], gj);

          moveActionCount += 1;
        }
      },
      removeEvent: () => {
        canvas.removeEventListener("touchstart", touchHandles.current.touchstart);
        canvas.removeEventListener("touchend", touchHandles.current.touchend);
        canvas.removeEventListener("touchmove", touchHandles.current.touchmove);
      },
    };
    canvas.addEventListener("touchstart", touchHandles.current.touchstart);
    canvas.addEventListener("touchend", touchHandles.current.touchend);
    canvas.addEventListener("touchmove", touchHandles.current.touchmove);
  };

  const Notice: React.FC<{ info: any; }> = ({ info }) => {
    if (!info) {
      return null;
    }
    const { totalDays, daysToGetAward, signedInToday, todayAwardCount, continuousDays } = info ?? {};
    let text = "";
    let width = "0%";
    if (activityInfo?.status === "ADVANCE") {
      text = "活动暂未开始，敬请期待";
    } else if (activityInfo?.status === "BEGIN") {
      if (signedInToday && todayAwardCount > 0) {
        text = `今日已获得${todayAwardCount}次抽奖机会`;
        width = "100%";
      } else if (!continuousDays) {
        text = `连续签到 ${totalDays} 天，可获得抽奖机会`;
        width = "0%";
      } else {
        text = `再签到 ${daysToGetAward} 天，可获得抽奖机会`;
        width = `${((totalDays - daysToGetAward) / totalDays) * 100}%`;
      }
    } else {
      text = "本次活动已结束，请期待下次活动";
    }

    return (
      <div className={styles.noticeWrap}>
        {!["CANCEL", "END"].includes(activityInfo.status) && (
          <div className={styles.progressWrap}>
            <div className={styles.progress} style={{ width }}></div>
          </div>
        )}
        <div className={styles.noticeText}>{text}</div>
      </div>
    );
  };
  useEffect(() => {
    if (activityInfo.promoActivityId) {
      touchHandles.current.removeEvent?.();
      init();
    }
  }, [activityInfo]);

  return (
    <div
      className={`${styles.wrap} ${["ADVANCE", "BEGIN"].includes(activityInfo?.status) && styles.padding_top_40}`}
    >
      <div className={styles.main}>
        <div id="gj" className={styles.gj}>
          <div className={styles.jp}>松手后抽奖</div>
          <canvas
            id="mask"
            className={styles.mask}
            width={canvasWidth}
            height={canvasHeight}
          ></canvas>
        </div>
        <Notice info={activityInfo.signInTaskInfo} />
      </div>
      <DrawResultPop
        visible={drawResultVisible}
        onClose={() => setDrawResultVisible(false)}
        result={drawResultInfo}
        handleShare={handleShare}
        setRefreshTimes={setRefreshTimes}
      />
    </div>
  );
};

export default Lottery;
