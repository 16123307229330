import { batchReceiveCouponServ } from "@/api/activity";
import { clearWindowNum } from "@/api/coupon";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { showCaptcha } from "@/utils/captcha/captcha";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Mask, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import useCheckBind from "../../../hook/useCheckBind";
import { CouponInfoExt } from "../../../model/CouponModel";
import style from "./index.module.less";

interface Props {
    // 优惠券
    couponInfo: CouponInfoExt;
    // 页面名称
    pageName: string;
    // 更新
    update: () => void;
    // 关闭
    onClose: () => void;
}

const NewCustomerZonePopupV1: React.FC<Props> = ({ couponInfo, pageName, update, onClose }) => {
    const [isShow, setIsShow] = useState(false);
    const [hasReceiveSuccess, setHasReceiveSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const rcsTraceId = useRef<string>("");
    const track = Track();
    const captchaRef = useRef<HTMLDivElement>(null);
    const { navigate2NewPersonPage } = useNavigation();
    const { isAuthed, isBind, bindPhonePopupVisible,
        setBindPhonePopupVisible, toAuth, bindPhone } = useCheckBind();
    const handleClose = (type?: string) => {
        if (isChecked) {
            localStorage.setItem("noShowCouponDialogAgain", "true");
        }
        if (type === "btn") {
            track.track("btn_click", {
                page_name: pageName,
                btn_name: "关闭优惠券弹窗",
            });
        }
        setIsShow(false);
        onClose();
    };

    useEffect(() => {
        const init = async () => {
            const noShowCouponDialogAgain = localStorage.getItem("noShowCouponDialogAgain");
            console.log(
                noShowCouponDialogAgain,
                "不再提示优惠券弹框",
                window.location.search.includes("noShowCouponDialogAgain"),
            );
            if (noShowCouponDialogAgain === "true") {
                setIsShow(false);
                handleClose();
            } else if (
                couponInfo?.newPeopleCouponActivities?.length ||
                couponInfo?.spCouponActivities?.length
            ) {
                setIsShow(true);
            }
            const { pathname: routerName } = window.location;
            const userDesc = isAuthed ? "新客" : isBind ? "老客" : "游客";
            track.track("resource_expose", {
                page_name: pageName,
                resource_type: routerName === "superbrand" ? "会场优惠券弹窗" : `${pageName}优惠券弹窗`,
                resource_name: routerName === "superbrand" ? "会场优惠券弹窗" : `${pageName}优惠券弹窗`,
                desc: userDesc,
            });
        };
        init();
    }, [pageName]);

    const initCaptcha = (tpc: string, callback: (token: string) => void) => {
        showCaptcha(tpc).then((token) => {
            callback(token ?? "");
        });
    };
    const onReceiveCoupon = async (captchaToken?: string) => {
        try {
            if (!isAuthed) {
                toAuth();
                return;
            }
            if (!isBind) {
                bindPhone();
                return;
            }
            if (loading) {
                return;
            }

            track.track("btn_click", {
                page_name: pageName,
                btn_name: "一键领取",
                desc: hasReceiveSuccess ? "是" : "否",
            });

            setLoading(true);
            const { spCouponActivities = [], newPeopleCouponActivities = [] } = couponInfo;
            const couponByIdReqList = [
                ...(spCouponActivities?.map((item) => ({
                    awdId: item.ticketId,
                    promoActivityId: item.promoActivityId,
                })) || []),
                ...(newPeopleCouponActivities?.map((item) => ({
                    awdId: item.ticketId,
                    promoActivityId: item.promoActivityId,
                })) || []),
            ];

            const res = await batchReceiveCouponServ({
                couponByIdReqList,
                rcsTraceId: rcsTraceId.current,
                captchaToken,
                pageIndex: window.location.href,
                protocolVersion: "",
            });
            rcsTraceId.current = (res.data && res.data.rcsTraceId) || "";
            if (res.code === "666B00004") {
                setLoading(false);
                initCaptcha(res.data && res.data.tpc, (token) => {
                    onReceiveCoupon(token);
                });
                return;
            }

            if (res.code === "00000" && res.success) {
                Toast.show("优惠券领取成功");
                setHasReceiveSuccess(true);
                clearWindowNum();
                update();
            } else {
                Toast.show(res.message || "优惠券被领光啦");
                setHasReceiveSuccess(false);
            }
        } catch (e: any) {
            Toast.show(`${e?.message ?? "系统异常"} `);
        } finally {
            setLoading(false);
        }
    };

    const toNewPerson = () => {
        track.track("btn_click", {
            page_name: pageName,
            btn_name: "逛新人专区",
        });
        if (!hasReceiveSuccess) {
            Toast.show("请先领券！");
            return;
        }
        if (couponInfo?.configId) {
            navigate2NewPersonPage(couponInfo?.configId);
        }
    };
    const onFinishBindPhone = () => {
        setBindPhonePopupVisible(false);
        onReceiveCoupon();
    };

    return (
        <>
            <Mask visible={isShow} className={style.coupon_wrap}>
                <div className={style.coupon}>
                    <div className={style.coupon_sunshine}></div>
                    <div className={style.coupon_top}>
                        <div className={style.total_count}>
                            <span>{couponInfo.totalCouponAmount}</span>
                        </div>
                    </div>
                    <div className={style.coupon_con}>
                        {(couponInfo.newPeopleCouponActivities?.length ?? 0) > 0 && (
                            <div className={style.coupon_new_person}>
                                <div className={style.coupon_new_person_title}>
                                    <div className={style.left}>
                                        新人专享券<span className={style.line}></span>
                                        <span className={style.time_wrap}>
                                            <span className={style.time}>7</span>天后过期
                                        </span>
                                    </div>
                                    <div className={style.right} onClick={toNewPerson}>
                                        逛新人专区<i className={style.icon_arrow_right}></i>
                                    </div>
                                </div>
                                <div className={style.coupon_new_person_con}>
                                    {couponInfo.newPeopleCouponActivities?.map((item, key) => (
                                        <div key={key} className={style.coupon_new_person_item}>
                                            <div className={style.title_label}>全场通用</div>
                                            <div className={style.coupon_count}>
                                                <span className={style.unit}>¥</span>
                                                {item.amount}
                                            </div>
                                            <div className={style.coupon_limit}>满{item.thresholdAmount}可用</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {(couponInfo.spCouponActivities?.length ?? 0) > 0 &&
                            (couponInfo.newPeopleCouponActivities?.length ?? 0) > 0 && (
                                <div className={style.shop_coupon_title}>店铺福利券</div>
                            )}
                        <div
                            className={`${style.coupon_center} ${(couponInfo?.newPeopleCouponActivities?.length ?? 0) > 0 ? `${style.has_new_person}` : ""}`}
                        >
                            {couponInfo.spCouponActivities?.map((item, key) => (
                                <div key={key} className={style.coupon_item}>
                                    <div className={style.left}>
                                        <span className={style.unit}>¥</span>
                                        {item.amount}
                                    </div>
                                    <div className={style.right}>
                                        <div className={style.coupon_name}>{item.couponName}</div>
                                        <div className={style.coupon_time}>{item.time}</div>
                                        <div className={`${style.coupon_range} mx-ellipsis-1`}>{item.couponDesc}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={style.coupon_btm}>
                        <div
                            className={`${style.btn} ${hasReceiveSuccess ? `${style.to_use}` : ""} `}
                            onClick={() => onReceiveCoupon()}
                        ></div>
                        <p className={style.tips}>可在“我的-优惠券“中查看</p>
                    </div>
                    <div className={style.close} onClick={() => handleClose("btn")}></div>
                    {couponInfo?.lastWindowNum && (
                        <div
                            className={`${style.switch_radio} ${isChecked ? `${style.checked}` : ""} `}
                            onClick={() => setIsChecked(!isChecked)}
                        >
                            不再提醒
                        </div>
                    )}
                </div>
                <div ref={captchaRef}></div>
            </Mask>
            <BindPhonePopup
                visible={bindPhonePopupVisible}
                setVisible={setBindPhonePopupVisible}
                onFinish={onFinishBindPhone}
                extra={{ bindStatus: 3 }}
            />
        </>
    );
};

export default NewCustomerZonePopupV1;
