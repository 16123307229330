import { checkBind, launchConsultation } from "@/api/my";
import { getCSBase } from "@/utils/service";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import Cookie from "js-cookie";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { btn_click } from "../../track";
import styles from "./index.module.less";

const menuList = [
  // {
  //   type: "coupon",
  //   icon: "https://akim-oss.aikucun.com/30f810e77bd109a7d8d2bea0177e17af727e9d58_1701941469648_54.png",
  //   text: "我的优惠券",
  //   path: "/#/coupon",
  //   show: true,
  // },
  {
    type: "hope",
    icon: "https://akim-oss.aikucun.com/6a3b5ad9e9d5b358ee472f9f7a26c6335f8e56ad_1701941508456_62.png",
    text: "品牌关注",
    path: "/#/my-focus",
    show: true,
  },
  {
    type: "address",
    icon: "https://akim-oss.aikucun.com/8114ed65ffe915616358db7674e087ef472820b4_1701941546438_43.png",
    text: "收货地址",
    path: "/#/addressList",
    show: true,
  },
  {
    type: "service",
    icon: "https://akim-oss.aikucun.com/310a3dae82417c86a5d149f4674a434488a3a575_1701941621196_93.png",
    text: "联系客服",
    path: "/cschat/chatlist",
    isTrigger: true,
    show: true,
  },
  // {
  //   type: "feedback",
  //   icon: "https://akim-oss.aikucun.com/38a80481bf2c1772fab0d1f35fd7f815a6685387_1701941587924_10.png",
  //   text: "意见反馈",
  //   path: "suggestion",
  //   isTrigger: true,
  //   show: true
  // },
  {
    type: "setting",
    icon: "https://akim-oss.aikucun.com/1a5872b67322ef7be5af6ebc0263257d09bc686b_1702352886888_69.png",
    text: "设置",
    path: "/mx-shop-micro/setting",
    show: true,
  },
  // {
  //   type: "track",
  //   icon: "https://akim-oss.aikucun.com/3fec9e79ca74c88cc5b6357b18f132d8e604e071_1711002570692_60.png",
  //   text: "足迹",
  //   path: "/mx-shop-micro/track",
  // },
];

const Menu: React.FC = () => {
  const { navigate2H5 } = useNavigation();
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const distributorId = searchParams.get("distributorId") || "";
  const jumpToCS = async (queryObj) => {
    const webChannel = 3;
    const query = {
      ...queryObj,
      webChannel,
      shopId,
      distributorId,
    };
    const queryStr = Object.keys(query).reduce((res, i) => `${res}${i}=${query[i]}&`, "?");
    const auditChannel = 2;
    let sourceQueryStr = `shopId=${shopId}&source=3&auditChannel=${auditChannel}&distributorId=${distributorId}`;
    const msUserCode = Cookie.get("msUserCode");
    if (msUserCode) {
      sourceQueryStr += `&userId=${msUserCode}`;
    }
    const base = await getCSBase({
      webChannel,
      locationType: queryObj.locationType,
      merchantCode: queryObj.merchantCode,
    });
    window.location.href = `${base}${queryStr}sourceOrigin=${window.encodeURIComponent(
      window.location.origin,
    )}&sourceQueryStr=${window.encodeURIComponent(sourceQueryStr)}`;
  };
  const checkLocationType = async () => {
    const query = { locationType: 6 };
    const queryObj: any = { locationType: 6 };
    const res: any = await launchConsultation(query);
    if (res && res.success && res.data) {
      const { merchantCode } = res.data;
      if (merchantCode) {
        queryObj.merchantCode = merchantCode;
      }
      jumpToCS(queryObj);
    }
  };

  const toCS = async () => {
    // 是否绑定过
    const res: any = await checkBind();
    if (res && res.success && res.data) {
      if (res?.data?.isAuthorized !== true || res?.data?.visitorStatus !== 0) {
        const link = "/#/my";
        const options: IOptions = {
          path: link,
          is_micro_app: true,
          query: "showLoginPopup=1",
        };
        navigate2H5(options);
        return;
      } else {
        checkLocationType();
      }
    }
  };
  const goPage = (path: string, type: string, text: string) => {
    console.log(path);
    const options: IOptions = {
      path: path,
      is_micro_app: true,
    };
    if (type === "service") {
      // window.location.href = path;
      toCS();
      btn_click("我的-其他功能设置", text);
      return;
    }
    if (type === "setting") {
      options.is_micro_app = false;
    }
    navigate2H5(options);
    btn_click("我的-其他功能设置", text);
  };
  return (
    <div className={styles["menu"]}>
      {menuList.map((item, index) => (
        <div
          className={styles["menu-item"]}
          key={index}
          onClick={() => goPage(item.path, item.type, item.text)}
        >
          <img className={styles["menu-item-icon"]} src={item.icon} />
          <div className={styles["menu-item-text"]}>{item.text}</div>
        </div>
      ))}
    </div>
  );
};
export default Menu;
