import { SharePosterParams } from "@/api/scode/poster";
import {
  CarrierElementType,
  ContentType,
  SourceScene,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import makeMoneyPng from "@/assets/make_moeny.png";
import noSecKill from "@/assets/no_seckill.png";
import shareMarketPng from "@/assets/share_market.png";
import loadingPng from "@/base64";
import Bottom from "@/components/bottom/index";
import PointsCoupon from "@/components/points-coupon/Index";
import PosterSwipe from "@/components/poster-swiper";
import ProductCom from "@/components/product/Index";
import SaveImagePopup from "@/components/save-image-popup";
import {
  autoShareConferencePosterIncrement,
  setTitleNShareCardInfo,
  shareConferencePosterAction,
  shouldAutoShareConferencePoster,
} from "@/utils/actions";
import { getAppSystem } from "@/utils/app-system";
import { useCommonContext } from "@/utils/context/common";
import { initTime } from "@/utils/countdown";
import { identity } from "@/utils/identity";
import { MICRO_APP_BASEURL, platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { storeRem } from "@/utils/rem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { versionsCompare } from "@/utils/version-compare";
import { CenterPopup, Image, InfiniteScroll, Toast } from "antd-mobile";
import { isEmpty, throttle } from "lodash";
import { inject, observer } from "mobx-react";
import React, { lazy, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useActivate, useAliveController, useUnactivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getCartTotal,
  preview,
  requestConferencePopupImage,
  searchActivity,
  searchCoupon,
  searchNavigationActivity,
  searchNavigationProduct,
  searchPageInfo,
  searchPointCoupon,
  searchProduct,
  searchSecKill,
} from "../api";
import styles from "./index.module.less";

const ImageCom = lazy(() => import("@/components/image/Index"));
const CubeCom = lazy(() => import("@/components/cube/Index"));
const TextCom = lazy(() => import("@/components/text/Index"));
const BannerCom = lazy(() => import("@/components/banner/Index"));
const VideoCom = lazy(() => import("@/components/video/Index"));
const ActivityCom = lazy(() => import("@/components/activity/Index"));
const NavigationCom = lazy(() => import("@/components/navigation/Index"));
const CouponCom = lazy(() => import("@/components/coupon/Index"));
const SecKillCom = lazy(() => import("@/components/seckill/Index"));
interface Props {
  appStore?: any;
  [key: string]: any;
}

const Home: React.FC<Props> = ({ appStore }) => {
  const { navigate2H5, navigate2SaleCalendar, navigate2Home } = useNavigation();

  const { currentRole } = useCommonContext();

  const [popupImageUrl, setPopupImageUrl] = useState<string>();

  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<any>();
  const [hasMore, setHasMore] = useState(false);
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const [showPoster, setShowPoster] = useState(false);
  const sharePosterData = useRef<SharePosterParams>();
  const pageDom = useRef<HTMLDivElement>(null);
  const [queryLoading, setQueryLoading] = useState(false);
  const [shareBtnSwitch, setShareBtnSwitch] = useState<any>();
  const [cartTotal, setCartTotal] = useState(0);
  // const [sticky, setSticky] = useState(false);
  const track = Track();
  const shopId = search.get("shopId");
  const customPageCode = search.get("customPageCode");
  const calendarDay = search.get("calendarDay") || "";
  const [componentIndex, setComponentIndex] = useState(5);
  const [loadingComponents, setLoadingComponents] = useState(false);
  const showSaleCalendar = useRef(false);
  const getCookie = (cname) => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  };

  // const initScroll = () => {
  //   setTimeout(() => {
  //     const scrollTop = sessionStorage.getItem("scrollLoaction");
  //     if (scrollTop) {
  //       pageDom.current?.scrollTo(0, Number(scrollTop));
  //       sessionStorage.setItem("scrollLoaction", "");
  //     }
  //   }, 500);
  // };

  useEffect(() => {
    storeRem();
    // initScroll();
  }, []);

  useActivate(() => {
    console.log("in useActivate");
    document.title = `${calendarDay} ${pageInfo.name}`;
    setTitleNShareCardInfo({ ...pageInfo, name: `${calendarDay}${pageInfo.name}` });
  });

  //加载更多(仅限最后一个组件为档期或商品)
  const InfiniteScrollComponent = () => {
    if (!loadingComponents) {
      return;
    }
    return (
      <div className={styles.infinite}>
        <div>
          <img
            className={styles.loadingMore}
            src={loadingPng}
            style={{ width: pxtorem(20), height: pxtorem(20) }}
          />
        </div>
      </div>
    );
  };

  //判断最后一个组件是否是档期或者商品，并判断是否还需要加载更多
  const checkLast = (pageInfo: any) => {
    if (pageInfo?.components?.length > 0) {
      const last = pageInfo.components[pageInfo.components.length - 1];
      if (last.type === "ACTIVITY") {
        setHasMore(last?.dataInfo?.hasNext);
        return;
      }
      if (
        last.type === "PRODUCT" &&
        last.animationType !== "2" &&
        last.layoutType !== "10" &&
        last.layoutType !== "20"
      ) {
        setHasMore(last?.dataInfo?.hasNext);
        return;
      }
      setHasMore(false);
    }
  };

  //获取档期
  const queryActivity = async (item: any, pageData: any, from = 0) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    const params = {
      componentCode: item.componentCode,
      componentDetailCodes: item.activityRuleConfigDetails.map((item) => item.componentDetailCode),
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      from: from,
      pageSize: from > 0 ? item.morePageSize : item.pageSize,
      lastActivityId:
        item?.dataInfo?.result?.length > 0
          ? item?.dataInfo?.extra?.lastActivityId
            ? item?.dataInfo?.extra?.lastActivityId
            : item?.dataInfo?.result[item.dataInfo?.result.length - 1]?.activityId
          : null,
      pageId: "H5_SuperMarketing",
      preview: false,
    };
    const res: any = await searchActivity(params);
    if (res) {
      if (from > 0 && res?.data) {
        res.data.result = res.data?.result
          ? item.dataInfo?.result.concat(res.data?.result)
          : item.dataInfo?.result;
        item.dataInfo = res?.data;
      } else {
        item.dataInfo = res?.data;
      }
      for (const itemInfo of item.dataInfo?.result || []) {
        const { day, hour, minute, seconds } = initTime({
          type: "ms",
          startTime: itemInfo?.status === 1 ? itemInfo?.beginTimeStr : itemInfo?.endTimeStr,
          serverTime: new Date().valueOf(),
        });
        itemInfo.day = day;
        itemInfo.hour = hour;
        itemInfo.minute = minute;
        itemInfo.seconds = seconds;
      }
      setPageInfo(JSON.parse(JSON.stringify(pageData)));
      checkLast(pageData);
      setQueryLoading(false);
    } else {
      setQueryLoading(false);
    }
  };

  //档期加载更多
  const loadActivity = async (index) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: pageInfo?.name || document.title,
        module: "档期",
        btn_name: "查看更多",
        btn_text: "查看更多",
      });
    }
    /* eslint-enable */
    const last = pageInfo.components[index];
    await queryActivity(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
  };

  //获取秒杀
  const querySecKill = async (item: any, pageData: any) => {
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.seckillConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      pageNum: 1,
      pageSize: 4,
      pageId: "H5_SuperMarketing",
      preview: false,
    };
    const res = await searchSecKill(params);
    item.dataInfo = res.data.result;
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  //获取优惠券
  const queryCoupon = async (item: any, pageData: any) => {
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      preview: false,
    };
    const res = await searchCoupon(params);
    item.dataInfo = res?.data;
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  //获取积分优惠券
  const queryPointsCoupon = async (item: any, pageData: any) => {
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      preview: false,
    };
    const res = await searchPointCoupon(params);
    item.dataInfo = res?.data;
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  //获取商品
  const queryProduct = async (item: any, pageData: any, from = 0) => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    const params = {
      componentCode: item.componentCode,
      componentDetailCode: item.productRuleConfigDetails[0].componentDetailCode,
      customPageCode: pageData.pageCode,
      customPageVersion: pageData.version,
      from: from,
      pageSize:
        (item.layoutType === "10" && item.animationType !== "2") || item.layoutType === "20"
          ? 8
          : item.animationType === "2"
            ? 50
            : from > 0
              ? item.morePageSize
              : item.pageSize,
      lastActivitySpuCode:
        item?.dataInfo?.result?.length > 0
          ? item?.dataInfo?.extra?.lastActivitySpuCode
            ? item?.dataInfo?.extra?.lastActivitySpuCode
            : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activitySpuId
          : null,
      pageId: "H5_SuperMarketing",
      preview: false,
    };
    const res: any = await searchProduct(params);
    if (from > 0 && res?.data) {
      res.data.result = res?.data?.result
        ? (item?.dataInfo?.result || []).concat(res.data.result)
        : item?.dataInfo?.result || [];
      item.dataInfo = res?.data;
    } else {
      item.dataInfo = res?.data;
    }
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
    checkLast(pageData);
    setQueryLoading(false);
  };

  //商品加载更多
  const loadProduct = async (index) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: pageInfo?.name || document.title,
        module: "商品",
        btn_name: "查看更多",
        btn_text: "查看更多",
      });
    }
    /* eslint-enable */
    const last = pageInfo.components[index];
    await queryProduct(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
  };

  const queryNavigation = async (item: any, pageData: any, from = 0) => {
    //普通导航
    if (
      item.navigationConfigDetails.length > 0 &&
      (item.styleType === "DEFAULT" || item.styleType === "0" || item.styleType === 0)
    ) {
      await Promise.all(
        item.navigationConfigDetails.map(async (nav: any) => {
          if (nav.type === "ACTIVITY") {
            const params = {
              componentCode: item.componentCode,
              componentDetailCode: nav.componentDetailCode,
              customPageCode: pageData.pageCode,
              customPageVersion: pageData.version,
              from: from,
              pageSize: from > 0 ? nav.morePageSize : nav.pageSize,
              lastActivityId:
                item?.dataInfo?.result?.length > 0
                  ? item?.dataInfo?.extra?.lastActivityId
                    ? item?.dataInfo?.extra?.lastActivityId
                    : item?.dataInfo?.result[(item?.dataInfo?.result?.length ?? 1) - 1]?.activityId
                  : null,
              pageId: "H5_SuperMarketing",
              preview: false,
            };
            const res = await searchNavigationActivity(params);
            nav.dataInfo = res?.data;
          } else if (nav.type === "PRODUCT") {
            const params = {
              componentCode: item.componentCode,
              componentDetailCode: nav.componentDetailCode,
              customPageCode: pageData.pageCode,
              customPageVersion: pageData.version,
              from: from,
              pageSize: from > 0 ? nav.morePageSize : nav.pageSize,
              lastActivitySpuCode:
                item?.dataInfo?.result?.length > 0
                  ? item?.dataInfo?.extra?.lastActivitySpuCode
                    ? item?.dataInfo?.extra?.lastActivitySpuCode
                    : item?.dataInfo?.result[item.dataInfo?.result.length - 1]?.activitySpuId
                  : null,
              pageId: "H5_SuperMarketing",
              preview: false,
            };
            const res = await searchNavigationProduct(params);
            nav.dataInfo = res?.data;
          }
        }),
      );
    }
    //电梯导航
    if (
      item.navigationConfigDetails.length > 0 &&
      (item.styleType === "ELEVATOR" || item.styleType === "1" || item.styleType === 1)
    ) {
      if (item.components?.length > 0) {
        await Promise.all(
          item.components.map(async (com: any) => {
            if (com.type === "PRODUCT") {
              const params = {
                componentCode: com.componentCode,
                componentDetailCode: com.productRuleConfigDetails[0].componentDetailCode,
                customPageCode: pageData.pageCode,
                customPageVersion: pageData.version,
                from: from,
                pageSize:
                  com.layoutType === "10" || com.layoutType === "20" || com.animationType === "2"
                    ? 10
                    : from > 0
                      ? com.morePageSize
                      : com.pageSize,
                lastActivitySpuCode:
                  com?.dataInfo?.result?.length > 0
                    ? com?.dataInfo?.extra?.lastActivitySpuCode
                      ? com?.dataInfo?.extra?.lastActivitySpuCode
                      : com?.dataInfo?.result[(com?.dataInfo?.result?.length ?? 1) - 1]
                        ?.activitySpuId
                    : null,
                pageId: "H5_SuperMarketing",
                preview: false,
              };
              const res: any = await searchProduct(params);
              com.dataInfo = res?.data;
            }
            if (com.type === "ACTIVITY") {
              const params = {
                componentCode: com.componentCode,
                componentDetailCodes: com.activityRuleConfigDetails.map(
                  (detail: any) => detail.componentDetailCode,
                ),
                customPageCode: pageData.pageCode,
                customPageVersion: pageData.version,
                from: from,
                pageSize: from > 0 ? com.morePageSize : com.pageSize,
                lastActivityId:
                  com?.dataInfo?.result?.length > 0
                    ? com?.dataInfo?.extra?.lastActivityId
                      ? com?.dataInfo?.extra?.lastActivityId
                      : com?.dataInfo?.result[com.dataInfo?.result.length - 1]?.activityId
                    : null,
                pageId: "H5_SuperMarketing",
                preview: false,
              };
              const res: any = await searchActivity(params);
              com.dataInfo = res?.data;
            }
          }),
        );
      }
    }
    setPageInfo(JSON.parse(JSON.stringify(pageData)));
  };

  // 组件接口获取内容改异步调用
  const queryComponent = async (components: any, pageData: any) => {
    try {
      const promises = components.map(async (item) => {
        if (item.type === "ACTIVITY") {
          return queryActivity(item, pageData, 0);
        }
        if (item.type === "SECKILL") {
          return querySecKill(item, pageData);
        }
        if (item.type === "COUPON") {
          return queryCoupon(item, pageData);
        }
        if (item.type === "PRODUCT") {
          return queryProduct(item, pageData, 0);
        }
        if (item.type === "POINTSCOUPON") {
          return queryPointsCoupon(item, pageData);
        }
        if (item.type === "NAVIGATION") {
          return queryNavigation(item, pageData, 0);
        }
      });
      await Promise.all(promises);
      return components;
    } catch (error) {
      // console.error("Error in queryComponent:", error);
    }
  };

  const forwardConferenceThr = throttle(
    async (info?: any, auto = false) => {
      const pi = info ?? pageInfo;
      if (auto && !shouldAutoShareConferencePoster(pi)) {
        // 不需要自动弹出海报
        return;
      }
      if (!platform.isApp && !platform.isMini) {
        sharePosterData.current = {
          bizInfo: bizInfoParams({
            contentType: ContentType.conference3,
            contentId: pi.pageCode,
          }),
          envContext: envContextParams({
            sourceScene: SourceScene.conference3,
            sourceSceneId: pi.pageCode,
          }),
          carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
        };
        setShowPoster(true);
      } else {
        shareConferencePosterAction(pi);
      }
      if (auto) {
        autoShareConferencePosterIncrement(pi);
      }
      /* eslint-disable */
      if (track.track) {
        track.track("poster_expose", {
          page_name: pageInfo?.name || document.title,
          expose_content: "会场",
          type: auto ? "自动" : "手动",
          market_id: info?.pageCode,
          market_name: info?.name,
        });
      }
      /* eslint-enable */
    },
    500,
    {
      leading: true,
      trailing: false,
    },
  );

  // 转发会场
  const forwardConference = (info?: any, auto = false, type?: any) => {
    if (type) {
      /* eslint-disable */
      if (track.track) {
        track.track("btn_click", {
          page_name: pageInfo?.name || document.title,
          module: type === 1 ? "分享会场" : "转发赚钱",
          btn_name: type === 1 ? "分享会场" : "转发赚钱",
          btn_text: type === 1 ? "分享会场" : "转发赚钱",
          market_id: pageInfo.pageCode,
          market_name: pageInfo.name,
        });
      }
      /* eslint-enable */
    }
    forwardConferenceThr(info ?? pageInfo, auto);
  };

  //检查最后一个组件是否是动效组件
  const checkAnimationProduct = async (components, pageData) => {
    if (components === "") {
      return [];
    }
    components = JSON.parse(components);
    if (components.length > 0) {
      const last = components[components.length - 1];
      if (last.type === "PRODUCT" && last.animationType === "2") {
        const params = {
          componentCode: last.componentCode,
          componentDetailCode: last.productRuleConfigDetails[0].componentDetailCode,
          customPageCode: pageData.pageCode,
          customPageVersion: pageData.version,
          from: 0,
          pageSize: 50,
          lastActivitySpuCode: null,
          pageId: "H5_SuperMarketing",
          preview: false,
        };
        const res: any = await searchProduct(params);
        if (res?.data?.result?.length <= 4) {
          return components.slice(0, components.length - 1);
        } else {
          return components;
        }
      } else {
        return components;
      }
    } else {
      return components;
    }
  };

  const showPosterIfNeedOnInit = (data?: any) => {
    const autoForward = search.get("autoForward");
    if (autoForward) {
      forwardConference(data ?? pageInfo);
    } else {
      !identity() && forwardConference(data ?? pageInfo, true);
    }
  };

  const [isInvalid, setIsInvalid] = useState(false);
  const toHomeTimer = React.useRef<NodeJS.Timeout>();
  const [invalidTimeout, setInvalidTimeout] = useState(2);
  const toHome = React.useCallback(() => {
    if (toHomeTimer.current) {
      clearInterval(toHomeTimer.current);
    }
    setIsInvalid(false);
    navigate2Home();
  }, []);
  const onInvalid = React.useCallback(
    () => {
      setIsInvalid(true);
      setInvalidTimeout(2);
      let timeout = invalidTimeout;
      toHomeTimer.current = setInterval(() => {
        setInvalidTimeout((prev) => prev - 1);
        timeout--;
        if (timeout <= 0) {
          toHome();
        }
      }, 1000);
    },
    [toHome],
  );

  //获取页面信息，并逐个获取组件信息
  const queryPage = async () => {
    //根据路径上是否存在isPreview,来走预览
    let res: any = null;
    if (search.get("preview") || search.get("preview") === "true") {
      res = await preview({
        customPageCode: search.get("customPageCode"),
        customPageVersion: search.get("version"),
      });
    } else {
      res = await searchPageInfo({
        customPageCode: search.get("customPageCode"),
      });
    }
    if (res.success === false) {
      Toast.show({
        content: "正为您努力搬运信息中，请您稍后再试！",
      });
      setLoading(false);
      return;
    }
    if ((res.data?.ruleAuthorityStatus ?? 0) !== 0) {
      // 会场已过期或当前用户无权限
      onInvalid();
      return;
    }
    document.title = `${calendarDay} ${res?.data?.name}`;
    setShareBtnSwitch(res?.data?.shareConfig?.shareButtonSwitch === 0 ? false : true);
    //如果最后一个组件是商品组件，且是动效的，需要判断他是否展示，不展示就从组件里去除
    if (res?.data) {
      res.data.components = await checkAnimationProduct(res.data.components, res.data);
    }
    //对组件进行修改,将电梯导航的子组件塞入电梯导航
    if ((res?.data?.components?.length ?? 0) > 0) {
      const pageCom: any = [];
      for (const nav of res.data.components) {
        if (
          nav.type === "NAVIGATION" &&
          (nav.styleType === "1" || nav.styleType === 1) &&
          nav.componentCodes?.length > 0
        ) {
          nav.components = [];
          for (const com of res.data.components) {
            for (const navCom of nav.componentCodes) {
              if (navCom.componentCode === com.componentCode) {
                nav.components.push(com);
                com.deleteFlag = true; //删除标记
              }
            }
          }
        }
      }
      for (const com of res.data.components) {
        if (!com.deleteFlag) {
          pageCom.push(com);
        }
      }
      res.data.components = pageCom;
    }
    setPageInfo(res.data);
    //对组件进行处理
    await queryComponent(res.data.components.slice(0, componentIndex), res.data);
    //判断最后一个组件是否是档期或者商品，并判断是否还需要加载更多
    // await checkLast(res.data);
    setPageInfo(res.data);
    sessionStorage.setItem("conference3_page_title", res.data?.name);
    setLoading(false);
    await setTitleNShareCardInfo({ ...res.data, name: `${calendarDay} ${res?.data?.name}` });

    if (res.data.showPopupImage) {
      // 需要请求公益弹窗
      try {
        const imageUrl = await requestConferencePopupImage(res.data?.pageCode);
        if (imageUrl && imageUrl.length > 0) {
          setPopupImageUrl(imageUrl);
        } else {
          showPosterIfNeedOnInit(res.data);
        }
      } catch (e) {
        showPosterIfNeedOnInit(res.data);
        console.error("请求公益弹窗报错", e);
      }
    } else {
      showPosterIfNeedOnInit(res.data);
    }
    //页面埋点
    /* eslint-disable */
    if (search.get("materialId")) {
      track.pageView("会场", {
        market_id: res.data?.pageCode,
        market_name: res.data?.name,
        material_id: search.get("materialId"),
      });
    } else {
      track.pageView("会场", {
        market_id: res.data?.pageCode,
        market_name: res.data?.name,
      });
    }
    /* eslint-enable */
  };

  //加载更多
  const loadMore = async () => {
    const last = pageInfo.components[pageInfo.components.length - 1];
    if (last.type === "ACTIVITY") {
      await queryActivity(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
    }
    if (last.type === "PRODUCT") {
      await queryProduct(last, pageInfo, last?.dataInfo?.result?.length ?? 0);
    }
  };

  const onClosePoster = () => {
    sharePosterData.current = undefined;
    setShowPoster(false);
  };

  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  const goHome = () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: pageInfo?.name || document.title,
        module: "首页",
        btn_name: "首页",
        btn_text: "首页",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  };

  const goCart = () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: pageInfo?.name || document.title,
        module: "购物车",
        btn_name: "购物车",
        btn_text: "购物车",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2`);
    }
  };

  useLayoutEffect(() => {
    if (!pageInfo || pageInfo.pageCode !== customPageCode) {
      queryPage();
    }
  }, [customPageCode]);

  // const setScrollLocation = debounce((scrollTop) => {
  //   sessionStorage.setItem("scrollLoaction", scrollTop);
  // }, 500);

  const isPc = () =>
    navigator.platform.match(/Win|Mac|Windows/) &&
    !navigator.userAgent.match(/ipad|iphone|ipod|android/i);

  const fetchCartTotal = async () => {
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartTotal(cartSkuNum);
  };

  // const onScroll = throttle(() => {
  //   const dom: any = document.getElementById("page");
  //   console.log(
  //     "滚动高度--",
  //     dom.scrollTop
  //   );
  //   if (pxtorem(dom.scrollTop) > pxtorem(44)) {
  //     setSticky(true);
  //   } else {
  //     setSticky(false);
  //   }
  // }, 100);

  const getAppVersion = async () => {
    if (platform.isApp) {
      const appVersion = (await getAppSystem())?.appVersion;
      if (appVersion && versionsCompare(appVersion, "7.54.0") >= 0) {
        showSaleCalendar.current = true;
      }
    } else {
      showSaleCalendar.current = true;
    }
  };

  useEffect(() => {
    fetchCartTotal();
    getAppVersion();
  }, []);

  const loadMoreComponents = async () => {
    if (pageInfo.components?.length <= componentIndex || loading) {
      return;
    }
    setLoadingComponents(true);
    const index = componentIndex + 5;
    await queryComponent(pageInfo.components.slice(componentIndex, index), pageInfo);
    setComponentIndex(index);
    setLoadingComponents(false);
  };

  const last = pageInfo?.components?.[pageInfo?.components?.length - 1];

  const toSaleCalendarPage = () => {
    if (platform.isMini) {
      navigate2SaleCalendar({});
    } else {
      navigate2H5({
        path: "/mx-shop-micro/sale-calendar",
      });
    }
  };

  return (
    <>
      {pageInfo && (
        <div
          className={styles.App}
          style={{
            background: pageInfo.backgroundImgUrl
              ? `url(${pageInfo.backgroundImgUrl}) no-repeat `
              : "unset",
            backgroundSize: "100% 100%",
            backgroundColor: pageInfo.backgroundColor || "rgba(241, 242, 245, 1)",
            maxWidth: isPc() ? pxtorem(375) : "unset",
          }}
          ref={pageDom}
          id="page"
        // onScroll={onScroll}
        >
          {loading && (
            <div className={styles.loading}>
              <Image src={loadingPng} width={pxtorem(30)}></Image>
            </div>
          )}
          {(platform.isH5 || platform.isWxH5) && (
            <>
              <div className={styles.back_top_sticky}>
                <div className={styles.left}>
                  <img
                    onClick={() => goBackOrHome()}
                    className={styles.left_back}
                    src="https://akim-oss.aikucun.com/b07f427927175d0e745611f0d4342969f9f468f3_1701850313426_82.png"
                  />
                  <div className={styles.line}></div>
                  <img
                    onClick={() => goHome()}
                    className={styles.left_home}
                    src="https://akim-oss.aikucun.com/8cb8a10dbe75e006c04d3ff2036b4d61353908d6_1701850383174_61.png"
                  />
                  <div onClick={() => goHome()} className={styles.text}>
                    首页
                  </div>
                </div>
                <div className={styles.center}>
                  <img
                    className={styles.img}
                    src="https://akim-oss.aikucun.com/4a59601c8f6727f5ba6ec791e07a9417b0d1e346_1701867890366_22.png"
                  />
                  <div className={styles.text}>全场100%正品</div>
                </div>
                <div className={styles.right} onClick={() => goCart()}>
                  <img
                    className={styles.cart}
                    src="https://akim-oss.aikucun.com/5c81c6bc5cc06cc592f9a107f93ea80acf8c27d8_1701850359831_90.png"
                  />
                  {cartTotal > 0 && <div className={styles.num}>{cartTotal}</div>}
                </div>
              </div>
            </>
          )}
          {showSaleCalendar.current && pageInfo.isTodayBuy && (
            <img
              className={styles["sale-calendar"]}
              src={
                platform.isApp || ["R", "E"].includes(currentRole)
                  ? "https://akim-oss.aikucun.com/mshop/fbb33f07aa0644f7472e69667125fc9d5a6d3909_1723801934391_89.png"
                  : "https://akim-oss.aikucun.com/mshop/fbb33f07aa0644f7472e69667125fc9d5a6d3909_1724654626488_45.png"
              }
              onClick={toSaleCalendarPage}
            />
          )}
          <div>
            {pageInfo?.components?.slice(0, componentIndex).map((item, index) => {
              return (
                <div key={index}>
                  {item.type === "IMAGE" && (
                    <React.Suspense>
                      <ImageCom propData={item} pageData={pageInfo} resourceRank={index}></ImageCom>
                    </React.Suspense>
                  )}
                  {item.type === "BANNER" && (
                    <React.Suspense>
                      <BannerCom
                        propData={item}
                        pageData={pageInfo}
                        resourceRank={index}
                      ></BannerCom>
                    </React.Suspense>
                  )}
                  {item.type === "CUBE" && (
                    <React.Suspense>
                      <CubeCom propData={item} pageData={pageInfo} resourceRank={index}></CubeCom>
                    </React.Suspense>
                  )}
                  {item.type === "TEXT" && (
                    <React.Suspense>
                      <TextCom propData={item} pageData={pageInfo} resourceRank={index}></TextCom>
                    </React.Suspense>
                  )}
                  {item.type === "VIDEO" &&
                    item.videoConfigDetails[0].height > 0 &&
                    item.videoConfigDetails[0].width > 0 && (
                      <React.Suspense>
                        <VideoCom
                          propData={item}
                          pageData={pageInfo}
                          resourceRank={index}
                        ></VideoCom>
                      </React.Suspense>
                    )}
                  {item.type === "ACTIVITY" && !loading && !isEmpty(item.dataInfo) && (
                    <React.Suspense>
                      <ActivityCom
                        propData={item}
                        pageData={pageInfo}
                        resourceRank={index}
                        last={index === pageInfo.components.length - 1 ? true : false}
                        loadMore={() => loadActivity(index)}
                      ></ActivityCom>
                    </React.Suspense>
                  )}
                  {item.type === "SECKILL" && !loading && item?.dataInfo?.length > 0 && (
                    <React.Suspense>
                      <SecKillCom
                        propData={item}
                        pageData={pageInfo}
                        resourceRank={index}
                      ></SecKillCom>
                    </React.Suspense>
                  )}
                  {item.type === "COUPON" && !loading && (
                    <React.Suspense>
                      <CouponCom
                        propData={item}
                        pageData={pageInfo}
                        resourceRank={index}
                      ></CouponCom>
                    </React.Suspense>
                  )}
                  {item.type === "PRODUCT" && !loading && (
                    <React.Suspense>
                      <ProductCom
                        propData={item}
                        pageData={pageInfo}
                        resourceRank={index}
                        subItem={item}
                        eventId="07_learnmaterial_materiallist_show"
                        eventParam={{ type: "product" }}
                        index={index}
                        last={index === pageInfo.components.length - 1 ? true : false}
                        loadMore={() => loadProduct(index)}
                      ></ProductCom>
                    </React.Suspense>
                  )}
                  {item.type === "POINTSCOUPON" && !loading && (
                    <React.Suspense>
                      <PointsCoupon propData={item} pageData={pageInfo} resourceRank={index} />
                    </React.Suspense>
                  )}
                  {item.type === "NAVIGATION" && !loading && (
                    <React.Suspense>
                      <NavigationCom
                        propData={item}
                        pageData={pageInfo}
                        resourceRank={index}
                        last={index === pageInfo.components.length - 1 ? true : false}
                        sticky={true}
                      ></NavigationCom>
                    </React.Suspense>
                  )}
                </div>
              );
            })}
          </div>
          {pageInfo?.components?.length > componentIndex ? (
            <InfiniteScroll loadMore={loadMoreComponents} hasMore={true} threshold={300}>
              <InfiniteScrollComponent />
            </InfiniteScroll>
          ) : (
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
              <Bottom
                loading={loading}
                hasMore={hasMore}
                reachBottom={pageInfo.components?.length > 0 && last.type !== "NAVIGATION"}
              />
            </InfiniteScroll>
          )}
          {identity() && !showPoster && shareBtnSwitch && (
            <div className={styles.share_btn} style={{ maxWidth: pxtorem(375) }}>
              <Image
                className={styles.share_img}
                src={shareMarketPng}
                onClick={() => forwardConference(null, false, 1)}
              ></Image>
            </div>
          )}
          {!identity() && !showPoster && shareBtnSwitch && (
            <div className={styles.share_btn} style={{ maxWidth: pxtorem(375) }}>
              <Image
                className={styles.share_img}
                src={makeMoneyPng}
                onClick={() => forwardConference(null, false, 2)}
              ></Image>
            </div>
          )}
          {sharePosterData.current && (
            <PosterSwipe
              show={showPoster}
              posterData={sharePosterData.current}
              shareType="CONFERENCE3"
              btnConfig={["copy"]}
              close={onClosePoster}
            />
          )}
          {/* {platform.isH5 && <SideBar />} */}
          {(pageInfo === null ||
            pageInfo === undefined ||
            pageInfo?.components === "" ||
            pageInfo.components?.length <= 0) && (
              <div className={styles.nullStatus}>
                <Image className={styles.noSecKill} src={noSecKill}></Image>
                <div className={styles.text}>暂无活动</div>
              </div>
            )}
          {popupImageUrl && (
            <SaveImagePopup
              onMaskClick={() => {
                setPopupImageUrl(undefined);
                showPosterIfNeedOnInit(pageInfo);
              }}
              imageUrl={popupImageUrl}
            />
          )}
        </div>
      )}
      {/* 领券无感验证专用 */}
      <div id="captcha"></div>
      <CenterPopup visible={isInvalid} onClick={toHome} bodyClassName={styles.conference_invalid_popup}>
        <div className={styles.title}>提示</div>
        <div className={styles.content}>活动已结束，{invalidTimeout}s后进入首页</div>
      </CenterPopup>
    </>
  );
};

export default inject("appStore")(observer(Home));
