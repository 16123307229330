import {
  getWhetherPopup
} from "@/api/promote";
import { Dialog, Modal } from "antd-mobile";
import React, { useEffect, useState } from "react";
import RightsUpgradePop from "./RightsUpgradePop";

type Props = {
  businessCode: string;
};

const BizPopup: React.FC<Props> = ({ businessCode }) => {
  const showRightsUpgradePop = () => {
    const handle = Modal.show({
      content: <RightsUpgradePop onClose={() => handle.close()} />,
      bodyStyle: { width: "300px", borderRadius: "10px", paddingTop: "10px" },
      closeOnMaskClick: true,
    });
  };
  const init = async () => {
    const res = await getWhetherPopup({ businessCode });
    if (!res?.data?.[0]) { return; }
    console.log(3333, res?.data, res?.data?.filter((item: any) => item.popupCode === "RightsUpgrade"));
    const { popupFlag, popupCode, popContentType, windowPopContent } = res?.data?.find((item: any) => item.popupCode === "RightsUpgrade") || {};
    if (!popupFlag) { return; }
    if (popupCode === "RightsUpgrade") {
      // 会员权益升级
      showRightsUpgradePop();
      return;
    }
    // 弹窗内容类型 1:图片 2:文案
    // if (popContentType === 2) {
    //   Dialog.alert({
    //     title: windowPopContent.title,
    //     content: windowPopContent.content,
    //     confirmText: "我知道了",
    //     className: "dialog__private",
    //   });
    // }
  };

  useEffect(() => {
    init();
  }, []);
  return <></>;
};

export default BizPopup;