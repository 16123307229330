import { followBrand, unfollowBrand } from "@/api/brand";
import { queryHideInfo, setHideInfo } from "@/api/member";
import HideIcon1 from "@/assets/activityHide1.png";
import HideIcon2 from "@/assets/activityHide2.png";
import JumpIcon from "@/assets/jump-icon.png";
import Clickable from "@/components/clickable";
import HidePopup from "@/components/hide-popup";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import { ActivityBrandModel } from "@akc/biz-api";
import { Dialog, Image, Toast } from "antd-mobile";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useActivate } from "react-activation";
import { useSelectedActivity } from "../hooks/useActivityProduct";
import styles from "./Header.module.less";

interface ExtraActivityModel {
  /** 品牌素材信息 */
  materialResult?: {
    total: string | number;
    materialsResultVOList: ({ materialNo: string } & Record<string, any>)[]
  }
}

interface Props {
  brandData: ActivityBrandModel & ExtraActivityModel;
  isInitialFollowing?: boolean;
  forceHideRight?: boolean;
  previous_page_name?: string;
}
const Header: React.FC<Props> = ({ brandData, isInitialFollowing, forceHideRight = false, previous_page_name = "" }) => {
  const [isFollowing, setIsFollowing] = useState<boolean | undefined>();
  const [hideVisible, setHideVisible] = useState<boolean>(false);
  const [hideStatus, setHideStatus] = useState<boolean>(false);
  const [hideType, setHideType] = useState<"brand" | "brand_product">("brand");
  const [hidePoupVisible, setHidePoupVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsFollowing(isInitialFollowing);
  }, [isInitialFollowing]);

  const navigate = useNavigation();

  const toggleFollowing = async () => {
    if (!isFollowing) {
      setIsFollowing(true);
      brandData.activityList?.[0]?.brandId &&
        (await followBrand(brandData.activityList[0].brandId));
      Toast.show("关注成功");
    } else {
      setIsFollowing(false);
      brandData.activityList?.[0]?.brandId &&
        (await unfollowBrand(brandData.activityList[0].brandId));
      Toast.show("取消关注成功");
    }
  };

  const getUserRole = () => {
    const userInfo =
      isEmpty(localStorage?.userinfo) || localStorage?.userinfo === "undefined"
        ? null
        : JSON.parse(localStorage?.userinfo);
    // 管理员
    if (userInfo?.currentRole === 4) {
      return "A";
    }
    if (userInfo?.currentRole === 2) {
      return "R";
    }
    if (userInfo?.currentRole === 1) {
      return "C";
    }
    return "E";
  };

  const getHideInfo = async () => {
    if (getUserRole() === "A" && brandData?.brandId) {
      const brandId = brandData.brandId;
      const data = await queryHideInfo({
        bizNo: brandId,
        hideType: "brand",
      });
      if (data) {
        setHideVisible(!!data?.isHide);
        setHideStatus(!!data?.status);
        setHideType(data?.hideType || "brand");
      }
    } else {
      setHideVisible(false);
    }
  };

  const handleHideConfirm = (hideType) => {
    setHideInfo({
      hideType,
      hideAction: "hide",
      bizNo: brandData?.brandId,
    }).then((data) => {
      if (data) {
        Toast.show("隐藏成功");
        setHideStatus(true);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }
    });
  };

  const handleHideCancel = () => {
    Dialog.confirm({
      title: "取消隐藏",
      content: "请确认是否要取消隐藏该品牌？",
      className: "dialog_hide",
      cancelText: "取消",
      confirmText: "确认",
      onConfirm: () => {
        setHideInfo({
          hideType,
          hideAction: "cancel_hide",
          bizNo: brandData?.brandId,
        }).then((data) => {
          if (data) {
            Toast.show("已成功取消隐藏");
            setHideStatus(false);
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        });
      },
    });
  };

  useEffect(() => {
    getHideInfo();
  }, [brandData]);

  useActivate(() => {
    getHideInfo();
    setHidePoupVisible(false);
  });

  const selectedActivity = useSelectedActivity();
  const brandStoryActivityId = useMemo(() => {
    let activity = selectedActivity;
    if (!activity?.activityId) {
      activity = brandData.activityList?.[1];
    }
    if (
      (activity?.brandStoryVO?.brandStory || activity?.brandStoryVO?.brandVideo) &&
      activity.activityId
    ) {
      return activity.activityId;
    }
  }, [selectedActivity, brandData]);
  const brandMaterialCount = isNaN(Number(brandData?.materialResult?.total)) ? 0 : Number(brandData.materialResult?.total);
  const isC = getUserRole() === "C";

  const goBrandMaterial = () => {
    if (brandMaterialCount === 0) {
      return;
    }
    if (brandMaterialCount === 1 && Array.isArray(brandData?.materialResult?.materialsResultVOList)) {
      const vo = brandData?.materialResult?.materialsResultVOList[0];
      if (vo?.materialNo) {
        navigate.navigate2MaterialContent(7, vo.materialNo);
      }
    } else if (brandData.brandId) {
      navigate.navigate2BrandMaterialList(brandData.brandId);
    }
  };
  console.log("brandData.brandLogoUrl", brandData, brandMaterialCount, isC);

  const hasGoods = brandData.activityList?.length === 1;
  const hasActivityList = !!brandData.activityList && brandData.activityList?.length > 1;
  const hasBrandMaterial = !!brandMaterialCount && !isC;
  return (
    <>
      <div
        style={{ backgroundImage: `url(${selectedActivity?.backgroundBrandVideoUrl})` }}
        className={styles.header}
      >
        <div className={styles.headerContainer}>
          <Clickable
            className={styles.brandCard}
            onClick={() => {
              brandStoryActivityId && navigate.navigate2BrandStory(brandStoryActivityId);
            }}
            trackData={{
              resource_type: "品牌介绍",
              btn_name: "展开",
            }}
            trackContextKeys={[
              "tab_name",
              "tab_id",
              "tab_rank",
              "merchant_shop_code",
              "activity_id",
            ]}
          >
            <div
              className={styles.brandLogo}
              style={{ backgroundImage: `url(${brandData.brandLogoUrl})` }}
            ></div>
            <div className={styles.brandText}>
              <div className={styles.brandName}>
                {brandData.activityList?.length === 1 && (
                  <span className={styles.numTag}>{brandData.totalOnlineProductCount}款</span>
                )}
                <span className={`${styles.textEllipsis} ${styles.brandNameText}`}>
                  {brandData.brandName}
                </span>
                {brandStoryActivityId && (
                  <img
                    className={styles.moreBrandInfoButton}
                    src="https://akim-oss.aikucun.com/mshop/41f4c0be10cecbbca18daeb2e4cf6b8d45dc0376_1690879361475_45.png"
                  />
                )}
              </div>
              <div className={styles.brandDesc}>
                {selectedActivity?.brandStoryVO?.brandIntr && (
                  <>
                    <span
                      className={`${styles.textEllipsis} ${styles.brandDescText}`}
                    // style={{ maxWidth: hideVisible ? pxtorem(110) : pxtorem(135) }}
                    >
                      {selectedActivity?.brandStoryVO?.brandIntr}
                    </span>
                  </>
                )}
              </div>
              <div className={styles.brandDesc}>
                {hasGoods && (
                  <>
                    <span>共{brandData.totalOnlineProductCount}款商品</span>
                    {hasGoods && (hasActivityList || hasBrandMaterial) && (<span className={styles.brandDescSep}>|</span>)}
                  </>
                )}
                {hasActivityList && (
                  <>
                    {brandData.activityList && brandData.activityList?.length > 1 && (
                      <span>共{brandData.activityList.length - 1}个会场</span>
                    )}
                    {hasActivityList && hasBrandMaterial && (<span className={styles.brandDescSep}>|</span>)}
                  </>
                )}
                {hasBrandMaterial && (
                  <>
                    <Clickable
                      className={styles.brandMaterialCountContainer}
                      onClick={(e) => {
                        goBrandMaterial();
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      trackData={{
                        resource_type: "品牌介绍",
                        resource_name: "品牌素材",
                        brand_id: brandData.brandId,
                        page_name: document.title,
                        previous_page_name,
                      }}
                    >
                      <span className={styles.brandMaterialCount}>
                        品牌素材 · {brandMaterialCount}
                      </span>
                      <Image src={JumpIcon} className={styles.jumpIcon} />
                    </Clickable>
                  </>
                )}
              </div>
            </div>
            {!forceHideRight && <div className={styles.followButtonContainer}>
              {typeof isFollowing !== "undefined" && (
                <Clickable
                  className={`${styles.followButton} ${isFollowing ? styles.followed : styles.unfollowed
                    }`}
                  onClick={toggleFollowing}
                  trackData={{
                    resource_type: "品牌介绍",
                    btn_name: isFollowing ? "取消关注" : "关注",
                  }}
                  trackContextKeys={[
                    "tab_name",
                    "tab_id",
                    "tab_rank",
                    "merchant_shop_code",
                    "activity_id",
                  ]}
                >
                  {!isFollowing && (
                    <>
                      {/* <img
                      className={styles.followButtonIcon}
                      src="https://akim-oss.aikucun.com/0a57cb53ba59c46fc4b692527a38a87c78d84028_1698900243112_16.png"
                    /> */}
                      <span>关注</span>
                    </>
                  )}
                  {isFollowing && <span>已关注</span>}
                </Clickable>
              )}
              {hideVisible && (
                <div
                  className={styles.hideButton}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    hideStatus ? handleHideCancel() : setHidePoupVisible(true);
                  }}
                >
                  <img className={styles.hideButtonIcon} src={hideStatus ? HideIcon2 : HideIcon1} />
                </div>
              )}
            </div>}
          </Clickable>
        </div>
      </div>
      {/* 选择隐藏方式 */}
      <HidePopup
        visible={hidePoupVisible}
        setVisible={setHidePoupVisible}
        curHideType={hideType}
        onConfirm={handleHideConfirm}
      />
    </>
  );
};

export default Header;
