import { Skeleton } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

/**
 * 骨架屏
 */
const SkeletonComp: React.FC = () => {
    return (
        <div className={styles.pdSkeleton}>
            <div className={styles.card}>
                <Skeleton animated className={styles.skeleton1} />
                <Skeleton animated className={styles.skeleton2} />
                <Skeleton animated className={styles.skeleton3} />
            </div>
            <div className={styles.card}>
                <Skeleton animated className={styles.skeleton1} />
                <Skeleton animated className={styles.skeleton2} />
                <Skeleton animated className={styles.skeleton3} />
            </div>
            <div className={styles.card}>
                <Skeleton animated className={styles.skeleton1} />
                <Skeleton animated className={styles.skeleton2} />
                <Skeleton animated className={styles.skeleton3} />
            </div>
            <div className={styles.card}>
                <Skeleton animated className={styles.skeleton1} />
                <Skeleton animated className={styles.skeleton2} />
                <Skeleton animated className={styles.skeleton3} />
            </div>
        </div>
    );
};

export default SkeletonComp;
