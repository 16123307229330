import { queryFilterBoardData, queryMultiActivityBrandPageInfoServ } from "@/api/activity";
import { queryBaseDetailServ } from "@/api/appH5";
import loadingPng from "@/base64";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import TopNavigation from "@/components/top-navigation";
import {
  onShareAppMessageByB2R,
  onShareAppMessageByHome,
  shareB2RPosterAction,
  sharePosterParams4H5B2R,
} from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { hostEnv, platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { ActivityBrandModel } from "@akc/biz-api";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { Image } from "antd-mobile";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import ProductList from "./components/LandingProductList";
import PageEmptyStatus from "./components/PageEmptyStatus";
import ShopList from "./components/ShopList";
import { ActivityProductProvider } from "./hooks/useActivityProduct";
import styles from "./landing.module.less";

const Landing: React.FC = () => {
  const track = Track();
  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const { currentRole } = useCommonContext();

  const [search] = useSearchParams();
  const actId = search.get("actId") || "";

  const [pageInfo, setPageInfo] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [isSticky, setIsSticky] = useState(true);
  const [disableScroll, setDisableScroll] = useState(false);
  const [brandList, setBrandList] = useState<any>([]);
  const [activeBrandIndex, setActiveBrandIndex] = useState(0);
  const [brandData, setBrandData] = useState<ActivityBrandModel>({});
  const [selectedActivityId, setSelectedActivityId] = useState("");
  const [showEmptyStatus, setShowEmptyStatus] = useState(false);
  const [pageTrackData, setPageTrackData] = useState<any>(null);
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  // ref
  const PageScrollRef = useRef<HTMLDivElement>(null);
  const ProductListRef = useRef<any>(null);
  // const ShopRef = useRef(null);

  const onScrollTop = () => {
    PageScrollRef?.current?.scrollTo(0, 0);
  };

  const getTabList = async () => {
    const data = await queryFilterBoardData({
      scene: "SALES_REBATE_PAGE",
      promoActivityIds: [actId],
      needBrandWhenOnlyOne: true,
    });
    if (data?.brandList?.length) {
      let localBrandList =
        data?.brandList?.length === 1
          ? data?.brandList
          : [{ brandId: "", brandName: "推荐" }, ...data.brandList];
      if (search.get("brandId") && localBrandList.length > 1) {
        let index = localBrandList.findIndex((item) => item.brandId === search.get("brandId"));
        setActiveBrandIndex(index !== -1 ? index : 0);
      }
      setBrandList(localBrandList);
    }
  };

  const getPageInfo = async () => {
    if (!actId) {
      return;
    }
    setPageLoading(true);
    const data =
      (await queryBaseDetailServ({
        actId,
      })) || {};
    let relevanceTypeStr =
      data?.relevanceType === "PRODUCT"
        ? "商品"
        : data?.relevanceType === "STORE"
          ? "店铺"
          : "档期";
    let trackData = {
      pageName: "激励活动落地页",
      previous_page_name: search.get("page_name") || "",
      resource_id: search.get("actId"),
      domain: "激励活动",
      resource_type: relevanceTypeStr,
      resource_content: relevanceTypeStr,
    };
    setPageInfo(data);
    setPageTrackData(trackData);
    setTrackData(trackData);
    document.title = data?.sharePageInfoConfig?.pageTitle || "今日甄选活动";
    if (["PRODUCT", "ACTIVITY"].includes(data.relevanceType)) {
      getTabList();
    }
    setPageLoading(false);
    track.track("$pageview", { ...trackData, page_code: search.get("actId") });
  };

  const getActivityList = async ({ isInit }) => {
    console.error("获取档期列表", {
      isInit,
    });
    if (!brandList?.[activeBrandIndex]?.brandId) {
      setBrandData({});
      return;
    }
    const data = await queryMultiActivityBrandPageInfoServ({
      pageId: `${hostEnv}_Shop`,
      scene: "SALES_REBATE_PAGE",
      promoActivityIds: [actId],
      brandId: brandList[activeBrandIndex]?.brandId,
    });
    if (data?.activityList?.length) {
      data.activityList.forEach((item, index) => (item.rank = index));
      data.activityList.unshift({
        activityAggrTitle: "全部",
        activityAggrSubTitleList: [`共${data.totalOnlineProductCount}款`],
        brandStoryVO: data.activityList[0].brandStoryVO,
        merchantShopCode: data.activityList[0].merchantShopCode,
        brandId: data.brandId,
        backgroundBrandVideoUrl: data.activityList[0].backgroundBrandVideoUrl,
      });
      if (isInit && search.get("activityId")) {
        setSelectedActivityId(search.get("activityId") || "");
      } else {
        setSelectedActivityId(data.activityList[0].activityId);
      }
    }
    setBrandData(data);
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  const onShare = async (e) => {
    e.stopPropagation();

    // track.track("resource_click", {
    //   page_name: "派单任务",
    //   previous_page_name: search.get("page_name"),
    //   resource_type: "派单任务",
    //   resource_content: isProduct ? "商品" : "素材",
    //   resource_rank: 1,
    //   ...trackParams,
    //   btn_name: btnName || "查看详情",
    //   tab_name: tabList[tabIndex].value,
    // });

    if (platform.isH5) {
      const sharePosterData = await sharePosterParams4H5B2R({
        contentId: actId,
        brandId: brandList[activeBrandIndex]?.brandId || "",
        contentDetailId: ProductListRef?.current?.selectedActivity?.activityId || "",
        shareBizType: pageInfo.relevanceType,
        shareMainTitle: pageInfo?.sharePageInfoConfig?.shareTitle,
        shareSubTitle: pageInfo?.sharePageInfoConfig?.shareSubTitle,
        sourceScene: "IncentiveActivityLanding",
      });
      setCustomEditData({
        contentType: "B2R",
        contentId: actId,
        brandId: brandList[activeBrandIndex]?.brandId || "",
        contentDetailId: ProductListRef?.current?.selectedActivity?.activityId || "",
        shareBizType: pageInfo.relevanceType,
        shareMainTitle: pageInfo?.sharePageInfoConfig?.shareTitle,
        shareSubTitle: pageInfo?.sharePageInfoConfig?.shareSubTitle,
        sourceScene: "IncentiveActivityLanding",
      });
      setSharePosterData(sharePosterData);
      setShowPoster(true);
    } else {
      shareB2RPosterAction({
        actId,
        brandId: brandList[activeBrandIndex]?.brandId || "",
        contentDetailId: ProductListRef?.current?.selectedActivity?.activityId || "",
        bizType: pageInfo.relevanceType,
        shareMainTitle: pageInfo?.sharePageInfoConfig?.shareTitle || "",
        shareSubTitle: pageInfo?.sharePageInfoConfig?.shareSubTitle || "",
        sourceScene: "IncentiveActivityLanding",
      });
    }
  };

  const handleTrackResourceClick = ({ btnName }) => {
    if (pageTrackData) {
      const activeActivityIndex =
        brandData?.activityList?.findIndex((item) => {
          return item.activityId === selectedActivityId;
        }) || 0;
      track.track("resource_click", {
        ...pageTrackData,
        brand_name: brandList[activeBrandIndex]?.brandName,
        brand_rank: activeBrandIndex + 1,
        tab_name: brandData?.activityList?.[activeActivityIndex]?.activityAggrTitle || "",
        tab_rank: activeActivityIndex + 1,
        btn_name:
          (btnName === "brand"
            ? brandList[activeBrandIndex]?.brandName
            : brandData?.activityList?.[activeActivityIndex]?.activityAggrTitle) || "",
      });
    }
  };

  const footerDom = () => {
    if (currentRole === "C") {
      return (
        <div
          className={`${styles.footer__btn} ${styles.footer__btn_c}`}
          onClick={(e) => onShare(e)}
        >
          分享
        </div>
      );
    } else {
      return (
        <div
          className={`${styles.footer__btn} ${styles.footer__btn_e}`}
          onClick={(e) => onShare(e)}
        >
          转发赚钱
        </div>
      );
    }
  };

  useEffect(() => {
    onShareAppMessageByB2R({
      contentType: "B2R",
      contentId: actId,
      brandId: brandList[activeBrandIndex]?.brandId || "",
      activityId: selectedActivityId || "",
      shareBizType: pageInfo.relevanceType,
      shareMainTitle: pageInfo?.sharePageInfoConfig?.shareTitle,
      shareSubTitle: pageInfo?.sharePageInfoConfig?.shareSubTitle,
    });
  }, [pageInfo, activeBrandIndex, selectedActivityId]);

  useEffect(() => {
    if (brandList.length > 0 && pageInfo.relevanceType === "ACTIVITY") {
      getActivityList({ isInit: true });
    }
  }, [brandList]);

  useEffect(() => {
    // 埋点
    if (pageTrackData) {
      const activeActivityIndex =
        brandData?.activityList?.findIndex((item) => {
          return item.activityId === selectedActivityId;
        }) || 0;
      let obj = {
        ...pageTrackData,
        brand_name: brandList[activeBrandIndex]?.brandName,
        brand_rank: activeBrandIndex + 1,
        tab_name: brandData?.activityList?.[activeActivityIndex]?.activityAggrTitle || "",
        tab_rank: activeActivityIndex + 1,
      };
      setPageTrackData(obj);
      setTrackData(obj);
    }
  }, [brandList, activeBrandIndex, selectedActivityId]);

  useEffect(() => {
    onScrollTop();
    if (brandList.length > 0 && pageInfo.relevanceType === "ACTIVITY") {
      getActivityList({ isInit: false });
    }
    handleTrackResourceClick({
      btnName: "brand",
    });
  }, [activeBrandIndex]);

  useEffect(() => {
    handleTrackResourceClick({
      btnName: "tab",
    });
  }, [selectedActivityId]);

  useActivate(() => {
    console.error("in useActivate");
  });

  // useEffect(() => {
  //   // if (pageLoading) {
  //   //   return;
  //   // }
  //   // if (pageInfo.relevanceType) {
  //   //   if (pageInfo.relevanceType === "STORE") {
  //   //     setShowEmptyStatus(cardList.length === 0);
  //   //   } else {
  //   //     setShowEmptyStatus(brandList.length === 0 && cardList.length === 0);
  //   //   }
  //   // } else {
  //   //   setShowEmptyStatus(true);
  //   // }
  //   console.error("设置空态——", cardList.length);
  //   if (pageLoading) {
  //     return;
  //   }
  //   if (pageInfo.relevanceType === "STORE") {
  //     setShowEmptyStatus(cardList.length === 0);
  //   } else {
  //     setShowEmptyStatus(
  //       brandList.length === 0 && ProductListRef?.current?.productList?.length === 0,
  //     );
  //   }
  // }, [cardList]);

  useEffect(() => {
    if (showEmptyStatus) {
      onShareAppMessageByHome();
    }
  }, [showEmptyStatus]);

  useEffect(() => {
    getPageInfo();
  }, []);

  return (
    <div className={styles.page}>
      <div
        ref={PageScrollRef}
        className={`${styles.page__scroll} ${disableScroll && styles.page__scroll__disable}`}
      >
        <div
          className={styles.scroll__body}
          style={{
            backgroundImage: `url(${pageInfo?.sharePageInfoConfig?.pageBgImg ||
              "https://akim-oss.aikucun.com/mshop/fd7f76e1d0baf36c7582969a707b5ea210aab35a_1715072579626_64.png"
              })`,
          }}
        >
          <TopNavigation
            hideSearchIcon={true}
            hideCartIcon={true}
            hideFavoriteIcon={true}
            backgroundColor={isSticky ? "#fff" : "transparent"}
          />
          {pageInfo.relevanceType && !showEmptyStatus && (
            <>
              {pageInfo.relevanceType === "STORE" && (
                <div className={styles.body__shop}>
                  <ShopList setShowEmptyStatus={setShowEmptyStatus} trackData={pageTrackData} />
                </div>
              )}
              {pageInfo.relevanceType !== "STORE" && (
                <ActivityProductProvider
                  initialSelectedActivityId={selectedActivityId}
                  activityList={brandData?.activityList}
                >
                  <ProductList
                    ref={ProductListRef}
                    onScrollTop={onScrollTop}
                    setDisableScroll={setDisableScroll}
                    brandList={brandList}
                    activeBrandIndex={activeBrandIndex}
                    setActiveBrandIndex={setActiveBrandIndex}
                    brandData={brandData}
                    selectedActivityId={selectedActivityId}
                    setSelectedActivityId={setSelectedActivityId}
                    setShowEmptyStatus={setShowEmptyStatus}
                    handleSetIsSticky={setIsSticky}
                    trackData={pageTrackData}
                  />
                </ActivityProductProvider>
              )}
            </>
          )}
          {showEmptyStatus && <PageEmptyStatus pageInfo={pageInfo} />}
        </div>
      </div>

      {pageInfo.relevanceType && !showEmptyStatus && (
        <div className={styles.page__footer}>{footerDom()}</div>
      )}

      {pageLoading && (
        <div className={`${styles.pageLoading} ${styles.loadingFixed}`}>
          <Image src={loadingPng} width={pxtorem(30)}></Image>
        </div>
      )}

      {/* 海报*/}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="B2R"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
    </div>
  );
};

export default Landing;
