import { CouponModel, LabelInfoModel, ProductDetailModel } from "@akc/biz-api";
import { ActivityMarketingServicesMoreComp } from "@akc/mx-taro-h5-components";
import { Image, Popup } from "antd-mobile";
import React, { ReactNode } from "react";
import { AddressListModel } from "../../model/AddressListModel";
import AddressListComp from "./components/address-list";
import ProductParamtersComp from "./components/product-paramters";
import styles from "./index.module.less";
export enum MarketingServicesType {
  //发货
  delivery = "delivery",
  //激励
  Motivate = "Motivate",
  //服务
  Service = "Service",
  //活动
  Promote = "Promote",
  //新客券
  Discount_NewComer = "Discount_NewComer",
  //优惠券
  Discount = "Discount",
  //商品参数
  Product_Paramters = "Product_Paramters",
  //地址列表
  Address = "Address",
}
type Props = {
  //是否显示
  dialogVisible: boolean;
  //弹窗类型
  type?: MarketingServicesType;
  //商详模型
  product?: ProductDetailModel;
  //选中的地址 id
  addressId?: string;
  //地址列表
  addressList?: AddressListModel[];
  //优惠券列表
  couponList?: CouponModel[];
  //关闭
  onClose?: (type?: MarketingServicesType) => void;
  /** 地址 */
  onAddressItemClick?: (addressId?: string, index?: number) => void;
  /** 活动 */
  onPromoteRightLook?: (label?: LabelInfoModel) => void;
  /** 领券 */
  onReceive?: (coupon: CouponModel) => void;
  /** 去使用 */
  onUse?: (coupon: CouponModel) => void;
  /** 凑单 */
  onTargetAmount?: (coupon: CouponModel) => void;
};

/**
 * 营销标签底部弹窗
 */
const PromotionBottomDialog: React.FC<Props> = (props) => {
  let note: ReactNode = <></>;
  let title = "";
  let labelInfoVOList;
  let showBottomBtn = false;
  switch (props.type) {
    case MarketingServicesType.delivery:
      title = "发货";
      note = (
        <div className={styles.deliveryDesc}>{props.product?.skuExtendInfoVO?.deliveryDesc}</div>
      );
      break;
    case MarketingServicesType.Motivate:
      title = "激励";
      labelInfoVOList = props.product?.labelLocationVOList?.find(
        (item) => item.locationCode === "H5_ProductDetail_Marketing_Motivate",
      )?.labelInfoVOList;
      note = (
        <ActivityMarketingServicesMoreComp
          type={props.type}
          className={styles.card}
          labelInfos={labelInfoVOList}
        />
      );
      break;
    case MarketingServicesType.Service:
      title = "服务";
      labelInfoVOList = props.product?.labelLocationVOList?.find(
        (item) => item.locationCode === "H5_ProductDetail_Marketing_Service",
      )?.labelInfoVOList;
      note = (
        <ActivityMarketingServicesMoreComp
          type={props.type}
          className={styles.card}
          labelInfos={labelInfoVOList}
        />
      );
      break;
    case MarketingServicesType.Promote:
      title = "活动";
      labelInfoVOList = props.product?.labelLocationVOList?.find(
        (item) => item.locationCode === "H5_ProductDetail_Marketing_PromoteSales",
      )?.labelInfoVOList;
      note = (
        <ActivityMarketingServicesMoreComp
          type={props.type}
          className={styles.card}
          labelInfos={labelInfoVOList}
          showSkipBtn={true}
          onGoLook={(label) => {
            props?.onPromoteRightLook?.(label);
          }}
        />
      );
      break;
    case MarketingServicesType.Discount:
    case MarketingServicesType.Discount_NewComer:
      title = props.type === MarketingServicesType.Discount ? "活动优惠" : "领券享优惠";
      note = (
        <ActivityMarketingServicesMoreComp
          type={props.type}
          coupons={props.couponList}
          onReceive={(coupon) => {
            //领券
            props.onReceive?.(coupon);
          }}
          onUse={(coupon) => {
            //去使用
            props.onUse?.(coupon);
          }}
          onTargetAmount={(coupon) => {
            //凑单
            props.onTargetAmount?.(coupon);
          }}
        />
      );
      break;
    case MarketingServicesType.Product_Paramters:
      showBottomBtn = true;
      title = "产品参数";
      note = <ProductParamtersComp product={props.product} />;
      break;
    case MarketingServicesType.Address:
      title = "配送至";
      note = (
        <AddressListComp
          addressList={props.addressList}
          addressId={props.addressId}
          onAddressItemClick={(address, index) => {
            props?.onAddressItemClick?.(address, index);
            props.onClose?.();
          }}
        />
      );
      break;
  }
  return (
    <Popup
      visible={props.dialogVisible}
      onMaskClick={() => {
        props.onClose?.(props.type);
      }}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 3000,
      }}
    >
      <div
        className={`${styles["promotionC"]} ${props.type === MarketingServicesType.Service ? styles["promotionCG"] : styles[""]}`}
      >
        {props.type === MarketingServicesType.Service && <div className={styles.bgImg} />}
        <div className={styles.top}>
          <div className={styles.title}>{title}</div>
          <Image
            className={styles.close}
            src="https://devdevnew.obs.cn-east-3.myhuaweicloud.com/taro/resources/close_black.png"
            onClick={() => {
              props.onClose?.(props.type);
            }}
          />
        </div>
        <div className={`${styles["body"]} ${showBottomBtn ? styles["bodyB"] : styles[""]}`}>
          {note}
        </div>
        {showBottomBtn && (
          <div className={styles.bottomC}>
            <div
              className={styles.confirmBtn}
              onClick={() => {
                props.onClose?.(props.type);
              }}
            >
              完成
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default PromotionBottomDialog;
