import { queryCouponWindow } from "@/api/coupon";
import { bindPhoneNum, getSmsCode } from "@/api/my";
import useCheckBind from "@/pages/new-person/hooks/useCheckBind";
import useNavigation from "@/utils/useNavigate";
import { Mask, Toast } from "antd-mobile";
import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./NewPersonCouponV1.module.less";

type Props = {
  couponInfo: any;
  setVisible?: (T: boolean) => void;
  setShowLoginPop?: (T: boolean) => void;
};

const CouponPop: FC<Props> = ({ couponInfo, setShowLoginPop, setVisible }) => {
  const { isVisitor } = useCheckBind();
  const { spCouponActivities = [] } = couponInfo ?? {};
  const couponSum = spCouponActivities.reduce((prev, { amount = 0 }) => prev + amount, 0);
  const collectCoupon = () => {
    if (isVisitor) {
      setShowLoginPop?.(true);
    } else {
      Toast.show({
        content: "领取成功",
        maskStyle: { zIndex: 1003 },
      });
      setVisible?.(false);
    }
  };
  return (
    <div className={styles.couponPopWrap}>
      <div className={styles.topImg}></div>
      <div className={styles.title}>初见君，赠红包愿结长久情</div>
      <div className={styles.couponCard}>
        <div className={styles.couponPrice}>{couponSum}</div>
        <div className={styles.couponDesc}>全场优惠券</div>
        <div className={styles.extra}>共{spCouponActivities.length}张</div>
      </div>
      <div className={styles.collectBtn} onClick={collectCoupon}></div>
    </div>
  );
};

const LoginPop: FC<Props> = ({ couponInfo, setVisible }) => {
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [smsCode, setSmsCode] = useState<string>("");
  const [smsCodeText, setSmsCodeText] = useState<string>("获取验证码");
  const [leftSeconds, setLeftSeconds] = useState<number>(-1);
  const [checked, setChecked] = useState<boolean>(false);
  const timer = useRef<any>(null);
  const { navigate } = useNavigation();

  const { spCouponActivities = [], hrefUrl } = couponInfo ?? {};
  const couponSum = spCouponActivities.reduce((prev, { amount = 0 }) => prev + amount, 0);

  const checkForm = () => {
    if (!phoneNum || phoneNum.length !== 11) {
      Toast.show({
        content: "请输入有效的手机号",
        maskStyle: { zIndex: 1003 },
      });
      return false;
    }
    if (!smsCode || smsCode.length !== 6) {
      Toast.show({
        content: "请输入有效的验证码",
        maskStyle: { zIndex: 1003 },
      });
      return false;
    }
    if (!checked) {
      Toast.show({
        content: "请确认并勾选协议",
        maskStyle: { zIndex: 1003 },
      });
      return false;
    }
    return true;
  };

  const countDown = () => {
    setLeftSeconds(59);
    timer.current = setInterval(() => {
      setLeftSeconds((cur) => {
        return cur - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (leftSeconds < 0) {
      clearInterval(timer.current);
      setSmsCodeText("获取验证码");
    } else {
      setSmsCodeText(() => `重新获取${leftSeconds}s`);
    }
  }, [leftSeconds]);

  const getCode = async () => {
    if (smsCodeText !== "获取验证码") {
      return;
    }
    if (!phoneNum || phoneNum.length !== 11) {
      Toast.show({
        content: "请输入有效的手机号",
        maskStyle: { zIndex: 1003 },
      });
      return false;
    }
    countDown();
    const res: any = await getSmsCode({ phone: phoneNum });
    if (res.success && (res.code === 200 || res.code === 0)) {
      Toast.show({
        content: "验证码已发送，请查收",
        maskStyle: { zIndex: 1003 },
      });
    }
  };

  const login = async () => {
    // 注册用户
    if (!checkForm()) {
      return;
    }

    let params = {
      phone: phoneNum,
      smsCode: smsCode,
    };
    const res: any = await bindPhoneNum(params);
    const { success, data = {}, message } = res ?? {};
    if (success && data.visitorStatus === 0) {
      const params = {
        shopBizCode: localStorage.getItem("shopId"),
        pageCode: "home_page",
      };
      await queryCouponWindow(params);
      Toast.show({
        content: "领取成功",
        maskStyle: { zIndex: 1003 },
      });
      setTimeout(() => {
        window.location.href = `${hrefUrl}&shopId=${localStorage.getItem("shopId")}`;
      }, 2000);
      setVisible?.(false);
    } else {
      Toast.show({
        content: message,
        maskStyle: { zIndex: 1003 },
      });
      setVisible?.(false);
    }
  };
  const toPolicy = (e) => {
    e.stopPropagation();
    navigate({
      h5Options: {
        url: "/#/legal-protocol/privacyPolicyToC",
      },
    });
  };

  return (
    <div className={styles.loginPopWrap}>
      <div className={styles.topImg}></div>
      <div className={styles.title}>初见君，赠红包愿结长久情</div>
      <div className={styles.couponCard}>
        <div className={styles.couponPrice}>{couponSum}</div>
        <div className={styles.couponDesc}>全场优惠券</div>
        <div className={styles.extra}>共{spCouponActivities.length}张</div>
      </div>
      <div className={styles.form}>
        <div className={styles.inputWrap}>
          <input
            value={phoneNum}
            placeholder="请输入手机号码"
            className={styles.input + " " + styles.phone}
            onChange={(e) => {
              setPhoneNum(e.target.value);
            }}
          />
        </div>
        <div className={styles.inputWrap}>
          <input
            value={smsCode}
            type="tel"
            pattern="/[0-9]*/"
            placeholder="请输入验证码"
            className={styles.input + " " + styles.code}
            onChange={(e) => {
              setSmsCode(e.target.value);
            }}
          />
          <div className={styles.codeBtn} onClick={getCode}>
            {smsCodeText}
          </div>
        </div>
      </div>
      <div className={styles.registerBtn} onClick={login}></div>
      <div
        className={styles.agreement}
        onClick={() => {
          setChecked(!checked);
        }}
      >
        <div className={styles.radio + " " + (checked && styles.checked)}></div>
        <div className={styles.cont}>
          <span>若手机号未注册将进入注册流程，注册即为同意</span>
          <span className={styles.hl} onClick={toPolicy}>
            《隐私政策》
          </span>
        </div>
      </div>
    </div>
  );
};

const NewPersonCouponV1: FC<Props> = ({ couponInfo }) => {
  const [visible, setVisible] = useState(true);
  const [showLoginPop, setShowLoginPop] = useState(false);

  return (
    <Mask visible={visible} style={{ zIndex: 1002 }} onMaskClick={() => setVisible(false)}>
      {showLoginPop ? (
        <LoginPop couponInfo={couponInfo} setVisible={setVisible} />
      ) : (
        <CouponPop
          couponInfo={couponInfo}
          setShowLoginPop={setShowLoginPop}
          setVisible={setVisible}
        />
      )}
    </Mask>
  );
};

export default NewPersonCouponV1;
