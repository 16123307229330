import AKJS from "@akc/akjs";
import { Pressable, PressableTargetType } from "@akc/decorate-ds";
import { Request } from "../api/request";
import { getAppSystem } from "./app-system";
import { MICRO_APP_BASEURL, platform } from "./platform";
import { getBaseurl, getUrlParam, urlQueryObject } from "./url-parse";
import { versionsCompare } from "./version-compare";
// import { unmountApp } from "@micro-zoe/micro-app";
import { routesList } from "@/router";
import { RouteName } from "@/router/extra";
import stores from "@/store/index";
import Track from "@/utils/track";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isNewProductDetail } from "./gray";

const staticUrl = getBaseurl();

const track = Track();

const LinkType = {
  LINK: "链接",
  PRODUCT: "商品",
  ACTIVITY: "档期",
  SHOPMICRO: "搭建页面",
  HOTSALE_OLD: "超级会场",
  CONFERENCE_OLD: "普通会场",
  SHOP: "店铺S码",
  CONFERENCE_3: "会场3.0",
  PAGE: "搭建页面",
  LIVE_ROOM: "直播",
};

export type SearchPageParams = {
  from?: string;
  scene?: string;
  activityId?: string;
  activityType?: 2 | 3 | 4;
};

export interface IOptions {
  path?: string;
  page_name?: RouteName; // 跳转页面的名称 ，如果传了该参数，不用传path,会自动去找routerList里面的name对应的path
  is_micro_app?: boolean;
  is_replace?: boolean; //是否replace
  query?: string;
  extra?: object; // 额外拓展参数，后期可能用做埋点
}

function useNavigation() {
  const wx = window.wx;
  const navigater = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const shopId = searchParams.get("shopId");
  const distributorId = searchParams.get("distributorId");
  const stringifyUrl = (
    url: string,
    params: {
      [key in string]: any;
    },
  ) => {
    if (url) {
      let str = Object.keys(params)
        .filter((key) => params[key] !== undefined)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      const { shopId, distributorId, plt } = urlQueryObject();
      const hasShopIdInUrl = params.shopId || url.includes("?shopId=") || url.includes("&shopId=");
      if (!hasShopIdInUrl) {
        str += (str ? "&" : "") + `shopId=${shopId}`;
      }
      if (!params.distributorId && distributorId) {
        str += `&distributorId=${distributorId}`;
      }
      if (plt) {
        str += `&plt=${plt}`;
      }
      const hyphen = url.includes("?") ? "&" : "?";
      return `${url}${hyphen}${str}`;
    }
    return "";
  };

  // 跳转到指定页面
  type NavigatorOptions = {
    url: string;
    query?: {
      [key in string]: any;
    };
  };

  function navigate(options: {
    appOptions?: NavigatorOptions;
    miniOptions?: NavigatorOptions;
    h5Options?: NavigatorOptions;
    type?: "outer";
    replace?: boolean;
  }) {
    const { isApp, isMini } = platform;
    if (isApp) {
      const { url, query } = options.appOptions || {};
      if (url) {
        const schema =
          url.indexOf("http") >= 0
            ? url
            : url.indexOf("akapp://") >= 0
              ? url
              : url.indexOf("/") >= 0
                ? `akapp://mengxiang.com${url}`
                : `akapp://mengxiang.com/${url}`;
        if (query) {
          query.page_name = encodeURIComponent(document.title);
        }
        AKJS.ready().then(() => {
          AKJS.action.dispatch("event.page.open", { url: schema, param: query }).catch((error) => {
            console.error("跳转失败:", error);
          });
        });
      }
    } else if (isMini) {
      const { url, query = {} } = options.miniOptions || {};
      console.log("输出url------------", url);
      if (url) {
        if (query) {
          query.page_name = encodeURIComponent(document.title);
        }
        let newurl = stringifyUrl(url, query);
        console.log("输出url------------", newurl);
        //小程序跳转会场走navigateTo
        if (
          newurl.includes("/mx-shop-micro/") ||
          newurl.includes("mx-shop-micro?") ||
          newurl.includes("mx-shop-micro/?")
        ) {
          const hyphen = newurl.includes("?") ? "&" : "?";
          if (getUrlParam()?.["isC"]) {
            newurl = `${newurl + hyphen}token=${stores.appStore.token}&isC=${
              getUrlParam()?.["isC"]
            }`;
          } else {
            newurl = `${newurl + hyphen}token=${stores.appStore.token}`;
          }
          window.wx.miniProgram.navigateTo({
            url: `/pages/web-view/web-view?url=${encodeURIComponent(newurl)}`,
          });
          return;
        }
        if (newurl.startsWith("http")) {
          const hyphen = newurl.includes("?") ? "&" : "?";
          console.log("小程序跳转-----", window.location.href);
          if (getUrlParam()?.["isC"]) {
            window.location.href = `${newurl + hyphen}token=${
              stores.appStore.token
            }&isC=${getUrlParam()?.["isC"]}`;
          } else {
            window.location.href = `${newurl + hyphen}token=${stores.appStore.token}`;
          }
          return;
        }
        wx &&
          wx.miniProgram.navigateTo({
            url: newurl,
          });
      }
    } else {
      const { url, query = {} } = options.h5Options || {};
      if (url) {
        if (query) {
          let name = "";
          if (query.promotePageType) {
            const pageNameObj = {
              1: "种草社区_推荐",
              2: "种草详情",
              3: "实拍专区-图文",
              4: "实拍专区-视频",
            };
            name = pageNameObj[query?.promotePageType];
            query.page_name = encodeURIComponent(name);
            query.previous_page_name = encodeURIComponent(name);
          } else {
            name = location.href.indexOf("mx-shop-micro/market/marketCategory")
              ? "店铺列表"
              : location.href.indexOf("mx-shop-micro/market/marketSearch")
                ? "搜索"
                : location.href.indexOf("mx-shop-micro/market")
                  ? "店铺聚合"
                  : "";
            const pageName = encodeURIComponent(document.title || (name ? name : "首页"));
            query.page_name = pageName;
            query.previous_page_name = pageName;
          }
        }
        let newUrl = stringifyUrl(url, query);
        console.log("newUrl", newUrl);
        if (newUrl) {
          let type = options.type;
          if (newUrl.indexOf("/#/productDetail") > -1 && isNewProductDetail()) {
            // 若是进入商详 && 在灰度中，进入新版商详
            type = undefined;
            newUrl = newUrl.replace("/#/productDetail", "/mx-shop-micro/product");
          }
          // 针对站外场景跳转
          if (type === "outer") {
            window.location.href = url;
            return;
          }
          // 微前端跳转 | 针对其他会场跳转
          navigater(newUrl, {
            state: query,
            replace: options.replace,
          });
        }
        // if (url.indexOf("#/brand") > -1) {
        //   const scrollTop = document.getElementById("page")?.scrollTop as any;
        //   sessionStorage.setItem("scrollLoaction", scrollTop);
        //   window.location.href = newUrl;
        // } else {
        //   navigater(newUrl, {
        //     state: query
        //   });
        // }
        // const newurl = stringifyUrl(url, query);

        // window.location.href = newurl;
      }
    }
  }
  // 跳转到档期详情
  const navigate2Activity = (
    query: {
      activityNo: string;
      marketName?: string;
      promotePageType?: any; //种草/实拍专区跳转pagename
    },
    clickEvent?: any,
  ) => {
    /* eslint-disable */
    if (track.track && clickEvent) {
      if (clickEvent.btnInfo) {
        track.track("btn_click", {
          page_name: document.title,
          btn_name: clickEvent.btnInfo.btn_name,
          module: clickEvent.btnInfo.module,
          btn_text: clickEvent.btnInfo.btn_text,
          ...clickEvent,
        });
      } else {
        track.track("resource_click", {
          page_name: document.title,
          resource_type: clickEvent.type,
          resource_content: "档期",
          resource_name: "档期",
          resource_id: clickEvent?.targetId,
          resource_rank: clickEvent?.resourceRank,
          relative_rank: clickEvent?.relativeRank,
          has_picture: true,
          has_specially: clickEvent?.hasSpecially,
          activity_spu_code: clickEvent?.targetId,
          activity_id: query.activityNo,
          market_id: getUrlParam()?.["customPageCode"],
          market_name: document.title,
          ...clickEvent,
        });
      }
    }
    /* eslint-disable */
    setTimeout(() => {
      navigate({
        appOptions: {
          url: "activity",
          query: {
            id: query.activityNo,
            marketName: query.marketName,
          },
        },
        miniOptions: {
          url: "/acts/pages/activity-detail/activity-detail",
          query: {
            id: query.activityNo,
          },
        },
        h5Options: {
          url: `/mx-shop-micro/activity/${query.activityNo}`,
          query: {
            activityNo: query.activityNo,
            fromPage: query.marketName,
          },
        },
      });
    }, 100);
  };

  const navigate2LiveRoom = (props: { liveId: string; liveStatus?: number }) => {
    navigate({
      appOptions: {
        url: "liveRoom",
        query: {
          id: props.liveId,
        },
      },
      miniOptions: {
        url: `/liveGoods/livePlaying/pages/live-playing/live-playing?liveNo=${props.liveId}`,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/living?channel=h5Mall&liveNo=${props.liveId}`,
      },
    });
  };

  const navigate2HiComi = (query) => {
    // unmountApp("h5shop", {
    //   destroy: true,
    // });
    navigate({
      miniOptions: {
        url: `${origin}/mx-shop-micro/decorate/profit`,
        query: {
          merchantShopCode: query.merchantShopCode,
        },
      },
      h5Options: {
        url: `/mx-shop-micro/decorate/profit`,
        query: {
          merchantShopCode: query.merchantShopCode,
        },
      },
    });
  };
  // 跳转到首页
  const navigate2Home = () => {
    const { isApp, isMini, isWxH5, isH5 } = platform;
    if (isApp) {
      navigate({
        appOptions: {
          url: "home",
        },
      });
    } else if (isWxH5 || isH5) {
      const options: IOptions = {
        path: "/mx-shop-micro/home",
        is_micro_app: true,
      };
      navigate2H5(options);
    } else if (isMini) {
      window?.wx?.miniProgram?.reLaunch({
        url: "/pages/home/home",
      });
    }
  };
  // 跳转到商品详情
  const navigate2Product = async (
    query: (
      | { activitySpuId: string; productId?: never }
      | { activitySpuId?: never; productId: string }
    ) & {
      activityId?: string;
      firstSalePropertyValue?: string;
      selectSkuId?: string;
      marketName?: string;
      promotePageType?: any; //种草实拍专区pagename
    },
    clickEvent?: any,
  ) => {
    /* eslint-disable */
    if (track.track && clickEvent) {
      if (clickEvent.btnInfo) {
        track.track("btn_click", {
          page_name: document.title,
          btn_name: clickEvent.btnInfo.btn_name,
          module: clickEvent.btnInfo.module,
          btn_text: clickEvent.btnInfo.btn_text,
          ...clickEvent,
        });
      } else {
        track.track("resource_click", {
          page_name: document.title,
          resource_type: clickEvent.type,
          resource_content: "商品",
          resource_name: "商品",
          resource_id: clickEvent?.targetId,
          resource_rank: clickEvent?.resourceRank,
          relative_rank: clickEvent?.relativeRank,
          has_picture: true,
          has_specially: clickEvent?.hasSpecially,
          activity_spu_code: clickEvent?.targetId,
          market_id: getUrlParam()?.["customPageCode"],
          market_name: document.title,
          ...clickEvent,
        });
      }
    }
    /* eslint-enable */
    const { isApp } = platform;
    if (isApp) {
      const appVersion = (await getAppSystem())?.appVersion;
      if (appVersion && versionsCompare(appVersion, "7.0.0") < 0) {
        // 老版本，进入档期详情并定位到商品
        const response = await Request({
          url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/product/convertProductId`,
          method: "post",
          data: {
            activitySpuIds: [query.activitySpuId],
            convertType: 2,
          },
        });
        if (response?.data && (response?.data?.length ?? 0) > 0) {
          const skcId = response?.data[0]?.skcId;
          if (!skcId) {
            console.log(`未查到对应的skcid. activitySpuId: ${query.activitySpuId}`);
            return;
          }
          navigate({
            appOptions: {
              url: "activity",
              query: {
                id: query.activityId,
                productId: skcId,
              },
            },
          });
        }
        return;
      }
    }
    setTimeout(() => {
      navigate({
        appOptions: {
          url: "productDetail",
          query: {
            activityId: query.activityId,
            productId: query.activitySpuId,
            selectSkuId: query.firstSalePropertyValue ? undefined : query.selectSkuId,
            firstSalePropertyValue: query.firstSalePropertyValue,
          },
        },
        miniOptions: {
          url: "/acts/pages/product-detail/product-detail?activitySpuId",
          query: {
            activityId: query.activityId,
            activitySpuId: query.activitySpuId,
            selectSkuId: query.firstSalePropertyValue ? undefined : query.selectSkuId,
            firstSalePropertyValue: query.firstSalePropertyValue,
            token: token,
          },
        },
        // TODO 待接入商祥灰度
        h5Options: {
          url: `${MICRO_APP_BASEURL}/#/productDetail`,
          query: {
            activityId: query.activityId,
            activitySpuId: query.activitySpuId,
            selectSkuId: query.firstSalePropertyValue ? undefined : query.selectSkuId,
            firstSalePropertyValue: query.firstSalePropertyValue,
            promotePageType: query.promotePageType, //种草实拍专区pagename
          },
        },
      });
    }, 100);
  };

  // 跳转到小程序积分券详情
  const navigate2SaasCoupon = async (query: { awdId: string; promoActivityId: string }) => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        btn_name: "去兑换",
        module: "积分优惠券",
        btn_text: "去兑换",
      });
    }
    /* eslint-enable */
    setTimeout(() => {
      navigate({
        miniOptions: {
          url: "/jifen/pages/exchange/exchange",
          query: {
            awdId: query.awdId,
            promoActivityId: query.promoActivityId,
          },
        },
      });
    }, 100);
  };

  //跳购物车
  const navigate2Cart = async () => {
    setTimeout(() => {
      navigate({
        h5Options: {
          url: `${MICRO_APP_BASEURL}/#/cart`,
        },
      });
    }, 100);
  };

  // 跳转到优惠券档期凑单页
  function navigate2OfferOrderList(query: any) {
    navigate({
      miniOptions: {
        url: "/acts/pages/offer-order/offer-order",
        query: query.miniQuery,
      },
      h5Options: {
        // url: `${MICRO_APP_BASEURL}/#/offer-order`,
        url: "/mx-shop-micro/joint-bill",
        query: query.h5Query,
      },
    });
  }

  // 跳转到优惠券商品凑单页
  function navigate2GoodsOfNpieceXfold(query: any) {
    navigate({
      miniOptions: {
        url: "/acts/pages/offer-order/offer-order",
        query: query.miniQuery,
      },
      h5Options: {
        // url: `${MICRO_APP_BASEURL}/#/goodsOfNpieceXfold`,
        url: "/mx-shop-micro/joint-bill",
        query: query.h5Query,
      },
    });
  }

  // 跳转到普通会场详情
  function navigate2Venue(query: {
    miniQuery?: {
      id: string;
    };
    h5Query?: {
      conferenceId: string;
    };
  }): void {
    navigate({
      appOptions: {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/pages/commonActivity.html#/index?conferenceId=${query.h5Query?.conferenceId}`,
      },
      miniOptions: {
        url: "/acts/pages/conference/conference",
        query: query.miniQuery,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/commonActivity?conferenceId=${query.h5Query?.conferenceId}`,
      },
    });
  }

  // 跳转到超品会场详情
  function navigate2SuperVenue(query: {
    miniQuery?: {
      pageName?: string;
      shareOnlyId: string;
    };
    h5Query?: {
      hotSaleId: string;
    };
  }) {
    navigate({
      appOptions: {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/pages/superBrand.html#/index?hotSaleId=${query.h5Query?.hotSaleId}`,
      },
      miniOptions: {
        url: "/pages/transfer/transfer",
        query: query.miniQuery,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/superbrand?hotSaleId=${query.h5Query?.hotSaleId}`,
      },
    });
  }

  //跳转到小程序搜索页
  function navigate2Search(query: {
    miniQuery?: {
      merchantShopCode?: any;
      shopId?: any;
      scene?: any;
    };
    h5Query?: {
      merchantShopCode: any;
      shopId?: any;
      scene?: any;
    };
  }) {
    navigate({
      miniOptions: {
        url: "/acts/pages/mix-search/mix-search",
        query: query.miniQuery,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/sinSearch?merchantShopCode=${query.h5Query?.merchantShopCode}&scene=${query.h5Query?.scene}`,
      },
    });
  }

  //跳转到店铺页面
  function navigate2Shop(query: { shopCode: any }) {
    navigate({
      miniOptions: {
        url: `${location.origin}/mx-shop-micro/shop/info`,
        query: query,
      },
      h5Options: {
        url: "/mx-shop-micro/shop/info",
        query: query,
      },
    });
  }

  //跳转到店铺页面
  function navigate2Store(query) {
    navigate({
      appOptions: {
        url: "shopDetail",
        query,
      },
      miniOptions: {
        url: "/shop/pages/market/index",
        query,
      },
      h5Options: {
        url: "/mx-shop-micro/decorate",
        query,
      },
    });
  }

  //跳转到分类下级页面
  function navigate2Category(query: {
    miniQuery?: {
      shopId: any;
      shopCode: any;
      categoryId: any;
      categoryName: any;
      threeClassId: any;
    };
    h5Query?: {
      shopId: any;
      shopCode: any;
      categoryId: any;
      categoryName: any;
      threeClassId: any;
    };
  }) {
    navigate({
      miniOptions: {
        url: "/acts/pages/category-detail/category-detail",
        query: query.miniQuery,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/categoryDetail?shopCode=${query.h5Query?.shopCode}&channel=h5Mall&categoryId=${query.h5Query?.categoryId}&categoryName=${query.h5Query?.categoryName}&threeClassId=${query.h5Query?.threeClassId}&refresh=true`,
      },
    });
  }

  // 跳转到确认订单页面
  function navigate2OrderConfrim(query: {
    miniQuery?: {
      skuNo: any;
      quantity: any;
      virtualRechargeType: any;
      activityNo: any;
      spuId;
    };
    h5Query?: {
      skuNo: any;
      num: any;
      directcChargeType: any;
      activityNo: any;
      spuId: any;
      liveRoomNo?: any;
      isBusinessVenue?: any;
    };
  }) {
    navigate({
      miniOptions: {
        url: "/score/pages/order-confirm/order-confirm",
        query: query.miniQuery,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/confirmationOrder?skuNo=${query.h5Query?.skuNo}&num=${query.h5Query?.num}&directcChargeType=${query.h5Query?.directcChargeType}&activityNo=${query.h5Query?.activityNo}&spuId=${query.h5Query?.spuId}&liveRoomNo=${query.h5Query?.liveRoomNo}&isBusinessVenue=${query.h5Query?.isBusinessVenue}&channel=h5Mall`,
      },
    });
  }

  /**
   * 点击 Pressable 动作
   */
  function preasableAction(
    item: Pressable,
    pageName?: string,
    type?: string,
    resourceRank?: string,
    relativeRank?: string,
    trackInfo?: any,
  ) {
    /* eslint-disable */
    if (track.track) {
      track.track("resource_click", {
        page_name: document.title,
        resource_type: type,
        resource_content: item?.targetType ? LinkType[item?.targetType] : item?.targetType,
        resource_name: "",
        resource_id: item?.targetId,
        resource_rank: resourceRank,
        relative_rank: relativeRank,
        has_picture: true,
        has_specially: false,
        market_id: getUrlParam()?.["customPageCode"],
        market_name: document.title,
        ...trackInfo,
      });
    }
    /* eslint-enable */
    if (!item?.targetId) {
      return;
    }
    const origin = location.origin;
    console.log("preasableAction——", item);
    console.log("%cPressableTargetType", "color: green;", JSON.stringify(PressableTargetType));
    switch (item.targetType) {
      case PressableTargetType.link: {
        // // 链接
        // const options = {
        //   url: item.targetId,
        // };
        // navigate({
        //   appOptions: options,
        //   miniOptions: options,
        //   h5Options: options,
        //   type: "outer",
        // });
        const options = {
          url: `${item.targetId.split("?")[0]}`,
          query: getUrlParam(null, item.targetId || "") || {},
        };
        console.log("小程序端跳转参数——", options);
        const isOuterLink = !item.targetId.includes("/mx-shop-micro");
        let path = "";
        if (!isOuterLink) {
          const start = item.targetId.indexOf("/mx-shop-micro");
          const end =
            item.targetId.indexOf("?") === -1 ? item.targetId.length : item.targetId.indexOf("?");
          path = item.targetId.substring(start, end);
        }
        console.log("h5端跳转参数——", {
          isOuterLink,
          path,
          query: options.query,
        });
        navigate({
          appOptions: {
            url: item.targetId,
          },
          miniOptions: options,
          h5Options: {
            url: isOuterLink ? item.targetId : path,
            query: options.query,
          },
          type: isOuterLink ? "outer" : undefined,
        });
        break;
      }
      case PressableTargetType.product: {
        // 商品
        if (!item.activityId) {
          return;
        }
        navigate2Product({
          activityId: item.activityId,
          activitySpuId: item.targetId,
          marketName: pageName,
        });
        break;
      }
      case PressableTargetType.activity: {
        // 档期
        navigate2Activity({
          activityNo: item.targetId,
          marketName: pageName,
        });
        break;
      }
      case "CONFERENCE_3" as PressableTargetType:
      case PressableTargetType.page:
      case PressableTargetType.shopMicro: {
        // 搭建页面
        const url = `${location.href.split("?")[0]}`;
        const options = {
          url,
          query: {
            customPageCode: item.targetId,
            merchantShopCode: getUrlParam()?.["merchantShopCode"],
          },
        };
        if (location.href.indexOf("/decorate") >= 0) {
          navigate({
            appOptions: {
              url: `${url}?customPageCode=${item.targetId}`,
            },
            miniOptions: options,
            h5Options: {
              url: `/mx-shop-micro?customPageCode=${
                item.targetId
              }&merchantShopCode=${getUrlParam()?.["merchantShopCode"]}`,
            },
          });
        } else {
          navigate({
            appOptions: {
              url: `${url}?customPageCode=${item.targetId}`,
            },
            miniOptions: options,
            h5Options: {
              url: `/mx-shop-micro?customPageCode=${item.targetId}`,
            },
          });
        }

        sessionStorage.setItem("conference3_share_flag", "true");
        break;
      }
      case PressableTargetType.saasShopMicro: {
        navigate({
          miniOptions: {
            url: "/pages/home-saas-sec/preview",
            query: { customPageCode: item.targetId },
          },
        });
        break;
      }
      case PressableTargetType.hotSaleOld: {
        // 超品会场-老
        navigate2SuperVenue({
          miniQuery: { pageName: "hotSale", shareOnlyId: item.targetId },
          h5Query: { hotSaleId: item.targetId },
        });
        break;
      }
      case PressableTargetType.conferenceOld: {
        // 普通会场-老
        navigate2Venue({
          miniQuery: { id: item.targetId },
          h5Query: { conferenceId: item.targetId },
        });
        break;
      }
      case PressableTargetType.live: {
        // 直播间
        navigate2LiveRoom({ liveId: item.targetId });
        break;
      }
      case PressableTargetType.shop: {
        // 店铺页面
        navigate({
          appOptions: {
            url: "shopDetail",
            query: {
              shopCode: item.targetId,
            },
          },
          miniOptions: {
            url: `${origin}/mx-shop-micro/decorate`,
            query: {
              merchantShopCode: item.targetId,
            },
          },
          h5Options: {
            url: `/mx-shop-micro/decorate?merchantShopCode=${item.targetId}`,
          },
        });
        break;
      }
      case PressableTargetType.seckillList: {
        // 入秒杀二级页
        const distributorId = getUrlParam()?.["distributorId"];
        // unmountApp("h5shop", {
        //   destroy: true,
        // });
        const url = location.href.split("mx-shop-micro");
        let query = `mx-shop-micro/decorate/seckill?merchantShopCode=${
          getUrlParam()?.["merchantShopCode"]
        }&decorate=1&activeIndex=1&shopId=${getUrlParam()?.["shopId"]}&isC=${
          getUrlParam()?.["isC"]
        }&previous_page_name=店铺首页&token=${getUrlParam()?.["token"]}`;
        if (getUrlParam()?.["preview"] === true || getUrlParam()?.["preview"] === "true") {
          query += "&preview=true";
        }
        if (trackInfo?.page_code) {
          query += `&customPageCode=${trackInfo?.page_code}`;
        }
        if (trackInfo?.page_version) {
          query += `&customPageVersion=${trackInfo?.page_version}`;
        }
        if (distributorId) {
          if (!platform.isMini) {
            navigater(`/${query}&distributorId=${distributorId}`);
          } else {
            const newUrl = `${url[0]}${query}&distributorId=${distributorId}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        } else {
          if (!platform.isMini) {
            navigater(`/${query}`);
          } else {
            const newUrl = `${url[0]}${query}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        }
        break;
      }
      case PressableTargetType.profitAdditionalList: {
        // 进入高佣二级页
        const distributorId = getUrlParam()?.["distributorId"];
        // unmountApp("h5shop", {
        //   destroy: true,
        // });
        const url = location.href.split("mx-shop-micro");
        let query = `mx-shop-micro/decorate/profit?merchantShopCode=${
          getUrlParam()?.["merchantShopCode"]
        }&type=image&shopId=${getUrlParam()?.["shopId"]}&isC=${
          getUrlParam()?.["isC"]
        }&previous_page_name=店铺首页&token=${getUrlParam()?.["token"]}`;
        if (getUrlParam()?.["preview"] === true || getUrlParam()?.["preview"] === "true") {
          query += "&preview=true";
        }
        if (distributorId) {
          if (!platform.isMini) {
            navigater(`/${query}&distributorId=${distributorId}`);
          } else {
            const newUrl = `${url[0]}${query}&distributorId=${distributorId}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        } else {
          if (!platform.isMini) {
            navigater(`/${query}`);
          } else {
            const newUrl = `${url[0]}${query}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        }
        break;
      }
      case PressableTargetType.toplist: {
        // 进入榜单二级页
        const distributorId = getUrlParam()?.["distributorId"];
        // unmountApp("h5shop", {
        //   destroy: true,
        // });
        const url = location.href.split("mx-shop-micro");
        let query = `mx-shop-micro/decorate/toplist?merchantShopCode=${
          getUrlParam()?.["merchantShopCode"]
        }&customPageCode=${getUrlParam()?.["customPageCode"]}&shopId=${
          getUrlParam()?.["shopId"]
        }&isC=${getUrlParam()?.["isC"]}&previous_page_name=店铺首页&token=${
          getUrlParam()?.["token"]
        }`;
        if (getUrlParam()?.["preview"] === true || getUrlParam()?.["preview"] === "true") {
          query += "&preview=true";
        }
        if (distributorId) {
          if (!platform.isMini) {
            navigater(`/${query}&distributorId=${distributorId}`);
          } else {
            const newUrl = `${url[0]}${query}&distributorId=${distributorId}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        } else {
          if (!platform.isMini) {
            navigater(`/${query}`);
          } else {
            const newUrl = `${url[0]}${query}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        }
        break;
      }
      case PressableTargetType.couponList: {
        // 进入优惠券二级页
        const distributorId = getUrlParam()?.["distributorId"];
        const customPageCode = getUrlParam()?.["customPageCode"];
        // unmountApp("h5shop", {
        //   destroy: true,
        // });
        const url = location.href.split("mx-shop-micro");
        let query = `mx-shop-micro/decorate/coupon?merchantShopCode=${
          getUrlParam()?.["merchantShopCode"]
        }&shopId=${
          getUrlParam()?.["shopId"]
        }&customPageCode=${customPageCode}&previous_page_name=店铺首页&token=${
          getUrlParam()?.["token"]
        }`;
        if (getUrlParam()?.["preview"] === true || getUrlParam()?.["preview"] === "true") {
          query += "&preview=true";
        }
        if (distributorId) {
          if (!platform.isMini) {
            navigater(`/${query}&distributorId=${distributorId}`);
          } else {
            const newUrl = `${url[0]}${query}&distributorId=${distributorId}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        } else {
          if (!platform.isMini) {
            navigater(`/${query}`);
          } else {
            const newUrl = `${url[0]}${query}`;
            window.wx.miniProgram.navigateTo({
              url: `/pages/web-view/web-view?url=${encodeURIComponent(newUrl)}`,
            });
          }
        }
        break;
      }
      case PressableTargetType.mini: {
        const path = item.targetId || {};
        if (path) {
          window.wx.miniProgram.navigateTo({
            url: `/${path}`,
          });
        }
        // const APPID = window.wx.miniProgram.getAccountInfoSync().miniProgram.appId;
        // const path = item.targetId || {};
        // const appId = item.miniAppId;
        // if (APPID === item.miniAppId) {
        //   if (path) {
        //     window.wx.miniProgram.navigateTo({
        //       url: `/${path}`,
        //     });
        //   }
        // } else {
        //   window.wx.miniProgram.navigateToMiniProgram({
        //     appId,
        //     path,
        //     extraData: {
        //       currentSellerId:
        //         window.wx.miniProgram.getStorageSync("userInfo") &&
        //         window.wx.miniProgram.getStorageSync("userInfo").currentSellerId,
        //     },
        //   });
        // }
        break;
      }
    }
  }

  const commonQueries = (props?: { url?: string; query?: { [key in string]: any } }) => {
    const urlParams = (() => {
      if (props?.url) {
        return new URL(props.url).searchParams;
      }
    })();
    let searchParams;
    if (props?.url) {
      searchParams = new URL(props.url).search;
    }

    const { isMini } = platform;
    const { shopId, distributorId } = urlQueryObject();
    const queries = {
      ...urlParams,
      ...props?.query,
      shopId,
      channel: "h5Mall",
      previous_page_name: encodeURIComponent(document.title) || "",
      distributorId,
      ...(isMini ? { token: token } : {}),
    };
    let res = Object.keys(queries)
      .map((key) => queries[key] && key + "=" + queries[key])
      .filter((i) => i)
      .join("&");
    if (searchParams) {
      res += searchParams.replace("?", "&");
    }
    return res;
  };

  // 此方法为新的跳具体的h5页面的方法。会自动加上 shopId、distributorId和previous_page_name
  // 如果入参是name,会去找routerList里面的name对应的path 如果是path,则直接跳转
  function navigate2H5(props: IOptions | number) {
    if (typeof props === "number") {
      navigater(props);
      return;
    }
    const { path, is_micro_app, is_replace = false, query = "", page_name } = props;

    let link = "";
    if (page_name) {
      link = routesList.find((item) => item.name === page_name)?.path || "";
    } else {
      link = `${path}`;
    }
    // 区分是否是微应用
    if (is_micro_app) {
      link = `${MICRO_APP_BASEURL}${path}`;
    }
    // 拼接公共参数
    if (link.includes("?")) {
      link = link.concat(`&${commonQueries()}`);
    } else {
      link = link.concat(`?${commonQueries()}`);
    }

    // 拼接自定义参数
    if (query !== "") {
      link = link.concat("&", query);
    }
    navigater(link, { replace: is_replace });
  }

  /**
   * 跳转资源位H5链接
   */
  const navigate2ResourceH5 = (urlString: string, query?: { [key in string]: any }) => {
    const u = stringifyUrl(urlString, query ?? {});
    const url = new URL(u);
    if (url.hostname !== location.hostname) {
      location.href = u;
      return;
    }
    let link = url.pathname + url.hash;
    link = link.concat(
      link.indexOf("?") > -1 ? "&" : "?",
      commonQueries({ url: url.toString(), query }),
    );
    navigater(link);
  };

  // 解析h5链接并跳转
  const navigateByH5Link = (h5Link: string) => {
    if (!h5Link) {
      return;
    }
    const shopId = getUrlParam()?.["shopId"];
    const distributorId = getUrlParam()?.["distributorId"];
    const prePageName = encodeURIComponent(document.title) || "";
    let publicParams = h5Link.includes("?") ? "&" : "?";
    publicParams += `shopId=${shopId}&previous_page_name=${prePageName}&channel=h5Mall`;
    if (distributorId) {
      publicParams += `&distributorId=${distributorId}`;
    }
    const { pathname, search } = new URL(h5Link);
    if (pathname.startsWith("/mx-shop-micro")) {
      // console.log(33333, publicParams, `${pathname}${search}${publicParams}`);
      navigater(`${pathname}${search}${publicParams}`);
    } else if (h5Link.startsWith("http")) {
      window.location.href = h5Link + publicParams;
    } else {
      navigater(h5Link + publicParams);
    }
  };

  // 跳转到优惠券列表页
  const navigate2CouponList = () => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/coupon`,
      },
    });
  };

  // 跳转到素材内容
  const navigate2MaterialContent = (type, materialNo) => {
    const url =
      type === 1
        ? `${MICRO_APP_BASEURL}/#/circleMaterial?materialNo=${materialNo}&isCheckMaterialType=1&isKeepVisualAngle=1`
        : `${MICRO_APP_BASEURL}/#/materialInfo?materialNo=${materialNo}&isCheckMaterialType=1&isKeepVisualAngle=1`;
    navigate({
      h5Options: {
        url: url,
      },
    });
  };

  // 跳转到素材列表
  const navigate2MaterialList = (
    merchantShopCode?: string,
    resourceCode?: string,
    activityIds?: string[],
  ) => {
    const params: string[] = [];
    if (merchantShopCode) {
      params.push(`merchantShopCode=${merchantShopCode}`);
    }
    if (resourceCode) {
      params.push(`resourceCode=${resourceCode}`);
    }
    if (activityIds) {
      params.push(`activityNo=${activityIds.join(",")}`);
      params.push(`previous_page_name=${encodeURIComponent(document.title)}`);
    }
    let url = `/mx-shop-micro/material?${params.join("&")}`;
    navigate({
      h5Options: {
        url: url,
      },
    });
  };
  // 跳转到品牌素材列表页
  const navigate2BrandMaterialList = (brandId: string) => {
    const url = `/mx-shop-micro/brand-material?brandId=${brandId}&previous_page_name=${encodeURIComponent(document.title)}`;
    navigate({
      h5Options: {
        url: url,
      },
    });
  };

  //更多素材跳素材
  const navigate2Material = (type, materialNo, activitySpuId) => {
    const url =
      type === 1
        ? `${MICRO_APP_BASEURL}/#/circleMaterial?materialNo=${materialNo}&isCheckMaterialType=0&isKeepVisualAngle=1&activitySpuId=${activitySpuId}`
        : `${MICRO_APP_BASEURL}/#/materialInfo?materialNo=${materialNo}&isCheckMaterialType=0&isKeepVisualAngle=1&activitySpuId=${activitySpuId}`;
    const miniUrl =
      type === 1
        ? "/material/pages/circle-material/circle-material"
        : "/material/pages/material-info/material-info";
    navigate({
      h5Options: {
        url: url,
      },
      miniOptions: {
        url: miniUrl,
        query: {
          isKeepVisualAngle: 0,
          materialNo,
          activitySpuId,
        },
      },
      appOptions: {
        url: type === 1 ? "circleDetail" : "materialDetail",
        query: {
          materialId: materialNo,
          circleId: materialNo,
          isFromMaterialUser: true,
          mAuditStatus: "2",
        },
      },
    });
  };

  // 跳转到品牌详情
  const navigate2BrandDetail = (brandId: string) => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/brandDetail?brandId=${brandId}`,
      },
    });
  };

  // 跳转到品牌故事
  const navigate2BrandStory = (activityId: string) => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/brandStory?activityId=${activityId}`,
      },
    });
  };

  // 跳转到C品牌故事
  const navigate2CBrandStory = (activityId: string) => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/officialMaterial?channel=h5Mall&activityNo=${activityId}&materialChannel=1&shopBizCode=${
          getUrlParam()?.["shopId"]
        }`,
      },
    });
  };

  // 跳转到搜索页面
  const navigate2SearchPage = (params?: SearchPageParams) => {
    let sParams = params
      ? Object.getOwnPropertyNames(params)
          .filter((key) => params[key] !== undefined)
          .map((key) => `${key}=${params[key]}`)
          .join("&")
      : "";
    if (sParams) {
      sParams = `?${sParams}`;
    }

    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/sinSearch${sParams}`,
      },
    });
  };

  // 跳转营业执照页面
  const navigate2BusinessLicense = (activityId: string) => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/businessLicense?externalActivityCode=${activityId}&shopId=${
          getUrlParam()?.["shopId"]
        }`,
      },
    });
  };

  // 跳转营销NX商品凑单页面
  const navigate2NxProductList = (promoActivityIds: string) => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/goodsOfNpieceXfold?marketingId=${promoActivityIds}&shopId=${
          getUrlParam()?.["shopId"]
        }`,
      },
    });
  };

  // 跳转直播页面
  const navigate2Live = (liveNo: string, liveStatus?: number) => {
    const url = `${MICRO_APP_BASEURL}/#/${
      liveStatus === 10 ? "live-preheat" : liveStatus === 20 ? "living" : "live-end"
    }?liveNo=${liveNo}`;
    navigate({
      h5Options: {
        url: url,
      },
    });
  };

  // 跳转新人券落地页（旧）
  const navigate2NewPersonPage = (configId: string) => {
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/newPersonPage?configId=${configId}&shopId=${
          getUrlParam()?.["shopId"]
        }`,
      },
    });
  };

  // 我的页面item跳转
  const navigate2MyItem = (itemPath: string, itemQuery?: string) => {
    const shopId = getUrlParam()?.["shopId"] ?? "";
    const distributorId = getUrlParam()?.["distributorId"] ?? "";
    const pathQuery = `shopId=${shopId}${distributorId ? "&" + distributorId : ""}`;
    navigate({
      h5Options: {
        url: `${MICRO_APP_BASEURL}${itemPath}?${pathQuery}${itemQuery ? "&" + itemQuery : ""}`,
      },
    });
  };

  // 跳地址列表
  const navigate2AddressList = (query) => {
    setTimeout(() => {
      navigate({
        h5Options: {
          url: `${MICRO_APP_BASEURL}/#/addressList?shopId=${getUrlParam()?.["shopId"]}&actId=${
            getUrlParam()?.["actId"] || getUrlParam()?.["contentId"]
          }&addressOnlyId=${query?.addressOnlyId || getUrlParam()?.["addressOnlyId"]}&fromPage=${
            query.fromPage
          }`,
        },
        miniOptions: {
          url: "/mePkg/pages/address/address",
          query,
        },
      });
    }, 100);
  };

  // 跳今日必卖
  const navigate2SaleCalendar = (query) => {
    setTimeout(() => {
      navigate({
        miniOptions: {
          url: "/acts/pages/sale-calendar/sale-calendar",
          query,
        },
      });
    }, 100);
  };
  // 跳转订单详情
  const navigate2OrderDetail = (query) => {
    const pathQuery = `${location.href.split("?")[1]}`;
    navigate({
      appOptions: {
        url: `${process.env.REACT_APP_PAGE_URL_4_APP}/v2/order/detail?orderNo=${query.orderNo}&nh=1&${pathQuery}`,
      },
      h5Options: {
        url: `${MICRO_APP_BASEURL}/#/order/detail`,
        query: {
          orderNo: query.orderNo,
        },
      },
      miniOptions: {
        url: "/order/pages/order-detail-new/order-detail-new",
        query: {
          orderNo: query.orderNo,
        },
      },
    });
  };

  return {
    navigate2Home,
    navigate2Product,
    navigate2Activity,
    navigate2LiveRoom,
    navigate2OfferOrderList,
    navigate2GoodsOfNpieceXfold,
    navigate2Search,
    navigate2Category,
    navigate2Shop,
    navigate2Store,
    preasableAction,
    navigate2OrderConfrim,
    navigate2HiComi,
    navigate2SuperVenue,
    navigate2Venue,
    navigate,
    navigate2Cart,
    navigate2SaasCoupon,
    navigate2CouponList,
    navigate2H5,
    navigate2ResourceH5,
    navigateByH5Link,
    navigate2BrandDetail,
    navigate2BrandStory,
    navigate2MaterialContent,
    navigate2MaterialList,
    navigate2BrandMaterialList,
    navigate2CBrandStory,
    navigate2SearchPage,
    navigate2BusinessLicense,
    navigate2NxProductList,
    navigate2Material,
    navigate2Live,
    navigate2NewPersonPage,
    navigate2AddressList,
    navigate2SaleCalendar,
    navigate2MyItem,
    navigate2OrderDetail,
  };
}

export default useNavigation;
