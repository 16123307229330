import { getBrandMetarialList } from "@/api/material";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import TopBar from "@/components/top-bar";
import Empty from "@/pages/imageSearch/components/Empty";
import { onShareAppMessageByBrandMaterial, sharePosterParams4H5Material } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import useNavigation from "@/utils/useNavigate";
import { MaterialListCardComp, TrackDataContext } from "@akc/mx-taro-h5-components";
import { InfiniteScroll, List } from "antd-mobile";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SideBar from "../../components/side/index";
import styles from "./index.module.less";


const loadingPng =
  "https://akim-oss.aikucun.com/586c047ad6fdba65ce060e7f8b37a399d2fd1d9b_1729047640191_29.png";

const BrandMaterial = () => {
  const { track } = useCommonContext();
  const [searchParams] = useSearchParams();
  const { navigate2Material } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [materialList, setMaterialList] = useState([]);
  const [showPoster, setShowPoster] = useState(false);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [pagesConfig, setPagesConfig] = useState({
    pageNo: 1,
    pageSize: 10,
    sortModel: 32,
    pageId: "H5_MaterialList",
    voConfig: {
      queryCollectionFlag: true
    }
  });
  const brandId = searchParams.get("brandId");
  const previous_page_name = useMemo(() => {
    const temp = searchParams.get("previous_page_name");
    return temp ? decodeURIComponent(temp) : "";
  }, [searchParams]);
  const frontCodes = useRef([{
    type: 2,
    code: brandId
  }, { type: 7, code: "7" }]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { trackData: contextTrackData } = useContext(TrackDataContext);

  const getMaterialList = () => {
    const params = {
      ...pagesConfig,
      frontCodes: frontCodes.current
    };

    return getBrandMetarialList(params).then(({ data }) => {
      const list = params.pageNo === 1 ? data.result : materialList.concat(data.result);

      setMaterialList(list);
      setHasMore(data.hasNext);
      setPagesConfig({
        ...pagesConfig,
        pageNo: params.pageNo + 1
      });
    });
  };
  const loadMore = async () => {
    if (hasMore && !loading) {
      setLoading(true);
      getMaterialList();
    }
  };
  const getServerTime = () => {
    return new Date().getTime();
  };
  const onClosePoster = () => {
    setShowPoster(false);
  };
  const onForwardAction = async (item) => {
    setSharePosterData(
      await sharePosterParams4H5Material({
        materialId: item.materialNo,
        activitySpuId: frontCodes.current[0].code || "",
      }),
    );
    setCustomEditData({
      activitySpuId: item.activitySpuId,
      activityId: item.activityId,
      selectSkuId: item.selectSkuId,
      materialNo: item.materialNo,
    });
    setShowPoster(true);
  };
  const toMaterialDetailPage = (item) => {
    const { type, materialNo } = item;
    navigate2Material(type, materialNo, frontCodes.current[0].code || "");
  };

  useEffect(() => {
    document.title = "品牌素材";
    onShareAppMessageByBrandMaterial();
    getMaterialList().finally(() => {
      setFirstLoading(false);
      /* eslint-disable */
      if (track.track) {
        track.track("$pageview", {
          page_name: '品牌素材列表页 ',
          previous_page_name: contextTrackData["previous_page_name"] || previous_page_name,
          brand_id: brandId,
        });
      }
    });
  }, []);

  return (
    <div className={styles["brand-material"]}>
      <TopBar isShowSearch={false} isHideRight={true} />
      <div className={styles["brand-material-content"]} id="material">
        {
          materialList.length > 0 && (
            <>
              {
                materialList.map((item, index) => (
                  <div className={styles["material-list-item"]} key={index}>
                    <MaterialListCardComp
                      material={item}
                      isCollect={true}
                      showlabel={true}
                      getServerTime={getServerTime}
                      onForwardAction={onForwardAction}
                      onAction={toMaterialDetailPage}
                      onAvatarAction={toMaterialDetailPage}
                      onLabelAction={toMaterialDetailPage}
                    />
                  </div>
                ))
              }
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
            </>
          )
        }
        {!materialList.length && !hasMore && !firstLoading && <Empty label='暂无数据' />}
        {/** 初始加载 */}
        {
          firstLoading && (<div className={styles["loading"]}><img src={loadingPng} /></div>)
        }
      </div>
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="MATERIAL"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
      <SideBar />
    </div>
  );
};

export default BrandMaterial;