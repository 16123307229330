import VerticalScrollview from "@/components/vertical-scrollview";
import { ImageURLUitls } from "@akc/foundation";
import { Image, ImageViewer, Swiper } from "antd-mobile";
import React from "react";
import { default as SnapshotBottomDialog, SnapshotDialogType } from "./components/bottom-dialog";
import ProductIntroduceComp from "./components/product-introduce";
import ProductLabelComp from "./components/product-label";
import styles from "./detail.module.less";
import { useTradeSnapshotDetail } from "./hook/useTradeSnapshotDetail";

//交易快照详情页面
const TradeSnapshotDetail: React.FC = () => {
    const { ...control } = useTradeSnapshotDetail();
    let paramsStr = "";
    control.snapshotDetail?.propertyList?.forEach((e, index) => {
        if (index === (control.snapshotDetail?.propertyList?.length || 0) - 1) {
            paramsStr += `${e.propertyName}`;
        } else {
            paramsStr += `${e.propertyName}，`;
        }
    });
    return (
        <VerticalScrollview className={styles.tradeSnapshotDetailC}>
            {/* 顶部区域 */}
            {control.snapshotDetail?.mainImageList?.length && (
                <Swiper
                    className={styles.top}
                    indicator={(total, current) => (
                        <div className={styles.customIndicator}>{`${current + 1}/${total}`}</div>
                    )}
                >
                    {control.snapshotDetail?.mainImageList?.map?.((item, index) => (
                        <Swiper.Item key={index}>
                            <Image
                                src={ImageURLUitls.normalProcessImage(item, 375, 375)}
                                fit="fill"
                                onClick={() => {
                                    const skuMainImageUrls = control.snapshotDetail?.mainImageList ?? [];
                                    control.setShowPicPreview(true);
                                    control.setPreviewPicList(skuMainImageUrls ?? []);
                                    control.setPreviewIndex(index ?? 0);
                                    control.previewRef?.current?.swipeTo(index ?? 0);
                                }}
                            />
                        </Swiper.Item>
                    ))}
                </Swiper>
            )}
            {/* 商品名称&快照解读 */}
            {control.snapshotDetail && (
                <div className={styles.desc}>
                    <div className={styles.title}>{control.snapshotDetail?.productName}</div>
                    <div className={styles.exp}>
                        <div className={styles.txt}>
                            当前页面内容为订单快照，包含订单创建时的商品描述和下单信息，买卖双方和平台在发生交易争议时，该页面作为判定依据。
                        </div>
                        {control.snapshotDetail?.salesStatus === "UP" && (
                            <div
                                className={styles.pdc}
                                onClick={() => {
                                    control.goProductDetail();
                                }}
                            >
                                <div className={styles.pd}>点击查看最新商品详情</div>
                                <Image
                                    className={styles.arrow}
                                    src="https://akim-oss.aikucun.com/a562a323aa46c131288790a104d81662f6f37501_1734602577356_55.png"
                                ></Image>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* 营销标签 */}
            <div className={styles.labelC}>
                {control.snapshotDetail?.skuInfo?.salePropertyValue?.length && (
                    <ProductLabelComp
                        title="已选"
                        content={control.snapshotDetail?.skuInfo?.salePropertyValue}
                    />
                )}
                {control.snapshotDetail?.isDisplayPropertyFlag &&
                    control?.snapshotDetail?.propertyList?.length && (
                        <ProductLabelComp
                            title="参数"
                            content={paramsStr}
                            onClick={() => {
                                control.setShowProductParams(true);
                            }}
                        />
                    )}
            </div>

            {/* 商品介绍 */}
            {control.snapshotDetail?.detailImageList?.length && (
                <ProductIntroduceComp spuDetailImageUrls={control.snapshotDetail?.detailImageList} />
            )}

            {/* 图片预览 */}
            <ImageViewer.Multi
                ref={control.previewRef}
                getContainer={document.body}
                images={control.previewPicList}
                visible={control.showPicPreview}
                defaultIndex={control.previewIndex}
                onClose={() => {
                    control.setShowPicPreview(false);
                }}
            />
            {/* 商品参数底部弹窗 */}
            <SnapshotBottomDialog
                dialogVisible={control.showProductParams}
                type={SnapshotDialogType.Product_Paramters}
                snapshotDetail={control.snapshotDetail}
                onClose={(type) => {
                    control.setShowProductParams(false);
                }}
            />
        </VerticalScrollview>
    );
};

export default TradeSnapshotDetail;
