import {
  queryBrandPromotion,
} from "@/api/yearEndBonus";
import loadingPng from "@/base64";
import exposure from "@/utils/expose/exposure";
import { getCookie } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Dialog, Image } from "antd-mobile";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./brand-bole.module.less";
import ActivityBanner from "./components/activity-banner/activity-banner";
import ActivityStatus from "./components/activity-status/activity-status";
import EmptyStatus from "./components/empty-status";

const BrandBole: React.FC = () => {
  document.title = "品牌伯乐奖";
  const pageName = "品牌伯乐奖";
  const track = Track();

  const [search] = useSearchParams();
  const navigater = useNavigate();
  const { navigate } = useNavigation();

  const [pageInfo, setPageInfo] = useState<any>({});
  const [tabList, setTabList] = useState<any>([
    {
      label: "参与活动",
      value: "participateList",
      total: 0
    },
    {
      label: "未参与活动",
      value: "absentParticipateList",
      total: 0
    },
    {
      label: "全部活动",
      value: "allList",
      total: 0
    },
  ]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [list, setList] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  const toRulePage = () => {
    AKJS.action.dispatch("event.page.open", {
      url: "https://h5.aikucun.com/p/9XoM6bj9i.html",
    });
  };

  const getPageInfo = async () => {
    setPageLoading(true);
    const data: any = await queryBrandPromotion();
    setPageInfo(data || {});
    setPageLoading(false);
  };

  const showDialog = () => {
    Dialog.alert({
      title: "预计中奖金额",
      content: "此奖励金额为截止当前日期，各个奖项预计获得奖励之和（不包含实物奖励），最终实发奖励以活动结束后实际发放为准。",
      confirmText: "我知道了",
      className: "dialog__private",
      // onConfirm: () => { },
    });
  };

  const onChange = (index: number) => {
    setTabIndex(index);
    setList(pageInfo?.[tabList[index].value] || []);
    track.track("resource_click", {
      page_name: pageName,
      domain: "年终奖",
      tab_name: tabList[index].label,
      module: "活动",
      resource_type: "活动卡片",
      resource_name: tabList[index].label,
      resource_rank: index,
      btn_name: tabList[index].label,
    });
  };

  const toDetailPage = (activity, index) => {
    track.track("resource_click", {
      page_name: pageName,
      domain: "年终奖",
      tab_name: tabList[tabIndex]?.label,
      module: "活动",
      resource_type: "活动卡片",
      resource_name: activity?.activityName,
      resource_rank: index,
      activity_id: activity?.brandActivityCode,
      btn_name: "查看活动详情"
    });
    let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    navigate({
      appOptions: {
        url: `${origin}/mx-shop-micro/year-end-bonus/detail?brandActivityCode=${activity.brandActivityCode}&token=${search.get("token")}&shopId=${search.get(
          "shopId",
        )}&env_identity=${envid || getCookie("X-Hades-Env-Identity")}`
      },
    });
  };

  useEffect(() => {
    setTabList(tabList.map(item => {
      return {
        ...item,
        total: pageInfo?.[item.value]?.length || 0
      };
    }));
    onChange(pageInfo?.participateList?.length === 0 ? 2 : 0);
  }, [pageInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        const dom = document.getElementById(`item-${i}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [list]);

  useEffect(() => {
    getPageInfo();
    track.pageView(pageName, {
      domain: "年终奖",
    });
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.scroll_view}>
        {/* 规则 */}
        <img
          className={styles.rule__absolute}
          src="https://akim-oss.aikucun.com/mshop/8e8a120b7b795a6c5b7428427f5a3e67245d44d4_1731383104974_3.png"
          onClick={toRulePage}
        />
        {/* header */}
        <div className={styles.page__header}>
          {/* 活动状态 */}
          {
            pageInfo?.taskStatus && <ActivityStatus
              type="brand-bole"
              sysTime={pageInfo.sysTime}
              taskEndTime={pageInfo.taskEndTime}
              taskStatus={pageInfo.taskStatus}
              backgroundColor="#C54BF3"
              borderColor="#FCA7F3"
            />
          }
        </div>
        {/* body */}
        <div className={styles.page__body}>
          {/* 数据展示 */}
          <div className={styles.body__data}>
            <div className={styles.data__list}>
              <div className={styles.list__item}>
                <div className={styles.item__value}>{pageInfo?.awardAmount || 0}<span className={styles.unit}>元</span></div>
                <div className={styles.item__label}>预计中奖金额<img className={styles.icon} src="https://akim-oss.aikucun.com/mshop/dc16dd3352577a826e74c056027382e2b5fbc772_1731119200062_62.png" onClick={showDialog} /></div>
              </div>
              <div className={styles.list__item}>
                <div className={styles.item__value}>{pageInfo?.participateNum || 0}<span className={styles.unit}>个</span></div>
                <div className={styles.item__label}>参与活动数量</div>
              </div>
            </div>
            <div className={styles.data__bg}></div>
          </div>
          {/* 内容区域 */}
          <div className={styles.body__content}>
            {/* tab */}
            {
              tabList.length > 0 && <div className={styles.content__tab}>
                {
                  tabList.map((item, index) => (
                    <div className={`${styles.tab__item} ${index === tabIndex && styles.tab__active}`} key={item.value} onClick={() => onChange(index)}>{`${item.label}(${item.total})`}</div>
                  ))
                }
              </div>
            }

            {/* 卡片列表 */}
            {
              list.map((item, index) => (
                <div
                  key={item.brandActivityCode}
                  className={styles.content__activity}
                  onClick={() => toDetailPage(item, index)}
                  id={`item-${index}`}
                  data-eventid={`item-${index}`}
                  data-param={JSON.stringify({
                    page_name: pageName,
                    domain: "年终奖",
                    tab_name: tabList[tabIndex]?.label,
                    module: "活动",
                    resource_type: "活动卡片",
                    resource_name: item?.activityName,
                    resource_rank: index,
                    activity_id: item?.brandActivityCode,
                  })}
                >
                  <img className={styles.activity__pic} src={item.activityPic || "https://akim-oss.aikucun.com/mshop/31a296fbf428758527861b0c56c94538daef43ba_1731414035609_88.png"} />
                  <div className={styles.activity__info}>
                    <div className={styles.info__left}>
                      <h3>{item.activityName || ""}</h3>
                      <p>活动时间：{moment(parseInt(item.activityStartTime)).format("YYYY.MM.DD")}-{moment(parseInt(item.activityEndTime)).format("YYYY.MM.DD")}</p>
                    </div>
                    <div className={styles.info__right}>
                      <div className={styles.right__btn}>查看活动详情</div>
                    </div>
                  </div>
                </div>
              ))
            }
            {/* 空态 */}
            {list.length === 0 && <EmptyStatus />}
          </div>
        </div>
        {/* 领奖攻略 */}
        <img className={styles.page__strategy} src="https://akim-oss.aikucun.com/mshop/2d83f3b85c9e6e18f4fbde89e585a759e1872782_1732602762903_67.png" />
        {/* 业绩提升小妙招 */}
        <ActivityBanner pageName={pageName} />
      </div>
      {
        pageLoading && (
          <div className={styles.page__loading}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )
      }
    </div>
  );
};

export default BrandBole;