import { queryProductRecommendList } from "@/api/product";
import { ProductDetailModel, ProductModel } from "@akc/biz-api";
import { useEffect, useRef, useState } from "react";

const useSoldOutRecommendProductList = ({
  currentActivitySpuId,
  product,
}: {
  currentActivitySpuId?: string;
  product?: ProductDetailModel;
}) => {
  // 关联推荐的id
  const relatedRecommendReqId = useRef<string>();
  // 是否显示推荐商品弹窗
  const [showRecommendProductDialog, setShowRecommendProductDialog] = useState<boolean>(false);
  // 商品列表
  const [soldOutRecommendProductList, setSoldOutRecommendProductList] = useState<ProductModel[]>(
    [],
  );

  const fetchProductList = async () => {
    if (
      product?.spuInfoVO?.sellOut ||
      !product?.spuInfoVO?.spuOnline ||
      (product?.activitySimpleVO?.status &&
        [3, 4].includes(product?.activitySimpleVO?.status) &&
        product?.activitySpuId)
    ) {
      queryProductRecommendList({
        activitySpuIds: [product?.activitySpuId ?? ""],
        pageId: "H5_RelatedSale",
        relatedScene: product?.spuInfoVO?.sellOut ? "detail_shortage" : "detail_offsale",
      }).then((res) => {
        if (res && (res.result?.length ?? 0) > 0) {
          setSoldOutRecommendProductList(res.result ?? []);
          relatedRecommendReqId.current = res.extra?.searchRequestId;
          if (product?.activitySpuId === currentActivitySpuId) {
            setShowRecommendProductDialog(true);
          }
        }
      });
    } else {
      setSoldOutRecommendProductList([]);
      setShowRecommendProductDialog(false);
    }
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    if (!product?.activitySpuId) {
      return;
    }
    fetchProductList();
  }, [product]);

  return {
    showRecommendProductDialog,
    soldOutRecommendProductList,
    setShowRecommendProductDialog,
  };
};

export default useSoldOutRecommendProductList;
