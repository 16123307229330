import { checkBind, checkUserBindPhone } from "@/api/my";
import { getPlatform } from "@/utils/platform";
import { useEffect, useState } from "react";

const useCheckBind = () => {
  const [isBind, setIsBind] = useState(true);
  const [isAuthed, setIsAuthed] = useState(true);

  const updateAuthStatus = async () => {
    const res = await checkBind();
    setIsAuthed(res?.data?.isAuthorized);
  };
  const updateBindStatus = async () => {
    const res = await checkUserBindPhone();
    setIsBind(res?.data?.isBindPhone);
    const { isMini } = getPlatform();
    if (!res?.data?.isBindPhone && !isMini) {
      updateAuthStatus();
    }
  };

  useEffect(() => {
    updateBindStatus();
  }, []);
  return {
    isAuthed,
    isBind,
    updateBindStatus,
  };
};

export default useCheckBind;
