import { getShopNaviList, shopPlusActivityList } from "@/api/shopPlus";
import { useCommonContext } from "@/utils/context/common";
import { identity } from "@/utils/identity";
import { pxtorem } from "@/utils/pxtorem";
import { getUrlParam } from "@/utils/url-parse";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { UseDecorateContext } from "../../hooks/useDecorate";
import FilterList from "./components/filterList";
import ProductList from "./components/productList";
import ProductStatusTab from "./components/productStatusTab";
import styles from "./index.module.less";

const BrandList = ({ shopInfo, brandAction, count }) => {
  const [search] = useSearchParams();
  const { brandSimpleVOList } = shopInfo ?? {};

  const [activeKey, setActiveKey] = React.useState<any>(0);

  const btnAction = (index, brandId, brandName) => {
    setActiveKey(index);
    brandAction(brandId, index, brandName);
  };

  React.useEffect(() => {
    const brandId = search.get("brandId");
    if (brandSimpleVOList && brandSimpleVOList?.length > 0) {
      for (let i = 0; i < brandSimpleVOList.length; i++) {
        if (brandSimpleVOList[i]?.brandId === brandId && count <= 0) {
          setActiveKey(i);
          setTimeout(() => {
            const categoryDom = document.getElementById(`brand-${i}`) as HTMLElement;
            categoryDom.scrollIntoView({
              behavior: "smooth",
              inline: "center",
              block: "nearest",
            });
          }, 1000);
          break;
        }
      }
    }
  }, []);

  if (brandSimpleVOList) {
    return (
      <div className={styles["brand-container"]}>
        <div className={styles["brand-list"]}>
          {brandSimpleVOList.map((item, index) => {
            const { brandLogo, brandName, brandId } = item;

            return (
              <div
                onClick={() => btnAction(index, brandId, brandName)}
                className={`${styles["brand-icon"]} ${activeKey === index ? styles["active"] : ""}`}
                key={index}
                id={`brand-${index}`}
              >
                {brandLogo ? (
                  <div>
                    <img src={brandLogo} />
                  </div>
                ) : (
                  ""
                )}
                <div>{brandName}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <></>;
};

const ActList = (props) => {
  const [actArr, setActArr] = React.useState([]);
  const [, setActComProps] = React.useState({});
  const [naviList, setNaviList] = React.useState([]);
  const [naviActive, setNaviActive] = React.useState(getUrlParam()?.["shareTabId"]);
  const [promoTag, setPromoTag] = React.useState<undefined | string>(undefined);
  const [sortType, setSortType] = React.useState(undefined);
  const [sortModel, setSortModel] = React.useState(undefined);
  const [filterBoardInfo, setFilterBoardInfo] = React.useState({});
  const [, setHasMore] = React.useState(true);
  const [, setPageIndex] = React.useState(0);
  const [search] = useSearchParams();
  const merchantShopCode = search.get("merchantShopCode");
  const [isquery, setIsQuery] = React.useState(false);
  const [actSortType] = React.useState(2);
  const [actSortModel] = React.useState(1);
  const [naviFloat, setNaviFloat] = React.useState(false);
  const stickyListenRef = React.useRef<HTMLDivElement | null>(null);
  const filterRef = React.useRef<HTMLDivElement | null>(null);
  const [fontsize, setFontsize] = React.useState(0);
  const { track } = useCommonContext();
  const [previous_page_name] = React.useState<string | null>(search.get("previous_page_name"));
  const { setSelectedNav, setShareBtnText, brandId, setBrandId } = UseDecorateContext();
  const filterDomRef = React.useRef<any>(null);
  const [showProductStatus, setShowProductStatus] = React.useState(false);
  const [productStatus, setProductStatus] = React.useState<number | null>(null);

  const { shopInfo } = props;

  const filterTab = identity()
    ? [
      {
        name: "综合",
        value: 1,
      },
      {
        name: "价格",
        value: 7,
        keys: [1, 2],
      },
      {
        name: "上新",
        value: 6,
        keys: [1, 2],
      },
    ]
    : [
      {
        name: "综合",
        value: 1,
      },
      {
        name: "价格",
        value: 7,
        keys: [1, 2],
      },
      {
        name: "上新",
        value: 6,
        keys: [1, 2],
      },
      {
        name: "赚",
        value: 8,
        keys: [1, 2],
      },
    ];

  const reqActList = (params) => {
    if (isquery) {
      return;
    }
    setIsQuery(true);
    shopPlusActivityList(params).then(
      (res: any) => {
        if (!res || !res.data) {
          return;
        }
        try {
          const { result, hasNext, pageIndex } = res.data;
          if (pageIndex > 1) {
            const valArr = result ? actArr.concat(result) : result;
            setActArr(valArr);
            setActComProps({
              hasNext,
              actList: valArr,
            });
          } else {
            setActArr(result || []);
            setActComProps({
              hasNext,
              actList: result || [],
            });
          }

          setHasMore(hasNext);
          setPageIndex(pageIndex);
        } catch (error) {
          console.error(error);
        }
        setIsQuery(false);
      },
      (err) => {
        console.error(err);
        setIsQuery(false);
      },
    );
  };
  const naviActiveFn = (index, itemType, itemName?: any, isChangeBrand = false) => {
    console.log("itemType", itemType);
    setFilterBoardInfo({});
    filterDomRef.current?.resetFilter?.();
    setSelectedNav({ itemCode: index || "", itemType: itemType || "", itemName: itemName || "" });
    props.changeNav({ itemCode: index, itemType, itemName }, isChangeBrand);
    if (brandId) {
      setShareBtnText("转发品牌");
    } else {
      if (itemType === "ALL") {
        setShareBtnText("转发全部");
      } else {
        setShareBtnText("转发全部");
      }
    }

    if (track.track) {
      track.track("tab_click", {
        page_name: "店铺全部",
        previous_page_name,
        domain: "B2R旗舰店",
        rank: index + 1,
        tab_name: itemName || itemType,
        merchant_shop_code: merchantShopCode,
      });
    }
    const { goScrollTop } = props;
    goScrollTop();
    if (itemType === "promoTag") {
      setPromoTag(index);
      setNaviActive(index);
      return;
    }
    setPromoTag("");
    // setNaviActive(undefined);
    setNaviActive(index || undefined);
  };
  const getNaviList = async () => {
    const param = {
      pageId: 1,
      activityTypeGroup: 1,
      shopCodes: [merchantShopCode],
    };
    const { currentPromotionType = "", advancePromotionType = "" } = props;
    if (!naviList.length) {
      currentPromotionType && (param["currentPromotionType"] = currentPromotionType);
      advancePromotionType && (param["advancePromotionType"] = advancePromotionType);
    }
    if (brandId) {
      param["brandIds"] = [brandId];
    }

    const res = await getShopNaviList(param);
    const { data } = res ?? {};

    setNaviList(data);
    const shareTabId = getUrlParam()?.["shareTabId"];
    if (shareTabId !== "") {
      const activeItem = data?.find((item) => item.itemCode === shareTabId);
      activeItem && naviActiveFn(activeItem.itemCode, activeItem.itemType, activeItem.itemName);
    } else if (data?.some((item) => item.selected)) {
      const activeItem = data?.find((item) => item.selected);
      activeItem && naviActiveFn(activeItem.itemCode, activeItem.itemType, activeItem.itemName);
    } else {
      naviActiveFn(null, "ALL", "");
    }
  };

  React.useLayoutEffect(() => {
    getNaviList();
  }, []);

  React.useEffect(() => {
    const param = {
      pageId: "H5_Shop",
      searchParams: {
        sortModel: actSortModel,
        sortType: actSortType,
        pageNum: 1,
        shopCodes: [merchantShopCode],
      },
    };
    if (brandId) {
      param.searchParams["brandIds"] = [brandId];
    }

    reqActList(param);
    getNaviList();
    props.changeBrand(brandId);
  }, [brandId]);

  const onSortChange = (props) => {
    console.log(filterTab);
    const sortName = filterTab.find((item) => item.value === props.sortModel)?.name;
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺全部",
        previous_page_name,
        domain: "B2R旗舰店",
        rank_name: sortName,
        rank_type: props.sortType === 1 ? "升序" : "降序",
        merchant_shop_code: merchantShopCode,
      });
    }
    const { sortModel, sortType } = props;

    setSortType(sortType);
    setSortModel(sortModel);
  };

  const onFilterBoardChange = (params) => {
    if (params) {
      let filterBoardParams = {};
      Object.keys(params).forEach((key) => {
        if (
          !(Array.isArray(params[key]) && params[key].length === 0) &&
          params[key] !== undefined
        ) {
          filterBoardParams[key] = params[key];
        }
      });
      setFilterBoardInfo(filterBoardParams);
    }
  };

  const onStatusChange = (status: number) => {
    setProductStatus(status === productStatus ? null : status);
    props?.goScrollTop();
  };

  const onStatusShow = (show: boolean) => {
    setShowProductStatus(show);
  };

  React.useEffect(() => {
    const fontsize = document.documentElement.style.fontSize.split("px")[0];
    setFontsize(Number(fontsize));
    const topBarDomHeight = document.getElementById("top-bar")?.clientHeight || 0;
    const filterHeight = filterRef.current?.clientHeight || 0;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio === 0) {
          setNaviFloat(true);
        } else {
          setNaviFloat(false);
        }
      },
      {
        threshold: [0, 0.5, 1],
        rootMargin: `-${topBarDomHeight + filterHeight + 1}px 0px 0px 0px`,
      },
    );
    stickyListenRef.current && observer.observe(stickyListenRef.current);
  }, []);

  const changeBand = (brandId, index, brandName) => {
    setBrandId(brandId);
    naviActiveFn(null, "ALL", "", true);
    if (track.track) {
      track.track("tab_click", {
        page_name: "店铺首页",
        previous_page_name,
        domain: "B2R旗舰店",
        rank: index,
        tab_name: brandName,
        merchant_shop_code: merchantShopCode,
      });
    }
  };
  React.useEffect(() => {
    return () => {
      naviActiveFn(null, "ALL");
    };
  }, []);

  return (
    <>
      <BrandList
        shopInfo={shopInfo}
        brandAction={(brandId, index, brandName) => changeBand(brandId, index, brandName)}
        count={props.count}
      />
      <div className={`${styles["act-list-container"]} ${styles["no-padding"]}`}>
        <div
          className={`${naviFloat ? styles["fixed-filter-container"] : "filter-container"}`}
          style={{ top: (document.getElementById("top-bar")?.clientHeight || 0) - 2 }}
          ref={filterRef}
        >
          <FilterList
            ref={filterDomRef}
            filterList={filterTab}
            brandId={brandId}
            promotionId={promoTag}
            categoryId={naviActive && !promoTag ? naviActive : undefined}
            sortChange={onSortChange}
            filterBoardChange={onFilterBoardChange}
            goScrollTop={props.goScrollTop}
          />
        </div>

        <div className={styles["product-list"]}>
          <div
            ref={stickyListenRef}
            style={{
              position: "absolute",
              top: naviFloat ? filterRef.current?.clientHeight : 0,
              height: "1px",
              width: "100%",
              background: "#FFF",
            }}
          ></div>
          <div
            className={`${styles["navi-list"]} ${naviFloat ? styles["navi-float"] : ""}`}
            id="navi_list_container"
            style={{
              paddingBottom: !identity() ? `${fontsize * 2.5}px` : "",
              height: naviFloat ? "89vh" : !identity() ? "64vh" : "71vh",
            }}
          >
            <div style={{ backgroundColor: "#fff" }}>
              {naviList.map((item, index) => {
                const { itemName, itemCode, itemType } = item;
                return (
                  <div
                    className={`${styles["navi-btn"]} ${itemCode === naviActive ? styles["active"] : ""
                      }`}
                    key={index}
                    onClick={() => naviActiveFn(itemCode, itemType, itemName)}
                  >
                    {itemName}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={styles["list-body"]}
            style={
              naviFloat
                ? {
                  marginLeft: pxtorem(64),
                  marginTop: pxtorem(44),
                  minHeight: "88vh",
                }
                : {}
            }
            id="list-body"
          >
            {showProductStatus && (
              <ProductStatusTab
                naviFloat={naviFloat}
                productStatus={productStatus}
                onStatusChange={onStatusChange}
              />
            )}
            <ProductList
              sortModel={sortModel}
              sortType={sortType}
              filterBoardInfo={filterBoardInfo}
              promoTag={promoTag}
              category={naviActive}
              brandId={brandId}
              productStatus={productStatus}
              onStatusShow={onStatusShow}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActList;
