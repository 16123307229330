import { isInGrayBatch } from "@/api";
import { queryJumpTypeServ } from "@/api/activity";
import { buyMore } from "@/api/add-to-cart";
import { queryAtmosphereData } from "@/api/newPerson";
import {
  convertProIdToSpuId,
  fetchMaterialProductSwitch,
  getConverterInfo,
  gqueryCGoldCoinAmount,
  queryActivityCardForProductDetail,
  queryBannerInfo,
  queryFavioriteStatus,
  queryMaterialList,
  queryMerchantShopCardForProductDetail,
  queryProductDetail,
  queryTopMaterialPage,
} from "@/api/product";
import { SharePosterParams } from "@/api/scode/poster";
import { SourceScene } from "@/api/scode/scode_params";
import { CustomEditData } from "@/components/poster-swiper";
import { sharePosterParams4H5Product } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { navigate } from "@/utils/navigate";
import { getCookie, MICRO_APP_BASEURL } from "@/utils/platform";
import { getUrlParam } from "@/utils/url-parse";
import useNavigation from "@/utils/useNavigate";
import {
  ActivityModel,
  BrandCardModel,
  CouponModel,
  ProductDetailAtmosphereModel,
  ProductDetailModel,
  ResourceBannerModel,
  ShopCardModel,
  SkuInfoMappingModel,
} from "@akc/biz-api";

import { MultiImageViewerRef, Toast } from "antd-mobile";
import React from "react";

import { MarketingServicesType } from "../../components/promotion-dialog";
import { useAddressList } from "../../hook/useAddressList";
import { useBaseProductDetail } from "../../hook/useBaseProductDetail";
import { useCouponList } from "../../hook/useCouponList";
import useRecommendProductList from "../../hook/useRecommendProductList";
import { MaterialListModel } from "../../model/MaterialListModel";
import { TopMaterialResponse } from "../../model/TopMaterialResponse";
import { TopTabModel } from "../../model/TopTabModel";
import { PDUtils } from "../../utils/PDUtils";
import { ProductVideoImageItem } from "../model/Media";
import { default as useCoupon, default as useMarketPop } from "./useMarketPop";
import useSoldOutRecommendProductList from "./useSoldOutRecommendProductList";
import { useTrack } from "./useTrack";

const pageName = "商品详情页";
export const useProductDetail = () => {
  const {
    activityId,
    setActivityId,
    selectSkuId,
    setSelectSkuId,
    firstSalePropertyValue,
    setFirstSalePropertyValue,
    secondSalePropertyValue,
    setSecondSalePropertyValue,
    activitySpuId,
    setActivitySpuId,
    pageCode,
    liveNo,
    productDetail,
    setProductDetail,
    onServiceAction,
    goCart,
    skcId,
    ud,
    needConvert,
    productNum,
    converterMsg,
    showTopTabs,
    activeKey,
    tabItems,
    setTabItems,
    showFloatMenu,
    getProductSysTemShareInfo,
  } = useBaseProductDetail();
  const commonContext = useCommonContext();
  const currentRoleIsC = commonContext.currentRole === "C";
  const { cartNum, currentRole, setCartNum } = useCommonContext();
  const { navigate2Activity, navigate2GoodsOfNpieceXfold } = useNavigation();

  const headerDomRef = React.useRef<HTMLDivElement>(null);
  const previewRef = React.useRef<MultiImageViewerRef>(null);
  // 收藏是否展示
  const [showFavoriteIcon, setShowFavoriteIcon] = React.useState<boolean>(false);
  // 档期卡片
  const [activityItem, setActivityItem] = React.useState<ActivityModel>();
  // 店铺卡片
  const [shopItem, setShopItem] = React.useState<ShopCardModel>();
  // 素材卡片
  const [materialItem, setMaterialItem] = React.useState<TopMaterialResponse>();
  // 图片/视频
  const [videoImage, setVideoImage] = React.useState<ProductVideoImageItem[]>([]);
  // 资源选择的index
  const [mediaSelectIndex, setMediaSelectIndex] = React.useState<number>(0);
  // 多媒体预览组件
  const [showMediaPreview, setShowMediaPreview] = React.useState<boolean>(false);
  // 品牌卡片
  const [brandItem, setBrandItem] = React.useState<BrandCardModel>();
  // 氛围及购买记录
  const [atmosphereModel, setAtmosphereModel] = React.useState<ProductDetailAtmosphereModel>();
  // 实拍专区
  const [materialListModel, setMaterialListModel] = React.useState<MaterialListModel>();
  // 营销弹窗显示状态
  const [promotionDialogVisible, setPromotionDialogVisible] = React.useState<boolean>(false);
  // 营销弹窗类型
  const [promotionDialogType, setPromotionDialogType] = React.useState<MarketingServicesType>();
  // 加购面板显示
  const [cartModalVisible, setCartModalVisible] = React.useState<boolean>(false);
  // 加购面板所需模型
  const [modalProduct, setModalProduct] = React.useState<ProductDetailModel>();
  // 加购面板按钮类型
  const [cartPannelButtonType, setCartPannelButtonType] = React.useState<number>();
  // 购物车数量
  const [cartTotal, setCartTotal] = React.useState<number>();
  // 转发
  const [sharePosterData, setSharePosterData] = React.useState<SharePosterParams>();
  const [customEditData, setCustomEditData] = React.useState<CustomEditData>();
  const [showPoster, setShowPoster] = React.useState(false);
  const [isOTC, setIsOTC] = React.useState(false);

  //是否显示新版加购面板
  const [showNewCartPanel, setShowNewCartPanel] = React.useState<boolean>(false);
  //图片预览
  const [previewPicList, setPreviewPicList] = React.useState<string[]>([]);
  //图片预览是否显示
  const [showPicPreview, setShowPicPreview] = React.useState<boolean>(false);
  //图片预览默认 index
  const [previewIndex, setPreviewIndex] = React.useState<number>(0);
  //banner数据集
  const [bannerList, setBannerList] = React.useState<ResourceBannerModel[]>();
  //用药指导弹窗
  const [showMedicateDialog, setShowMedicateDialog] = React.useState<boolean>(false);
  //是否显示推广费弹窗
  const [showPromotionFeeDialog, setShowPromotionFeeDialog] = React.useState<boolean>(false);
  // 是否预告佣金(区分：佣金 || 预告佣金)
  const [isAdvanceProfit, setIsAdvanceProfit] = React.useState<boolean>(false);
  //c积分
  const [coinAmount, setCoinAmount] = React.useState<number>(0);
  //是否显示骨架屏
  const [showSkeleton, setShowSkeleton] = React.useState<boolean>(true);

  // 埋点
  const { trackPageView } = useTrack(pageName);

  //地址
  const { addressList, selectAddressId, updateSelectAddress, fetchAddressList, isLoadAddress } =
    useAddressList();

  //推荐商品列表
  const { hasNext, recommendProductList, onLoadNextPage } = useRecommendProductList({
    product: productDetail,
    scene: "product_detail",
  });

  //售罄关联商品弹框
  const { soldOutRecommendProductList, showRecommendProductDialog, setShowRecommendProductDialog } =
    useSoldOutRecommendProductList({
      currentActivitySpuId: activitySpuId ?? undefined,
      product: productDetail,
    });

  // 新人专区/营销pop
  const {
    couponInfo,
    isShowMarketingPop,
    showPopCoupon,
    setShowPopCoupon,
    marketingPopInfo,
    setIsShowMarketingPop,
    search,
  } = useMarketPop({
    product: productDetail,
  });

  //优惠券
  const { couponList, receiveCoupon } = useCouponList(productDetail);

  React.useEffect(() => {
    const fetchFavioriteStatus = async () => {
      queryFavioriteStatus({
        businessCode: "H5_GUIDE_COLLECT_WINDOW_CODE",
      }).then((res) => {
        setShowFavoriteIcon(res ?? false);
      });
    };
    fetchFavioriteStatus();
  }, []);

  //获取加购面板灰度
  const getCartPanelGray = async () => {
    const res = await isInGrayBatch(["B-H5_REACT_ADD_PANEL"]);
    const newCartPanel = !!res?.[0]?.isHit;
    setShowNewCartPanel(newCartPanel);
  };

  //获取购物车数量
  const changeCartNum = () => {
    setCartNum().then((num) => {
      console.log("购物车数量", num);
      setCartTotal(num);
    });
  };

  //替换当前页面路径参数
  const getReplaceUrlParam = (params?: { activitySpuId?: string; activityId?: string }) => {
    let href = location.href;
    href = PDUtils.updateUrlParameter(
      href,
      "activitySpuId",
      params?.activitySpuId ?? activitySpuId ?? "",
    );
    href = PDUtils.updateUrlParameter(href, "activityId", params?.activityId ?? activityId ?? "");
    return href.split("?")[1];
  };

  // 请求商详
  const fetchProductDetail = () => {
    queryProductDetail({
      activityId,
      activitySpuId,
      selectSkuId,
      firstSalePropertyValue,
      secondSalePropertyValue,
      pageId: "H5_ProductDetail",
      addressId: selectAddressId,
    })
      .then((res) => {
        setShowSkeleton(false);
        setProductDetail(res);
        getProductSysTemShareInfo(res);
        if (!activityId) {
          setActivityId(res?.activityId);
        }
        trackPageView({
          previousPageName: search.get("page_name") ?? search.get("previous_page_name"),
          merchantShopCode: res?.activitySimpleVO?.merchantShopCode,
          activitySpuCode: res?.activitySpuId,
          skuId: selectSkuId,
          spuId: res?.originActivitySpuId,
          productModel: res?.activityType === 4 ? "普通商品" : "档期商品",
          activityId: activityId,
        });
      })
      .catch((e) => {
        setShowSkeleton(false);
        if (e?.message) {
          Toast.show(e?.message);
        }
      });
  };

  React.useEffect(() => {
    document.title = "商品详情";

    //商品信息置换请求
    const fetchData = async () => {
      console.log("fetchData,", "start");
      let curActivitySpuId;
      let curActivityId;
      let curSelectSkuId;
      //productId 置换 spuId
      if (!activitySpuId && skcId) {
        console.log("fetchData,", "skcId置换spuId");
        try {
          const res = await convertProIdToSpuId({
            convertType: 1,
            skcIds: [skcId],
          });
          if (res && res.length) {
            curActivitySpuId = res?.[0]?.activitySpuId;
            curActivityId = res?.[0]?.activityId;
            curSelectSkuId = res?.[0]?.selectSkuId;
          }
        } catch (error) {
          console.log("请求skcId置换activitySpuId,error=", error);
        }
      }

      // 商品详情转换逻辑
      if (ud || needConvert) {
        console.log("fetchData,", "商品详情转换逻辑");
        try {
          const res = await getConverterInfo({
            activityId: activityId,
            activitySpuId: activitySpuId,
            productNum: productNum,
          });
          if (res && res.isConverter) {
            window.location.replace(
              `/mx-shop-micro/product?activitySpuId=${res.activitySpuId}&activityId=${res.activityId}&converterMsg=${res.converterMsg}`,
            );
            return;
          }
        } catch (error) {
          console.log("请求商品详情转换逻辑,error=", error);
        }
      }
      //请求素材商详开关
      const mps = await fetchMaterialProductSwitch(curActivitySpuId ?? activitySpuId);
      if (mps?.switchStatus === 1 && mps.materialNo?.length) {
        console.log("fetchData,", "符合素材商品详情条件");
        const path = `/mx-shop-micro/product-material?materialNo=${mps?.materialNo}&${getReplaceUrlParam(
          {
            activitySpuId: curActivitySpuId ?? activitySpuId,
            activityId: curActivityId ?? activityId,
          },
        )}`;
        location.replace(path);
        return;
      }
      console.log("fetchData,", "不符合素材商品详情条件");
      await fetchAddressList();
      if (curActivitySpuId) {
        setActivitySpuId(curActivitySpuId);
      }
      if (curActivityId) {
        setActivityId(curActivityId);
      }
      if (curSelectSkuId) {
        setSelectSkuId(curSelectSkuId);
      }
      console.log("fetchData,", "end");
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (!activitySpuId) {
      return;
    }
    if (!isLoadAddress) {
      return;
    }
    console.log(
      "监听属性值变化，请求商详接口",
      ",selectSkuId=",
      selectSkuId,
      ",firstSalePropertyValue=",
      firstSalePropertyValue,
      ",secondSalePropertyValue=",
      secondSalePropertyValue,
      ",activitySpuId=",
      activitySpuId,
    );
    //请求商品详情
    fetchProductDetail();
  }, [
    selectSkuId,
    firstSalePropertyValue,
    secondSalePropertyValue,
    activitySpuId,
    selectAddressId,
  ]);

  React.useEffect(() => {
    if (!productDetail) {
      return;
    }
    console.log("productDetail发生了变化");
    //获取加购面板灰度
    getCartPanelGray();
    //请求购物车数量
    changeCartNum();
    // 请求气氛数据
    queryAtmosphereData({
      activitySpuId,
    }).then((res) => {
      setAtmosphereModel(res);
    });
    //请求banner
    queryBannerInfo().then((res) => {
      setBannerList(res || []);
    });

    if (currentRoleIsC) {
      //实拍专区
      queryMaterialList({
        pageId: "H5_MaterialList",
        objectIds: activitySpuId ? [activitySpuId] : [],
        baseProductIds: productDetail?.spuInfoVO?.spuId ? [productDetail?.spuInfoVO?.spuId] : [],
      }).then((res) => {
        setMaterialListModel(res);
      });
    } else {
      //普通素材
      let params = {
        pageId: "H5_MaterialList",
        voConfig: { queryCollectionFlag: true },
        objectIds: activitySpuId ? [activitySpuId] : [],
        baseProductIds: productDetail?.spuInfoVO?.spuId ? [productDetail?.spuInfoVO?.spuId] : [],
      };
      queryTopMaterialPage(params).then((res) => {
        setMaterialItem(res);
      });
    }
    // 是否积分商品
    const isIntegralProduct =
      productDetail?.pricePanelType === 13 || productDetail?.pricePanelType2 === 7;
    if (!isIntegralProduct) {
      switch (productDetail?.cardEntranceVO?.cardType) {
        case "ACTIVITY": {
          // 请求档期卡片
          let searchRequestId = getCookie("mstoken") || "" + Date.now();
          let params = {
            pageId: "H5_ScheduleList",
            activityId: (productDetail?.skuAdvancePriceInfo ?? productDetail?.skuCurrentPriceInfo)
              ?.activityId,
            brandId: productDetail?.brandInfo?.brandId || "",
            spuId: productDetail?.spuInfoVO?.spuId || "",
            categoryCode: productDetail?.spuCategoryInfoVO?.categoryCode || "",
            merchantShopCode: productDetail?.activitySimpleVO?.merchantShopCode || "",
            searchRequestId,
          };
          queryActivityCardForProductDetail(params).then((res) => {
            setActivityItem(res);
          });
          break;
        }
        case "MERCHANT_SHOP": {
          // 请求店铺卡片
          let searchRequestId = getCookie("mstoken") || "" + Date.now();
          let params = {
            pageId: "H5_ScheduleList",
            activityId: (productDetail?.skuAdvancePriceInfo ?? productDetail?.skuCurrentPriceInfo)
              ?.activityId,
            brandId: productDetail?.brandInfo?.brandId || "",
            spuId: productDetail?.spuInfoVO?.spuId || "",
            categoryCode: productDetail?.spuCategoryInfoVO?.categoryCode || "",
            merchantShopCode: productDetail?.activitySimpleVO?.merchantShopCode || "",
            searchRequestId,
          };
          queryMerchantShopCardForProductDetail(params).then((res) => {
            setShopItem(res);
          });
          break;
        }
        default:
          break;
      }
    } else {
      //获取积分
      gqueryCGoldCoinAmount({})
        .then((res) => {
          if (res && res.integralCoinCenter) {
            const amount = res.integralCoinCenter?.coinAccount?.amount || 0;
            setCoinAmount(amount);
          } else {
            console.log("获取积分失败");
          }
        })
        .catch((error) => {
          Toast.show("服务异常，请稍后再试");
        });
    }
  }, [productDetail]);

  React.useEffect(() => {
    if (!productDetail) {
      return;
    }
    let tabs: TopTabModel[] = [];
    tabs.push({ key: "1", title: "宝贝" });
    if (materialItem || materialListModel?.materialPictureVOList?.length) {
      tabs.push({ key: "2", title: "素材" });
    }
    tabs.push({ key: "3", title: "详情" });
    if (recommendProductList.length > 0) {
      tabs.push({ key: "4", title: "推荐" });
    }
    setTabItems(tabs);
  }, [productDetail, materialItem, materialListModel, recommendProductList]);

  //显示营销标签弹窗
  const showPromotionDialog = (type: MarketingServicesType) => {
    setPromotionDialogType(type);
    setPromotionDialogVisible(true);
  };

  //立即购买
  const onBuyAction = () => {
    if (
      productDetail?.skuCurrentPriceInfo?.promotionTimeStatus === 1 &&
      !productDetail?.skuAdvancePriceInfo
    ) {
      Toast.show("该场活动还未开始，暂不支持购买");
      return;
    }
    if (!productDetail?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    if (!productDetail?.activityId || !productDetail?.activitySpuId) {
      console.log("活动id和商品id不能为空");
      return;
    }
    setCartModalVisible(true);
    setCartPannelButtonType(3);
    setModalProduct(productDetail);
  };

  //加入购物车
  const onAddCartAction = () => {
    //该商品不允许发货
    if (!productDetail?.skuExtendInfoVO?.allowDeliver) {
      Toast.show("此商品当前收货地址无法配送，请更换收货地址再进行购买");
      return;
    }
    // 不允许加购
    if (!productDetail?.skuExtendInfoVO?.buyShoppingCartModel) {
      return;
    }
    if (productDetail?.skuExtendInfoVO?.isVirtual) {
      Toast.show("亲~该场活动还未开始，暂不支持购买");
      return;
    }
    if (!productDetail?.activityId || !productDetail?.activitySpuId) {
      return;
    }

    setCartModalVisible(true);
    setCartPannelButtonType(2);
    setModalProduct(productDetail);
  };

  //积分兑换
  const onExchangeAction = () => {
    if (coinAmount < (productDetail?.skuExtendInfoVO?.creditAmount ?? 0)) {
      Toast.show("积分不足，无法兑换");
      return;
    }
    setCartModalVisible(true);
    setCartPannelButtonType(7);
    setModalProduct(productDetail);
  };

  //处理价格区域sku点击
  const handleSelectSku = () => {
    if (productDetail?.pricePanelType === 13) {
      onBuyAction();
      return;
    }
    setCartModalVisible(true);
    setCartPannelButtonType(6);
    setModalProduct(productDetail);
  };

  //去使用优惠券
  const onUseCoupon = (coupon?: CouponModel) => {
    const { isOldCoupon, couponId, voucherNo } =
      coupon ?? productDetail?.skuExtendInfoVO?.newcomerCouponVO ?? {};
    const params: { couponId?: string; voucherNo?: string } = {};
    if (isOldCoupon) {
      params.couponId = couponId;
    } else {
      params.voucherNo = voucherNo;
    }

    queryJumpTypeServ(params).then((res) => {
      const { success, data, message } = res || {};
      const { couponId: _couponId, voucherNo: _voucherNo, activityId } = data || {};
      const originParams = getReplaceUrlParam();
      const mxShopPath = `/mx-shop-micro/joint-bill?couponId=${_couponId}&voucherNo=${_voucherNo}&${originParams}`;
      if (success) {
        switch (data.jumpType) {
          case "productDetail":
            onBuyAction();
            break;
          case "productList":
            navigate2GoodsOfNpieceXfold({
              h5Query: {
                couponId: data.couponId,
                voucherNo: data.voucherNo,
                awdInstanceId: data.awdInstanceId,
              },
            });
            break;
          case "activityDetail":
            navigate2Activity({
              activityNo: activityId ?? "",
            });
            break;
          case "activityList":
            navigate({
              h5Options: {
                url: mxShopPath,
              },
            });
            break;
          default:
            break;
        }
      } else {
        Toast.show(message || "服务异常，请稍后重试！");
      }
    });
  };

  //跳转商品尺码详情页面
  const goToProductFitSize = () => {
    const attrValueRes = productDetail?.productSizeTableSimpleInfo?.attrValueRes || [];
    const sizeUrl = productDetail?.spuInfoVO?.sizeUrl;
    const fitSizeCode = productDetail?.spuInfoVO?.fitSizeCode;
    if (attrValueRes.length > 0) {
      if (!fitSizeCode) {
        return;
      }
      navigate({
        h5Options: {
          url: `${MICRO_APP_BASEURL}/#/productFitSize?fitSizeCode=${productDetail?.productSizeTableSimpleInfo?.fitSizeCode}`,
        },
      });
    }

    if (sizeUrl) {
      setShowPicPreview(true);
      setPreviewPicList([sizeUrl]);
    }
  };

  //店铺
  const onB2RShopAction = (productType = 1) => {
    let merchantShopCode = productDetail?.activitySimpleVO?.merchantShopCode;
    const originParams = getReplaceUrlParam();
    let mxShopPath = `/mx-shop-micro/decorate?merchantShopCode=${merchantShopCode}&productType=${productType}&${originParams}`;
    const { skuCurrentPriceInfo, skuAdvancePriceInfo } = productDetail || {};
    if (skuCurrentPriceInfo?.promotionType) {
      mxShopPath += `&currentPromotionType=${skuCurrentPriceInfo.promotionType}`;
    }
    if (skuAdvancePriceInfo?.promotionType) {
      mxShopPath += `&advancePromotionType=${skuAdvancePriceInfo.promotionType}`;
    }
    navigate({
      h5Options: {
        url: mxShopPath,
      },
    });
  };

  //转发赚钱(详情转发)
  const onForwardAction = () => {
    if (!productDetail?.activitySpuId) {
      return;
    }
    setSharePosterData(
      sharePosterParams4H5Product({
        pageCode: pageCode as string,
        sourceScene: SourceScene.productMaterial,
        activitySpuId: productDetail?.activitySpuId,
        selectSkuId: productDetail?.selectSkuId,
      }),
    );
    setCustomEditData({
      activitySpuId: productDetail?.activitySpuId,
      activityId: productDetail?.activityId,
      selectSkuId: productDetail?.selectSkuId,
    });
    setIsOTC(productDetail?.skuExtendInfoVO?.isOTC ?? false);
    setShowPoster(true);
  };

  // 还想买
  const stillBuy = () => {
    buyMore({
      activityId: productDetail?.activityId,
      activitySpuId: productDetail?.activitySpuId,
    }).then((res) => {
      if (res) {
        Toast.show("你的购买意愿已通知商家");
      }
    });
  };

  //关闭营销标签弹窗
  const diamissPromotionDialog = () => {
    setPromotionDialogVisible(false);
  };

  const goPromoActivity = (item) => {
    if (item.skipUrl && item.drillDown) {
      const skipUrl = item.skipUrl;
      if (skipUrl.indexOf("/#/goodsOfNpieceXfold?") > 0) {
        const promoActivityId = getUrlParam("promoActivityId", skipUrl);
        navigate({
          h5Options: {
            url: `${MICRO_APP_BASEURL}/#/goodsOfNpieceXfold?marketingId=${promoActivityId}`,
          },
        });
      } else {
        window.location.href = skipUrl;
      }
    }
    diamissPromotionDialog();
  };

  //规格选择-sku点击
  const onPropertyAction = (skuInfo?: SkuInfoMappingModel) => {
    if (skuInfo) {
      setSelectSkuId(skuInfo?.skuId);
      setFirstSalePropertyValue(skuInfo?.firstPropertyValue);
    }
  };

  const showVideoImage = () => {
    return !currentRoleIsC && productDetail?.pricePanelType !== 13 && materialItem;
  };

  return {
    currentRoleIsC,
    showFavoriteIcon,
    productDetail,
    cartNum,
    currentRole,
    materialListModel,
    videoImage,
    headerDomRef,
    showPromotionDialog,
    onUseCoupon,
    onBuyAction,
    onAddCartAction,
    atmosphereModel,
    goToProductFitSize,
    recommendProductList,
    hasNext,
    onLoadNextPage,
    cartTotal,
    onB2RShopAction,
    onServiceAction,
    goCart,
    onForwardAction,
    stillBuy,
    showFloatMenu,
    promotionDialogVisible,
    promotionDialogType,
    cartModalVisible,
    modalProduct,
    cartPannelButtonType,
    diamissPromotionDialog,
    addressList,
    selectAddressId,
    updateSelectAddress,
    couponList,
    receiveCoupon,
    marketingPopInfo,
    setIsShowMarketingPop,
    goPromoActivity,
    setFirstSalePropertyValue,
    sharePosterData,
    customEditData,
    showPoster,
    isOTC,
    setShowPoster,
    showNewCartPanel,
    selectSkuId,
    setCartModalVisible,
    changeCartNum,
    previewRef,
    previewPicList,
    showPicPreview,
    previewIndex,
    setShowPicPreview,
    bannerList,
    setPreviewPicList,
    setPreviewIndex,
    isShowMarketingPop,
    shopItem,
    activityItem,
    materialItem,
    brandItem,
    liveNo,
    showMedicateDialog,
    setShowMedicateDialog,
    handleSelectSku,
    onPropertyAction,
    showPromotionFeeDialog,
    showRecommendProductDialog,
    soldOutRecommendProductList,
    setShowPromotionFeeDialog,
    setShowRecommendProductDialog,
    isAdvanceProfit,
    setIsAdvanceProfit,
    onExchangeAction,
    setShowPopCoupon,
    showTopTabs,
    activeKey,
    tabItems,
    converterMsg,
    setSelectSkuId,
    fetchProductDetail,
    getReplaceUrlParam,
    couponInfo,
    showPopCoupon,
    showSkeleton,
    mediaSelectIndex,
    setVideoImage,
    setMediaSelectIndex,
    showMediaPreview,
    setShowMediaPreview,
    showVideoImage,
  };
};
