import { useCommonContext } from "@/utils/context/common";
import useNavigation from "@/utils/useNavigate";
import { useAtmosphere, useBaseResource } from "@akc/biz-logic";
import {
  ResourceBannerComp,
  ResourceCapsuleBrandComp,
  ResourceDiamondComp,
  ResourceFloorComp,
  ResourceSuperBrandComp,
  ResourceSuperExplosiveComp,
  ResourceTodaySellingComp,
} from "@akc/mx-taro-h5-components";
import React from "react";
import CouponsBoard from "./new-person/CouponsBoardV2";

type Props = {
  activityTagNo: string;
  onTodaySellingPopupVisibleChanged: (visiable: boolean) => void;
};

const ResourceTodaySellingPopupKey = "resourceTodaySellingPopupKey";

const ResourceList: React.FC<Props> = ({ activityTagNo, onTodaySellingPopupVisibleChanged }) => {
  const { skin, currentRole } = useCommonContext();
  const { navigate, navigate2H5, navigateByH5Link, navigate2Product } = useNavigation();
  const { data: resourceData } = useBaseResource({ categoryId: activityTagNo });
  const { data: atmosphere } = useAtmosphere({ isBuyToday: true, type: 1 });
  const [todaySellingPopupVisible, setTodaySellingPopupVisible] = React.useState(
    (localStorage.getItem(ResourceTodaySellingPopupKey)?.length ?? 0) === 0,
  );

  React.useEffect(() => {
    if (!resourceData) {
      // 按道理不应该存在没有资源位的场景
      setTodaySellingPopupVisible(false);
      return;
    }
    const hasTodayBuy = resourceData.some((item) => item.resourceType === "TODAYBUY");
    setTodaySellingPopupVisible(
      (localStorage.getItem(ResourceTodaySellingPopupKey)?.length ?? 0) === 0 && hasTodayBuy,
    );
  }, [resourceData]);

  React.useEffect(() => {
    onTodaySellingPopupVisibleChanged(todaySellingPopupVisible);
  }, [todaySellingPopupVisible]);

  const toConfrence3 = (conferenceId) => {
    navigate({
      h5Options: {
        url: `/mx-shop-micro?customPageCode=${conferenceId}`,
      },
    });
  };

  const toShopDetail = (merchantShopCode) => {
    navigate({
      h5Options: {
        url: `/mx-shop-micro/decorate?merchantShopCode=${merchantShopCode}`,
      },
    });
  };

  const toTopList = () => {
    navigate2H5({
      path: "/#/top-list",
    });
  };

  const toSaleCalendarPage = () => {
    navigate2H5({
      path: "/mx-shop-micro/sale-calendar",
    });
  };

  const toActivity = (activityId) => {
    navigate2H5({
      path: `/mx-shop-micro/activity/${activityId}`,
    });
  };

  const toProductDetail = (product) => {
    const { activityId, activitySpuId, selectSkuId } = product;
    navigate2Product({
      activityId,
      activitySpuId,
      selectSkuId,
    });
  };

  const toURL = (url) => {
    navigateByH5Link(url);
  };

  const handleCapsuleClick = (e) => {
    console.log("资源位点击", e);
    const { jumpTarget, jumpType } = e ?? {};
    switch (jumpType) {
      case "CUSTOM_PAGE_30":
        toConfrence3(jumpTarget);
        break;
      case "CUSTOM_PAGE_MERCHANT_SHOP":
        toShopDetail(jumpTarget);
        break;
      case "HOT_SALE":
      case "HOT_TOP_LIST":
        toTopList();
        break;
      case "ACTIVITY":
        toActivity(jumpTarget);
        break;
      case "URL":
        toURL(jumpTarget);
        break;
      case "PRODUCT":
        toProductDetail({
          activitySpuId: jumpTarget,
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {resourceData?.map((resource, index) => {
        if (resource.resourceType === "TODAYBUY") {
          // 今日必卖
          return (
            <ResourceTodaySellingComp
              style={{ width: "100%" }}
              key={index}
              model={resource.todayBuy}
              atmospheres={atmosphere}
              skinColorStr={skin?.home?.todaySaleBgColor}
              skinImageUrlStr={skin?.home?.todaySaleBg}
              onTopRightAction={() => toSaleCalendarPage()}
              isCalendarPopupVisible={todaySellingPopupVisible}
              calendarPopupZIndex={11}
              onCalendarPopupAction={() => {
                setTodaySellingPopupVisible(false);
                localStorage.setItem(ResourceTodaySellingPopupKey, "true");
              }}
            />
          );
        } else if (resource.resourceType === "DIAMOND") {
          // 金刚位
          return (
            <ResourceDiamondComp
              key={index}
              diamondList={resource.diamondList?.slice(0, 5)}
              skinColorStr={skin?.home?.diamondBgColor}
            />
          );
        } else if (resource.resourceType === "BANNER") {
          return (
            <div key={index}>
              <ResourceBannerComp
                key={index}
                bannerList={resource.bannerList}
                skinColorStr={skin?.home?.resourceBannerBgColor}
                skinImageUrlStr={skin?.home?.resourceBannerBg}
              />
            </div>
          );
        } else if (resource.resourceType === "BRANDIMG") {
          // 品牌聚合
          return (
            <ResourceSuperBrandComp
              key={index}
              model={resource.brandImg}
              skinColorStr={skin?.home?.brandsTogetherBgColor}
              skinImageUrlStr={skin?.home?.brandsTogetherBgImg}
            />
          );
        } else if (resource.resourceType === "PRODUCT_WINDOW") {
          // 商品橱窗
          return (
            <div key={index}>
              <ResourceSuperExplosiveComp
                key={index}
                model={resource.productWindow}
                onContentAction={handleCapsuleClick}
                onDetailAction={(_, product) => {
                  toProductDetail(product);
                }}
              />
            </div>
          );
        } else if (resource.resourceType === "IMG") {
          // 图片组件
          return (
            <ResourceFloorComp
              key={index}
              model={resource.imgComponent}
              skinColorStr={skin?.home?.imageBgColor}
              onAction={handleCapsuleClick}
            />
          );
        } else if (resource.resourceType === "CAPSULE") {
          // 胶囊位
          return (
            <ResourceCapsuleBrandComp
              key={index}
              model={resource.capsule}
              onAction={handleCapsuleClick}
            />
          );
        } else if (resource.resourceType === "ZONECOUPON") {
          // 新人专区
          return <CouponsBoard key={index} />;
        }
      })}
    </>
  );
};

export default ResourceList;
