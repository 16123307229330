import { platform } from "@/utils/platform";
import { getBaseurl } from "@/utils/url-parse";
import { ProductListItemModel, ProductModel, ShopCardModel } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import { Request } from "./request";

// 抽奖响应体
// https://yapi.akcops.com/project/1799/interface/api/400506
export interface LotteryAwardResponse {
  /** 活动SpuID（PRODUCT 时返回） */
  activitySpuId?: string;
  /** 奖品名称 */
  awardName?: string;
  /** 奖品图片 */
  awardPic?: string;
  /** 奖品类型：NOTHING:未中奖 RED_PACKET:红包 COUPON:优惠券 POINTS:积分 PRODUCT:商品 */
  awardType?: string;
  couponAwd?: {
    /** 奖品id，主键 */
    awdId: string;
    /** 券模版id */
    awdInstanceId?: string;
    /** 最低消费（门槛）金额(元) */
    baseAmount?: number;
    /** 门槛类型 BaseLimitTypeEnum：NONE 代表无门槛 */
    baseLimitType?: string;
    /** 券类型：COUPON：优惠券，SHARE_COUPON：分享券 */
    bizType: string;
    /** 载体类型，ACTIVITY：档期活动，商品SKU：SKU */
    bodyType?: string;
    /** 按钮状态(1：领券 2：条件不符 3：暂未开始 4：已领取 5.去使用 6.已抢光 7.去凑单） */
    buttonStatus?: number;
    /** 新客券文案描述 */
    context?: string;
    /** (老券专用)券id */
    couponId?: string;
    /** 优惠券限制描述列表 */
    couponLimitDescList?: string[];
    /** 优惠比率：性价比（优惠金额/使用金额）无门槛（满0减10） */
    couponRate?: number;
    /** 优惠券状态，0:未领取  1:已领取  2:已抢光  3:已过期  4:已使用  5：未开始  */
    couponStatus?: number;
    /** 券使用提示信息：限饷店领取使用 */
    couponUseTip?: string;
    /** 当前数量（剩余数量） */
    currentNum?: number;
    /** 模版类型 */
    dcTemplateType?: string;
    /** 模版描述 */
    dcTemplateVoucherInstructions?: string;
    /** 模版名称 */
    dcTemplateVoucherName?: string;
    /** 新客券顶部文案描述 */
    detailContext?: string;
    /** 优惠券营销活动结束时间(字符串时间戳) */
    endTime?: string;
    /** 分享券类型下，R或E是否可以分享券 */
    idolOrDistributorCanShareCoupon?: boolean;
    /** 初始数量 */
    initialNum?: number;
    /** 是否品牌新人券 */
    isNewcomerCoupon?: boolean;
    /** 是否老优惠券 */
    isOldCoupon?: boolean;
    /** 是否叠加 0 不可叠加 1 可叠加 */
    isStack?: number;
    /** 拥有者id */
    ownerId?: string;
    /** 拥有者角色:PromoVoucherOwnerTypeEnum:USE_CODE爱豆，CONSUMER用户 */
    ownerType?: string;
    /** 优惠券承担方式 0:平台 1:商家 2:平台&商家 3:租户 */
    payer?: number;
    /** 兑换积分 */
    pointsValue?: number;
    /** 营销活动id */
    promoActivityId: string;
    /** 营销活动状态 */
    promoActivityStatus?: string;
    /** 发行者id，租户ID，商家S码，平台 */
    publisherId?: string;
    /** 发行者类型 TENANT（租户），MERCHANT_SHOP（商家店铺）、PLATFORM（平台） */
    publisherType?: string;
    /** 优惠金额(元) */
    quotaAmount?: number;
    /** 领取数量（分享券）（R分享之后，被领取数量） */
    receiveNumber?: number;
    /** 领取状态，0：未领取，1：已领取  */
    receiveStatus?: number;
    /** 限领次数 */
    restrictNum?: number;
    /** 分享券-领取时所用资产编号 */
    shareCouponDrawVoucherNo?: string;
    /** 分享券-当前分享券拥有者Id */
    shareCouponOwnerId?: string;
    /** 待分享结束时间 */
    shareEndTime?: string;
    /** 待分享开始时间 */
    shareStartTime?: string;
    /** 转发时间区间 xxxx-xxxx */
    shareTimeDesc?: string;
    /** 优惠券来源：PLATFORM:平台，MERCHANT:商家，TENANT:租户，SHOP:店铺，MERCHANT_SHOP:商家店铺，SELLER:店主，DISTRIBUTOR:店长 */
    source?: string;
    /** (老券专有)互斥类型[1:商家券 2:平台活动券  3：平台不可叠加券  4:平台叠加券] */
    sourceType?: number;
    /** 优惠券营销活动开始时间 */
    startTime?: string;
    /** 优惠券开始时间、结束时间:2023.02.20 10:00-2023.02.25 10:00 */
    timeDesc?: string;
    /** 使用时间类型[0:相对时间,1:绝对时间] */
    timeType?: number;
    /** 使用数量（分享券）（R分享之后，C使用数量） */
    useNumber?: number;
    /** 使用范围[0:不限制;1:指定品类使用;2:指定活动使用;3:指定商品使用] */
    useRange?: number;
    /** 有效天数 */
    validity?: number;
    /** 资产编号(已领取的优惠券有值：商详页、档期详情页判断按钮展示【去使用】) */
    voucherNo?: string;
    wxExclusive?: boolean;
  };
  /** 抽奖活动ID */
  lotteryActivityId?: number;
  /** 奖品记录ID */
  lotteryAwardResultId?: number;
  /** 商家店铺列表 */
  merchantShopList?: ShopCardModel[];
  /** 中奖积分（POINTS 时返回） */
  pointsAmount?: number;
  /** 积分余额（POINTS 时返回） */
  pointsBalance?: number;
  /** 推荐商品列表 */
  productList?: ProductListItemModel[];
  /** 日日奖抽奖奖品信息 */
  redPacketAward?: {
    /** 红包金额(元) */
    amount?: number;
    /** 奖品名称 */
    awardName?: string;
    /** 奖品图片 */
    awardPic?: string;
    /** 奖品类型：RED_PACKET:红包 COUPON:优惠券 POINTS:积分 PRODUCT:商品 */
    awardType?: string;
    hotBrandInfo?: {
      /** 品牌id */
      brandId?: string;
      /** 品牌logo */
      brandLogo?: string;
      /** 品牌名称 */
      brandName?: string;
      /** 品牌名称拼音 */
      brandNamePinYin?: string;
    };
    /** 商家店铺code */
    merchantShopCode?: string;
    /** 划线价 */
    tagPrice?: number;
  };
  /** 剩余抽奖次数 */
  remainCount?: number;
}

// 抽奖
const drawLotteryAward = async (params: { promoActivityId: string }) => {
  const constID = localStorage.getItem("constID");
  const data = await Request<any, { message: string; data: LotteryAwardResponse }>({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/dailyPrize/drawLotteryAward`,
    method: "post",
    data: params,
    headers: {
      dtdtoken: constID,
      "AKC-IDFA": constID,
    },
  });
  console.log("drawLotteryAward",data, !data?.data);
  if (!data?.data) {
    return Promise.reject(data?.message || "抽奖失败");
  }
  return data?.data;
};

// 查询奖池全部的奖品
const queryAllAwardInJackpot = async (params: { promoActivityId: string }) => {
  const data = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/dailyPrize/queryAllAwardInJackpot`,
    method: "post",
    data: params,
  });
  return data?.data;
};

// 查询日日奖详情信息
const queryDailyPrizeInfo = async (params?: { promoActivityId?: string }) => {
  const data = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/dailyPrize/queryDailyPrizeInfo`,
    method: "post",
    data: params,
  });
  return data?.data;
};

// 查询签到日历
const querySignInCalendar = async (params?: { promoActivityId: string }) => {
  const data = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/dailyPrize/querySignInCalendar`,
    method: "post",
    data: params,
  });
  return data?.data;
};

// 签到
const signIn = async (params?: { taskId: string; promoActivityId: string }) => {
  const constID = localStorage.getItem("constID");
  const data = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/dailyPrize/signIn`,
    method: "post",
    data: params,
    headers: {
      dtdtoken: constID,
      "AKC-IDFA": constID,
    },
  });
  return data?.data;
};

// 查询地址
const queryAddressDetail = async (params) => {
  const data = await Request({
    url: `${getBaseurl()}/api/member/multi/member/address/detail`,
    method: "get",
    params,
  });
  return data?.data;
};

export {
  drawLotteryAward,
  queryAddressDetail,
  queryAllAwardInJackpot,
  queryDailyPrizeInfo,
  querySignInCalendar,
  signIn
};

