import CHECKED from "@/assets/icon_checked.png";
import CLOSE from "@/assets/icon_close_gray.png";
import UNCHECK from "@/assets/icon_uncheck.png";
import { Popup } from "antd-mobile";
import React, { useEffect } from "react";
import styles from "./index.module.less";
interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentRole: number;
  onConfirm: (switchedRole: number) => void;
  userinfo: any;
}
const RoleSwitchPopup: React.FC<IProps> = ({
  visible,
  setVisible,
  currentRole,
  onConfirm,
  userinfo,
}) => {
  const [role, setRole] = React.useState<number>(1);
  const onClose = () => {
    setVisible(false);
    setRole(currentRole);
  };
  const handleConfirm = (index) => {
    setVisible(false);
    onConfirm(index);
  };
  useEffect(() => {
    setRole(currentRole);
  }, [currentRole]);
  return (
    <>
      <Popup
        visible={visible}
        onMaskClick={onClose}
        bodyStyle={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          zIndex: 11000,
        }}
      >
        <div className={styles["role-switch-popup"]}>
          <div className={styles["role-switch-popup-header"]}>
            <div className={styles["header__title"]}>切换身份</div>
            <img className={styles["header__close"]} src={CLOSE} onClick={onClose} />
          </div>
          <div className={styles["role-switch-popup-main"]}>
            {userinfo.administrator && (
              <div className={styles["main-item"]} onClick={() => setRole(4)}>
                <div className={styles["item-left"]}>
                  <div className={styles["left-title"]}>
                    <div className={styles["title__name"]}>管理员</div>
                    {currentRole === 4 && <div className={styles["title__label"]}>当前身份</div>}
                  </div>
                  <div className={styles["left__desc"]}>
                    有店铺管理相关操作的权限，包含店铺装扮、活动隐藏等权限
                  </div>
                </div>
                <div className={styles["item-right"]}>
                  <img
                    className={styles["right__icon"]}
                    src={role === 4 ? CHECKED : UNCHECK}
                    alt=""
                  />
                </div>
              </div>
            )}
            {userinfo.level && !userinfo.distributorDisable && (
              <div className={styles["main-item"]} onClick={() => setRole(3)}>
                <div className={styles["item-left"]}>
                  <div className={styles["left-title"]}>
                    <div className={styles["title__name"]}>店长</div>
                    {currentRole === 3 && <div className={styles["title__label"]}>当前身份</div>}
                  </div>
                  <div className={styles["left__desc"]}>
                    店长身份相关权益查看，包含店长收入、推广订单、余额提现等权益
                  </div>
                </div>
                <div className={styles["item-right"]}>
                  <img
                    className={styles["right__icon"]}
                    src={role === 3 ? CHECKED : UNCHECK}
                    alt=""
                  />
                </div>
              </div>
            )}
            <div className={styles["main-item"]} onClick={() => setRole(1)}>
              <div className={styles["item-left"]}>
                <div className={styles["left-title"]}>
                  <div className={styles["title__name"]}>顾客</div>
                  {currentRole === 1 && <div className={styles["title__label"]}>当前身份</div>}
                </div>
                <div className={styles["left__desc"]}>
                  与顾客进店后看到的效果一致，顾客身份可分享商品，不能转发赚钱
                </div>
              </div>
              <div className={styles["item-right"]}>
                <img
                  className={styles["right__icon"]}
                  src={role === 1 ? CHECKED : UNCHECK}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["confirm-btn"]} onClick={() => handleConfirm(role)}>
              确认切换
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
export default RoleSwitchPopup;
