/* eslint-disable camelcase */
import { ApiGetWxConfig } from "@/api/wx-config";
import Track from "@/utils/track";
import { getUrlParam } from "@/utils/url-parse";
import { captureMessage } from "./sentry";
import { getSessionStorage } from "./session-storage";
import { urlQueryObject } from "./url-parse";

const track = Track();

const defaultData = {
  desc: "",
  link: `${window.location.href.split("#")[0]}mx-shop-micro/home?shopId=${urlQueryObject().shopId}`,
  imgUrl: "https://cdn.xiangdian.com/cube/p633-1646035630195.png",
};

export const getShareType = (obj: { shareType: string; isDefaultShare: boolean }) => {
  const shareParams = JSON.parse(getSessionStorage("shareParams") || "{}");
  const { shareType, isDefaultShare } = obj || {};
  const actionType = isDefaultShare ? "default" : "click";
  if (shareParams[shareType] && shareParams[shareType][actionType]) {
    return shareParams[shareType][actionType];
  }
  return "";
};

export const getShareParams = (obj: {
  extraMap: {
    contentId: string;
    contentType: string;
  };
  is_default: boolean;
  [key: PropertyKey]: any;
}) => {
  const { extraMap, is_default = false } = obj || {};
  const { contentId, contentType } = extraMap || {}; // 额外信息
  /**
   * contentType: 店铺1  会场2 活动3 直播4 品牌5 商品6 超级会场7 素材13
   */
  const map = {
    1: ["店铺", "click_shop_id"],
    2: ["会场", "click_market_id"],
    3: ["活动", "click_brand_id"],
    6: ["商品", "click_product_id"],
    7: ["会场", "click_market_id"],
    8: ["直播频道", "click_shop_id"],
    9: ["直播", "live_no"],
    10: ["直播", "live_no"],
    11: ["直播", "live_no"],
    12: ["直播", "live_no"],
    13: ["素材", "material_id"],
    14: ["优惠券", "click_coupons_id"],
  };
  const arr = map[contentType];
  let params: {
    [key: PropertyKey]: any;
  } | null = null;
  if (arr) {
    params = {
      share_type: arr[0],
      [arr[1]]: contentId,
      is_default,
      // eslint-disable-next-line
      desc: is_default ? "微信默认分享" : "长按保存",
      // share_t: sessionStorage.getItem('share_t')
    };
    // 获取 share_type
    const shareT = getShareType({
      shareType: arr[0],
      isDefaultShare: is_default,
    });
    if (shareT) {
      params.share_t = shareT;
    }
  }
  return params;
};

const getOnMenuShareAppMessageData = (params?: { isDefault: boolean }) => {
  const obj = getSessionStorage("onMenuShareAppMessage");
  if (obj) {
    const { isDefault = true } = params || {};
    return {
      ...JSON.parse(obj),
      desc: isDefault ? "微信默认分享" : "长按保存",
    };
  }
  return null;
};

export const wechatSdkInit = () => {
  ApiGetWxConfig(window.location.href.split("#")[0]).then((res) => {
    const { code, data } = res ?? {};
    if (code === 200 && data) {
      const config = data.weixinJsApiSignature;
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList: [
          // 必填，需要使用的JS接口列表
          "onMenuShareAppMessage", // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          "onMenuShareTimeline", // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          "showMenuItems", // 批量显示功能按钮接口
          "hideMenuItems",
          "chooseWXPay", // 发起一个微信支付请求
          "checkJsApi",
          "previewImage",
          "chooseImage",
          "getLocalImgData",
          "hideAllNonBaseMenuItem",
        ],
      });
    }
  });
};

export const wxShare = (
  shareData: {
    title?: string;
    desc?: string;
    shopDesc?: string;
    link?: string;
    imgUrl?: string;
    shopLogoUrl?: string;
    scode?: string;
  },
  isHideAll:
    | boolean
    | {
        [key: PropertyKey]: any;
      },
  repostParams?: {
    [key: PropertyKey]: any;
  },
  url?: any,
) => {
  if (typeof isHideAll === "object") {
    repostParams = isHideAll;
    isHideAll = false;
  }
  const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const isDecorate = location.href.indexOf("/decorate") >= 0;
  let finalUrl = window.location.href;
  if (isiOS && isDecorate && url) {
    finalUrl = url;
  }
  if (isiOS && sessionStorage.getItem("wxShareUrl")) {
    finalUrl = sessionStorage.getItem("wxShareUrl") || "";
  }
  ApiGetWxConfig(finalUrl)
    .then((res) => {
      if (res) {
        const { code, data } = res;
        if (code === 200 && data) {
          const config = data.weixinJsApiSignature;
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: config.appId, // 必填，公众号的唯一标识
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名
            jsApiList: [
              // 必填，需要使用的JS接口列表
              "updateAppMessageShareData", // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
              "updateTimelineShareData", // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
              "showMenuItems", // 批量显示功能按钮接口
              "chooseWXPay", // 发起一个微信支付请求
              "checkJsApi",
              "previewImage",
              "chooseImage",
              "getLocalImgData",
              "hideAllNonBaseMenuItem",
            ],
          });
          //将分享信息存入sessionStorage
          sessionStorage.setItem(
            "conference3_share_info",
            JSON.stringify({
              config,
              shareData,
              repostParams,
              isHideAll,
            }),
          );
        } else {
          captureMessage(`微信签名失败 (api/member/signature) (${JSON.stringify(res)})`, "warning");
        }
        window.wx.ready(() => {
          // 判断当前客户端版本是否支持指定JS接口
          window.wx.checkJsApi({
            jsApiList: [
              "updateAppMessageShareData",
              "onMenuShareAppMessage",
              "updateTimelineShareData",
              "onMenuShareTimeline",
              "showMenuItems",
              "chooseWXPay",
              "previewImage",
              "chooseImage",
              "getNetworkType",
              "hideAllNonBaseMenuItem",
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success() {
              console.log("success", shareData);
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"
              // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
              window.wx.updateAppMessageShareData({
                title: shareData.title || document.title, // 分享标题
                desc: shareData.desc || shareData.shopDesc || shareData.title || document.title, // 分享描述
                link: shareData.link || defaultData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareData.imgUrl || shareData.shopLogoUrl || defaultData.imgUrl, // 分享图标
                success() {
                  //分享埋点
                  console.log("设置成功----");
                },
                fail() {
                  console.log("发送给朋友-fail");
                },
                complete() {
                  console.log("发送给朋友-complete");
                },
                cancel() {
                  console.log("发送给朋友-cancel");
                },
                trigger() {
                  console.log("发送给朋友-trigger");
                },
              });
              // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
              window.wx.updateTimelineShareData({
                title: shareData.title || document.title, // 分享标题
                link: shareData.link || defaultData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareData.imgUrl || shareData.shopLogoUrl || defaultData.imgUrl, // 分享图标
                success() {
                  // 设置成功
                  console.log("发送给朋友-success");
                },
                cancel() {
                  // 用户取消分享后执行的回调函数
                },
              });
              console.log("isHideAll", isHideAll);
              if (isHideAll === true) {
                window.wx.hideAllNonBaseMenuItem();
              } else {
                // 要显示的菜单项，所有menu项见附录3
                window.wx.showMenuItems({
                  menuList: [
                    // 要显示的菜单项
                    "menuItem:share:appMessage", // 发送给朋友
                    "menuItem:share:timeline", // 分享到朋友圈
                    "menuItem:favorite", // 收藏
                    "menuItem:share:qq", // 分享到QQ
                    "menuItem:share:QZone", //  分享到QQ空间
                    "menuItem:openWithQQBrowser", // 分在QQ浏览器中打开
                    "menuItem:openWithSafari", // 在Safari中打开
                    "menuItem:share:email", // 邮件
                    "menuItem:copyUrl", // 复制链接
                  ],
                });
              }
            },
          });
        });
        window.wx.error((res2: any) => {
          console.error("微信签名失败", res2);
        });
      } else {
        captureMessage("微信签名无效 (api/member/signature)", "warning");
      }
    })
    .catch((err) => {
      captureMessage(`微信签名失败 (api/member/signature) (${err})`, "warning");
    });
};
