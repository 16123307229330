import { CenterPopup } from "antd-mobile";
import React from "react";
import styles from "./PopForSigned.module.less";

type RecommendLotteryAward = {
  awardName: string;
  awardPic: string;
  awardType: string; // RED_PACKET:红包 COUPON:优惠券 POINTS:积分 PRODUCT:商品
  tagPrice: number;
};

export type SignedInfo = {
  daysToGetAward: number; // 再签到几天可得奖
  isGetSignInAward: boolean; // 是否得到签到奖
  recommendLotteryAward: RecommendLotteryAward; // 日日奖抽奖奖品信息
};

const PopForSigned: React.FC<{ info: SignedInfo; visible: boolean; handleConfirm: any; }> = ({
  info,
  visible,
  handleConfirm,
}) => {
  const { recommendLotteryAward } = info ?? {};
  if (!recommendLotteryAward) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <CenterPopup
        visible={visible}
        bodyStyle={{
          backgroundColor: "transparent",
        }}
        style={{ zIndex: 100 }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
      >
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.timeTitle}>
              再签<span>{info.daysToGetAward}</span>天
            </div>
            {recommendLotteryAward.awardType === "PRODUCT" && recommendLotteryAward.tagPrice ? (
              <div className={styles.subTitle}>
                有机会抽取价值<span>{recommendLotteryAward.tagPrice}元</span>的
              </div>
            ) : (
              <div className={styles.subTitle}>有机会抽取以下奖品</div>
            )}
            <img src={recommendLotteryAward.awardPic} alt="" />
            {["RED_PACKET", "COUPON"].includes(recommendLotteryAward.awardType) &&
              recommendLotteryAward.tagPrice ? (
              <div className={styles.numTitle}>
                <span>{recommendLotteryAward.tagPrice}</span>元{" "}
                {recommendLotteryAward.awardType === "RED_PACKET" ? "红包奖励" : "优惠券"}
              </div>
            ) : (
              <div className={styles.numTitle}>{recommendLotteryAward.awardName}</div>
            )}
          </div>
          <div className={styles.confirm} onClick={() => handleConfirm()}>
            确认
          </div>
        </div>
      </CenterPopup>
    </div>
  );
};

export default PopForSigned;
