import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import { shareCardByH5 } from "@/utils/share/share";
import { Toast } from "antd-mobile";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { page_view } from "./track";

import {
  checkBind,
  checkUserBindPhone,
  currentUserInfoServ,
  getConsultShopOwner,
  getLabelViewServ,
  getRecommendProductList,
  getUserInfo,
  getWeChatAccountInfoUrlByUserId,
  memberInfoServ,
} from "@/api/my";

import FollowWeChat from "./components/follow-wechat";
import JifenBoard from "./components/jifen-board";
import Menu from "./components/menu";
import MyBalance from "./components/my-balance";
import OrderCenter from "./components/order-center";
import Recommend from "./components/recommend";
import ServiceCard from "./components/service-card";
import UserInfo from "./components/userinfo";

import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { useActivate } from "react-activation";
import "./index.less";
import styles from "./index.module.less";

type Props = {
  cartChange?: () => Promise<void>;
};

const Personal: React.FC<Props> = ({ cartChange }) => {
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const [userInfo, setUserInfo] = React.useState<any>({});
  const [labelViewInfo, setLabelViewInfo] = React.useState<any>({});
  const [productList, setProductList] = React.useState<any[]>([]);
  const [idsClick, setIdsClick] = React.useState<any[]>([]);
  const [lastActivitySpuCode, setLastActivitySpuCode] = React.useState<any>("");
  const [shareInfo, setShareInfo] = React.useState<any>({});
  const [pageIndex, setPageIndex] = React.useState(1);
  const [hasNext, setHasNext] = React.useState(true);
  const [isFollow, setIsFollow] = React.useState<boolean>(true);
  const [isOnlyC, setIsOnlyC] = React.useState<boolean>(false);
  const [bindStatus, setBindStatus] = React.useState<number>(1);
  const [pointsInfo, setPointsInfo] = React.useState<any>({});

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  // 返回 1 为已绑定，2 待授权  3 为游客未绑定  4 为非游客未绑定
  const checkIsRealBind = async () => {
    let bindStatus = 1;
    const res = await checkBind();
    if (!res?.data?.isAuthorized) {
      bindStatus = 2;
      setBindStatus(bindStatus);
      return;
    }
    // 非游客
    if (res?.data?.visitorStatus === 0) {
      const res = await checkUserBindPhone();
      bindStatus = res?.data?.isBindPhone ? 1 : 4;
    } else {
      // 游客
      bindStatus = 3;
    }
    setBindStatus(bindStatus);
  };

  const queryUserInfo = async () => {
    try {
      const { data }: any = await getUserInfo({ shopId });
      const currentUserInfoRes: any = await currentUserInfoServ();
      setUserInfo({
        ...(currentUserInfoRes?.data || {}),
        ...data,
      });
      localStorage.setItem("userinfo", JSON.stringify(data));
    } catch (error) {
      console.error(error);
      Toast.show("获取用户信息失败");
    }
  };
  const reqRecommendProductList = async () => {
    // setLoading(true);
    const searchRequestId = _.random(100000000000, 999999999999999);
    try {
      const params = {
        scene: "personalCenter",
        pageId: "H5_Search",
        searchRequestId,
        idsClick: idsClick.join(","),
        lastActivitySpuCode,
        pageIndex,
      };
      const { data }: any = await getRecommendProductList(params);
      setHasNext(data.hasNext);
      if (!data.result) {
        return;
      }
      data.result = data.result.map((item) => {
        return {
          ...item,
          searchRequestId,
        };
      });
      setProductList((pre) =>
        pre.concat(data.result).map((item, index) => ({ ...item, rank: index })),
      );

      setLastActivitySpuCode(data.result[data.result.length - 1].activitySpuId);
      setPageIndex(pageIndex + 1);

      setTrackData((data) => ({
        ...data,
        recommend_requestid: searchRequestId,
        resource_name: "专属推荐",
        resource_type: "专属推荐",
        page_name: "我的",
      }));
    } catch (error) {
      console.error(error);
      setHasNext(false);
    }
  };
  const reqConsultShopOwner = async () => {
    const res = await getConsultShopOwner();
    if (!res?.data) {
      return;
    }
    setShareInfo(res.data);
  };
  const queryMemberInfo = async () => {
    const res = await memberInfoServ();
    setIsOnlyC(res?.data?.isMember ?? false);
    setPointsInfo(res.data);
  };
  const reqWeChatAccountInfoUrlByUserId = async () => {
    const res = await getWeChatAccountInfoUrlByUserId();
    setIsFollow(res?.data?.isFollow);
  };
  const loadMore = async () => {
    if (!hasNext) {
      return;
    }
    await reqRecommendProductList();
  };
  const shareSetting = async () => {
    const p = {
      bizInfo: {
        contentExtra: {
          "share.tab.activityTagNo": "",
          "share.tab.activityTagName": "",
        },
        contentId: "1",
        contentType: "TAB",
      },
      carrierContext: { carrierElements: ["h5LINK"] },
      contentContext: {
        contentExtra: { page_name: "个人中心" },
        contentId: "",
        contentType: "",
      },
      envContext: {
        sourceScene: "",
        bizCategory: "XD",
        bizSubCategory: "xdWxH5",
        terminalType: "h5",
      },
    };
    const res = await fetchShareCardInfo(p as any);
    if (res && res.data) {
      shareCardByH5(
        {
          title: res.data.urlShareTitle,
          desc: res.data.urlShareDesc,
          link: res.data.urlShareUrl,
          imgUrl: res.data.urlShareImg,
          scode: res.data.scode,
        },
        false,
      );
    }
  };
  const getLabelView = async () => {
    let data = await getLabelViewServ({ pageId: "H5_Member" });
    let arr = (data || []).filter((item) => item.locationCode === "H5_Member_DetailNameDown");
    if (arr.length) {
      setLabelViewInfo(arr[0].labelInfoVOList[0]);
    }
  };
  useEffect(() => {
    if (bindStatus !== 1) {
      Toast.show("为了你的账户安全，请绑定手机号，绑定后可快速登陆");
    }
  }, [bindStatus]);
  useEffect(() => {
    document.title = "我的";
    reqRecommendProductList();
    queryUserInfo();
    reqConsultShopOwner();
    shareSetting();
    queryMemberInfo();
    reqWeChatAccountInfoUrlByUserId();
    checkIsRealBind();
    page_view();
    getLabelView();
  }, []);
  useActivate(() => {
    document.title = "我的";
  });

  return (
    <div className={styles["scroll-view"]}>
      <div className={styles["personal-container"]}>
        <div className={styles.userInfoWrap}>
          <UserInfo
            userinfo={userInfo}
            shareInfo={shareInfo}
            bindStatus={bindStatus}
            labelViewInfo={labelViewInfo}
          />
        </div>
        {(pointsInfo.showPoint || pointsInfo.isMember) && (
          <JifenBoard pointsInfo={pointsInfo} />
        )}
        <div
          className={styles["wrapper"]}
          style={{ marginTop: pointsInfo?.showPoint ? "-20px" : "16px" }}
        >
          {userInfo.amount > 0 && <MyBalance userinfo={userInfo} />}
          <OrderCenter />
          {!isFollow && <FollowWeChat />}
          <Menu />
          <ServiceCard />
          {
            <Recommend
              productList={productList}
              onCartChange={cartChange}
              idsClick={idsClick}
              setIdsClick={setIdsClick}
              loadMore={loadMore}
              hasNext={hasNext}
            />
          }
        </div>
      </div>
    </div>
  );
};
export default inject("appStore")(observer(Personal));
