import { CouponInfo } from "@akc/biz-api";
import React from "react";
import { CouponItemExt } from "../../../model/CouponModel";
import style from "./CouponItem.module.less";
interface Props {
    coupon: CouponItemExt;
    modelType: number;
    type?: string;
}

const CouponVenueItem: React.FC<Props> = ({ coupon, modelType, type = "newcomer" }) => {
    const renderTimeDesc = () => {
        if (coupon.endTime) {
            if ((coupon?.diffDays ?? 0) > 0 && (coupon.diffDays ?? 0) < 1) {
                return <span className={style.theme}>{`${coupon?.countdownDesc}后到期`}</span>;
            } else if ((coupon.diffDays ?? 0) >= 1 && (coupon.diffDays ?? 0) <= 3) {
                return `${coupon?.diffDaysInteger}天后到期`;
            } else {
                return `${coupon.endTimeDesc}到期`;
            }
        } else {
            return coupon.timeDesc || "";
        }
    };

    const couponClass = modelType === 3 ? "three" : modelType === 2 ? "two" : "one";

    return (
        <div>
            {modelType === 1 && (
                <div className={`${style.coupon_item} ${style["one"]} ${style[type]}`}>
                    {type === "newcomer" && coupon.buttonStatus === 1 && coupon.currentNum && coupon.currentNum < 100 && (
                        <div className={style.coupon_num}>仅剩{coupon.currentNum}张</div>
                    )}
                    <div className={style.left}>
                        <div className={style.flex_jcc}>
                            <span className={style.amount}>{coupon.amount}</span>
                            <span className={style.rmb}>元</span>
                        </div>
                    </div>
                    <div className={style.right}>
                        <div className={style.name}>{coupon.brandName || coupon.couponName}</div>
                        <div className={style.time}>
                            {renderTimeDesc()}
                        </div>
                    </div>
                </div>
            )}
            {(modelType === 2 || modelType === 3) && (
                <div className={`${style.coupon_item} ${style[couponClass]} ${style[type]}`}>
                    {type === "newcomer" && coupon.buttonStatus === 1 && coupon.currentNum && coupon.currentNum < 100 && (
                        <div className={style.coupon_num}>仅剩{coupon.currentNum}张</div>
                    )}
                    <div className={style.main}>
                        <div className={style.name}>{coupon.brandName || coupon.couponName}</div>
                        <div className={style.flex_jcc}>
                            <span className={style.amount}>{coupon.amount}</span>
                            <span className={style.rmb}>元</span>
                        </div>
                    </div>
                    <div className={style.time}>
                        {renderTimeDesc()}
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default CouponVenueItem;