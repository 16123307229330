import ProductWaterfall from "@/components/product-waterfall";
import { ProductModel } from "@akc/biz-api";
import { Popup } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
    // 商品数组模型
    productList?: ProductModel[],
    // 展示页面
    dialogVisible: boolean;
    // 关闭页面
    onClose?: () => void;
};

/**
 * 推荐商品弹窗
 */
const RecommendProductDialog: React.FC<Props> = (props) => {

    return (
        <Popup
            visible={props.dialogVisible}
            onMaskClick={() => {
                props.onClose?.();
            }}
            bodyStyle={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                zIndex: 10000,
            }}
        >
            <div className={styles.recProductC}
            >
                <div className={styles.popTitle}>
                    商品已被抢光啦，为你推荐相似热卖款
                    <div className={styles.close} onClick={props?.onClose} />
                </div>
                <div className={styles.list}>
                    <ProductWaterfall
                        productList={props.productList ?? []}
                        hasNext={false}
                        loadMore={(() => Promise.resolve())}
                        topRightLocationCode="H5_ScheduleList_TopProductPicRight"
                        titleBottomLocationCode="H5_Search_ProductList_TitleDown"
                        titleFrontLocationCode="H5_Search_ProductList_TitleFront"
                        provideBackground={false}
                    />
                    <div className={styles.safeBottom} />
                </div>
            </div>

        </Popup >
    );
};

export default RecommendProductDialog;
