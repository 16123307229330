import { getCouponWindowForVenue } from "@/api/coupon";
import { queryCardPop } from "@/api/market";
import { CouponInfo, ProductDetailModel } from "@akc/biz-api";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MarketModel } from "../../model/MarketModel";

const useMarketPop = ({ product }: { product?: ProductDetailModel }) => {
  // 是否已经展示
  const isHasLoadedCouponWindow = useRef<boolean>(false);
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const [couponInfo, setCouponInfo] = useState<CouponInfo>({});
  const [showPopCoupon, setShowPopCoupon] = useState<boolean>(false);
  const [isShowMarketingPop, setIsShowMarketingPop] = useState<boolean>(false);
  const [marketingPopInfo, setMarketingPopInfo] = useState<MarketModel>();
  const fetchCouponInfo = async () => {
    if (isHasLoadedCouponWindow.current) {
      return;
    }
    isHasLoadedCouponWindow.current = true;
    let res = await getCouponWindowForVenue({
      shopBizCode: shopId,
      venueId: product?.activityId,
      pageCode: "productDetail",
    });

    if (res.data && res.data.totalCouponAmount) {
      setCouponInfo(res.data);
      if (res.data?.newPeopleCouponActivities?.length) {
        // 新客优惠券弹框优先级最高
        console.log("新客优惠券弹框");
        setShowPopCoupon(true);
      } else {
        const marketingPop = await queryCardPop({
          serverCode: "product_detail",
        });
        setShowPopCoupon(!marketingPop);
      }
    } else {
      setShowPopCoupon(false);
      setCouponInfo({});
    }
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    fetchCouponInfo();
  }, [product]);

  useEffect(() => {
    queryCardPop({ serverCode: "product_detail" }).then((res) => {
      if (res?.data) {
        const { skipType } = res.data;
        if (skipType) {
          setIsShowMarketingPop(true);
          setMarketingPopInfo(res.data);
        }
      }
    });
  }, []);

  return {
    couponInfo,
    showPopCoupon,
    marketingPopInfo,
    setIsShowMarketingPop,
    isShowMarketingPop,
    setShowPopCoupon,
    search,
  };
};

export default useMarketPop;
